<template>
	<el-row :gutter="20" style="flex-wrap: none !important;" class="flex-div">
		<div class="left nodeLeft" v-if="type != 2">
			<div style="height: 100%;background-color: #fff;padding: 0 10px;border-radius: 14px;">
				<p style="line-height: 45px;">所属单位</p>
				<div class="block" style="height: calc(100% - 65px);overflow: auto;">
					<div class="filter-input">
						<el-input v-model="filterText" placeholder="输入搜索关键字" />
						<!-- <el-button type="primary" size="small" style="margin-left: 10px;" @click="drawerOpen()">
							<el-icon>
								<plus />
							</el-icon>
							新增
						</el-button> -->
					</div>
					<el-tree :data="dataSource" node-key="treedept" class="tree-w" ref="tree"
						:filter-node-method="filterNode" @node-click="nodclick"
						:expand-on-click-node="false"
						:highlight-current="true"
						@node-expand="treeExpand"
						@node-collapse="treecollapse"
						>
						<template #default="{ node, data }">
							<span class="custom-tree-node">
								<p class="ptext">{{ node.label }}</p>
								<span style="display: flex;align-items: center;">
									<el-icon @click="append(node,data)" style="color: #2f49d7;">
										<plus />
									</el-icon>
									<el-icon style="margin: 0 5px;" @click="edit(node, data)">
										<edit />
									</el-icon>
									<!-- <el-icon @click="remove(node, data)" style="color: #F56C6C;">
										<delete />
									</el-icon> -->
									<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
										icon-color="red" title="删除该单位之前，请先确定已删除该单位关联的人员账号与相关任务!" @confirm="remove(node, data)">
										<template #reference>
											<el-icon style="color: #F56C6C;">
												<delete />
											</el-icon>
										</template>
									</el-popconfirm>
								</span>
							</span>
						</template>
					</el-tree>
				</div>
			</div>
		</div>
		<div class="right nodeRight">
			<div style="height: 100%;background-color: #fff;border-radius: 14px;box-sizing: border-box;padding: 15px;">
				<p style="line-height: 35px;">任务列表</p>
				<div class="right-title">
					<div>
					   <el-select v-model="statevalue" style="margin-right: 20px;" @change="stateSelect">
						<el-option
						      v-for="item in stateList"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value"
						    >
						    </el-option>
						  </el-select>
						
						<el-input style="width: 240px;" v-model="tablekwd" placeholder="请输入关键字">
							<template #suffix>
								<el-icon class="el-input__icon" @click="searchBtn">
									<search />
								</el-icon>
							</template>
						</el-input>
					</div>
					<el-button color="#576CD9" style="color:#FFFFFF;" @click="khClick">
						<el-icon style="margin-right: 5px;">
							<plus />
						</el-icon>新建任务
					</el-button>
				</div>
				<div class="right-main">
					<el-table :data="tableData" style="width: 100%" v-loading="tableLoading">
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column prop="deptName" label="所属单位" width="180" />
						<el-table-column prop="taskName" label="任务名称" width="100"/>
						<el-table-column prop="account" label="关联客户" >
							<template #default="scope">
								<el-tag size="small" v-for="(item,index) in scope.row.link_user">{{item.username}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="account" label="状态" >
							<template #default="scope">
								<el-popover  placement="top" :width="160"  v-if="scope.row.state == 1">
								    <p>结束任务</p>
								    <div style="text-align: right; margin: 0">
										<el-popconfirm title="确定要结束任务吗?" @confirm="stopServe(scope.$index, scope.row,2)">
										    <template #reference>
										      <el-button size="mini" type="danger">结束任务</el-button>
										    </template>
										</el-popconfirm>
								    </div>
								    <template #reference>
								      <el-tag size="small" effect="dark" type="success" style="cursor: pointer;">进行中</el-tag>
								    </template>
								</el-popover>
								
								<el-tag size="small" effect="dark" type="danger" v-if="scope.row.state == 2">已结束</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="操作" >
							<template #default="scope">
								<div style="display: flex;">
									
									<div @click="zhEdit(scope.$index, scope.row)" title="修改账号" class="radius-w"
										style="background-color: #67c23a;margin: 0 10px;">
										<el-icon>
											<edit />
										</el-icon>
									</div>
						
									<el-popconfirm confirm-button-text="确定" cancel-button-text="取消" 
										icon-color="red" @confirm="handleDelete(scope.$index, scope.row)"
										title="确定删除吗?">
										<template #reference>
											<div title="删除" class="radius-w" style="background-color: #CF5B5B;">
												<el-icon>
													<remove />
												</el-icon>
											</div>
										</template>
									</el-popconfirm>
								</div>
							</template>
						</el-table-column>
						
					</el-table>
				</div>
				<div class="block">
					<el-pagination layout="prev, pager, next,total" :total="tableTotal"
					 :page-size="limit" 
					 @current-change="changePage"
					 v-model:current-page="nowPage"></el-pagination>
				</div>
			</div>
		</div>
	</el-row>
	<!-- 抽屉 -->
	<el-drawer v-model="drawer" size='25%' @opened="drawerOpened()" @closed="resetForm" title="添加单位">
		<div class="drawer-div">
			<el-form ref="ssdwForm" :model="ssdwForm" label-width="80px">
				<el-form-item label="单位名称" prop="dwmc">
					<el-input v-model="ssdwForm.dwmc" :disabled="clickType == 'edit'"></el-input>
				</el-form-item>
				<el-form-item label="到期时间" v-if="userType ==0">
					<el-date-picker style="width: 100%;" v-model="tjdwsj" value-format="YYYY-MM-DD" type="date"
						placeholder="选择到期时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="单位地址" prop="ditusou">
					<ul class="mapList" v-show="mapInpoutOnFocus">
						<li v-for="(item,index) in mapList" @click="setCenter(item.location,item.name)">{{item.name}}
						</li>
					</ul>
					<el-input id="pickerInput" v-model="ssdwForm.ditusou" @focus="mapInpoutOnFocus = true"
						@blur="mapblur"></el-input>
	
				</el-form-item>
			</el-form>
			<!-- <label class="el-form-item__label lebel-w">单位地址</label> -->
			<div class="gd-ditu">
				<div class="ditu-center">
					<el-icon style="font-size: 52px;">
						<plus />
					</el-icon>
				</div>
				<!-- 地图 -->
				<div style="width: 100%;height: 100%;" id="container"></div>
				<!-- 搜索 -->
			</div>
		</div>
		<div style="width: 100%;text-align: left;box-sizing: border-box;padding-left: 20px;">
			<el-button type="primary" @click="ssdwBtn('ssdwForm')">提交</el-button>
		</div>
	</el-drawer>
	<!-- 添加客户 -->
	<el-drawer v-model="drawerkh" size='35%'  :title="drawerTitle" @close="drawerClose">
		<div class="drawer-div">
			<el-form ref="khForm" :model="khForm" label-width="80px">
				<el-form-item label="任务名称" prop="deptName">
					<el-input v-model="khForm.name"  ></el-input>
				</el-form-item>
				<!-- <el-form-item label="关联人员">
					<el-select v-model="personnelList" multiple placeholder="选择关联人员">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value"
					    >
					    </el-option>
					  </el-select>
				</el-form-item> -->
			</el-form>
			<p style="font-size: 14px;text-align: left;margin-bottom: 15px;color: #606266;padding-left: 14px;">关联客户</p>
			<el-transfer
			    v-model="personnelList"
			    :data="options"
				:props="{
				      key: 'value',
				      label: 'label',
				    }"
				:titles="['未关联', '已关联']"
				style="display: flex;align-items: center;justify-content: space-around;"
			  >
			  </el-transfer>
		</div>
		<div style="width: 100%;text-align: left;box-sizing: border-box;padding-left: 20px;">
			<el-button type="primary" @click="khBtn('khForm')">提交</el-button>
		</div>
	</el-drawer>
</template>
 
<script>
	import {
		ElMessage,
		ElLoading
	} from 'element-plus'
	export default {
		data(){
			return {
				tableLoading:false,
				deptId:'',
				personnelList:[],
				filterText: '',
				dataSource:[],
				isEdit:false,
				drawerkh:false,
				drawer:false,
				mapInpoutOnFocus: false,
				type:sessionStorage.getItem('type'),
				nowPage:1,
				limit:12,
				tableTotal:0,
				khForm:{
					name:""
				},
				options:[],
				deptName:'',
				tableData:[],
				treeNum:0,
				treeLeftWidth:0,
				treeRightWidth:0,
				stateList:[
					{
					  value: '',
					  label: '全部',
					},
					{
					  value: '1',
					  label: '进行中',
					},
					{
					  value: '2',
					  label: '已结束',
					}
				],
				statevalue:'',
				state:'',
				userCheack:'',
				nowIndex:'',
				rightdefault:[],
				drawerTitle:'添加任务',
				tablekwd:'',
				clickType:'',
				userType: sessionStorage.getItem('type'),
				treenode:'',
				ssdwForm: {
					dwmc: '',
					ditusou: ''
				},
				editGps:'',
				mapList:[],
				tjdwsj:''
				
			}
		},
		methods:{
			treecollapse(data,Node,seft){
				// console.log(Node);
				// console.log(seft);
				this.treeNum = this.treeNum - 1;
				var treeNum = this.treeNum;
				if (Node.level == 1) {
					$('.nodeRight').width('auto');
					$('.nodeLeft').width('16.667%');
					return
				}
				var treeLeftWidth = this.treeLeftWidth;
				var treeRightWidth = this.treeRightWidth;
				var nodePb = $('.el-tree-node.is-expanded .ptext');
				setTimeout(()=> {
					// nodeLeft
					var nodeP = $('.el-tree-node.is-expanded .ptext');
					var maxWidth = 0;
					nodeP.each(function (){
						maxWidth = maxWidth < $(this).width() ? $(this).width() : maxWidth;
					})
					$('.nodeRight').width(  $('.nodeRight').width() +  (treeNum * 20));
					$('.nodeLeft').width( $('.nodeLeft').width() -  (treeNum * 20) );
					if (maxWidth < 80) {
						maxWidth = 80
					}
					$('.nodeRight').width(  treeRightWidth - maxWidth)
					maxWidth = treeLeftWidth + maxWidth;
					$('.nodeLeft').width(maxWidth);
				},500)
			},
			treeExpand(data,Node,seft){
				if (this.treeNum == 0) {
					this.treeLeftWidth = $('.nodeLeft').width();
					this.treeRightWidth = $('.nodeRight').width();
				}
				this.treeNum = this.treeNum + 1;
				var treeNum = this.treeNum;
				var treeLeftWidth = this.treeLeftWidth;
				var treeRightWidth = this.treeRightWidth;
				setTimeout(()=> { 
					// nodeLeft
					var nodeP = $('.el-tree-node.is-expanded .ptext');
					var maxWidth = 0;
					nodeP.each(function (){
						maxWidth = maxWidth < $(this).width() ? $(this).width() : maxWidth;
					})
					$('.nodeRight').width(  $('.nodeRight').width() -  (treeNum * 20));
					$('.nodeLeft').width( $('.nodeLeft').width() +  (treeNum * 20) );
						$('.nodeRight').width(  treeRightWidth - maxWidth)
						maxWidth = treeLeftWidth + maxWidth;
						$('.nodeLeft').width(maxWidth);
				},500)
			},
			remove(node, data) {
				if (!node.parent.data.id) {
					ElMessage.error('根节点不可删除！');
					return
				}
				// // console.log(data);
				this.$axios.post('/dept/del', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: node.data.id
					})
					.then(res => {
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						const parent = node.parent
						const children = parent.data.children || parent.data
						const index = children.findIndex((d) => d.id === data.id)
						children.splice(index, 1)
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			resetForm(formName) {
				this.ssdwForm.dwmc = '';
				this.ssdwForm.ditusou = '';
				this.tjdwsj = '';
				this.editGps = [];
			},
			drawerOpen() {
				this.drawer = true;
				setTimeout(res => {
					this.autoInput()
			
				}, 1500)
			},
			drawerOpened() {
				let that = this;
				setTimeout(function() {
					var center,zoom;
					if (that.editGps.length > 0) {
						center = that.editGps;
						zoom = 18;
					} else {
						center = [116.397428, 39.90923];
						zoom = 14;
					}
					that.map = new AMap.Map('container', {
						zoom: zoom, //级别
						center: center, //中心点坐标
						viewMode: '3D' //使用3D视图
					});
					that.map.on('dragend', that.gpsToAdderss)
				}, 1000)
			},
			gpsToAdderss() {
				let that = this;
				var location = this.map.getCenter();
				if (!location) {
					return
				}
				var geocoder = new AMap.Geocoder({
					radius: 500 //范围，默认：500
				});
				geocoder.getAddress([location.lng, location.lat], function(status, result) {
					that.ssdwForm.ditusou = result.regeocode.formattedAddress;
				});
			},
			autoInput() {
				let that = this;
				AMap.plugin('AMap.Autocomplete', function() {
					// 实例化Autocomplete
					var autoOptions = {
						city: '全国'
					}
			  var autoComplete = new AMap.Autocomplete(autoOptions);
					autoComplete.search(that.ssdwForm.ditusou, function(status, result) {
						// 搜索成功时，result即是对应的匹配数据
						if (result.info == 'OK') {
							that.mapList = result.tips;
						}
						// // console.log(result);
					})
				})
			},
			setCenter(location, adderss) {
				if (location) {
					this.map.setZoomAndCenter(18, [location.lng, location.lat]);
					this.ssdwForm.ditusou = adderss;
				}
			},
			mapblur() {
				setTimeout(res => {
					this.mapInpoutOnFocus = false;
				}, 200)
			},
			ssdwBtn(formName) {
				let that = this;
				var urlType = '';
				if (this.clickType == 'edit') {
					urlType = '/dept/update'
				} else if (this.clickType == 'add') {
					urlType = '/dept/add'
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var center = this.map.getCenter();
						var geocoder = new AMap.Geocoder({
							radius: 500 //范围，默认：500
						});
						geocoder.getAddress([center.lng, center.lat], (status, result) => {
							// that.ssdwForm.ditusou = result.regeocode.formattedAddress;
							this.$axios.post(urlType, {
									userId: sessionStorage.getItem('userId'),
									type: sessionStorage.getItem('type'),
									deptName: this.ssdwForm.dwmc,
									parentId: this.treenode.data.id,
									parentName: this.treenode.data.label,
									gps: [center.lng, center.lat].toString(),
									endTime: this.tjdwsj,
									address: this.ssdwForm.ditusou,
									deptId: this.treenode.data.id
								})
								.then(res => {
									if (res.data.code != 666) {
										ElMessage.error(res.data.msg);
										return
									}
			
									ElMessage.success(res.data.msg);
									// 关闭右侧弹出
									this.drawer = false;
									// 清空弹窗内内容
									// this.resetForm('ssdwForm');
									// 清空坐标
									this.editGps = [];
									// // console.log(this.clickType);
									if (this.clickType == 'add') {
										var sourceD = {
											id: res.data.deptId,
											label: this.ssdwForm.dwmc,
											children: []
										}
										if (!this.treenode.data.children) {
											this.treenode.data.children = []
										}
										this.treenode.data.children.push(sourceD)
									} else {
										this.treenode.data.label = this.ssdwForm.dwmc;
									}
								})
								.catch(error => {
									console.error('error:', error)
								})
			
						});
			
					} else {
						// // console.log('填写不能为空')
						return false
					}
				})
			
			},
			edit(node, data) {
				this.clickType = 'edit';
				this.treenode = node;
			
				this.$axios.post('/dept/findById', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: this.treenode.data.id
					})
					.then(res => {
						// // console.log(res);
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						var data = res.data.data[0];
						this.ssdwForm.dwmc = data.deptName;
						this.ssdwForm.ditusou = data.address;
						this.tjdwsj = data.endTime;
						// // console.log(this.tjdwsj);
						this.editGps = data.gps.split(",");
					})
					.catch(error => {
						console.error('error:', error)
					})
			
				this.drawerOpen();
			},
			append(node, data) {
				this.clickType = 'add';
				this.treenode = node;
				this.drawerOpen();
			},
			filterNode(value, data) {
				if (!value) return true
				return data.label.indexOf(value) !== -1
			},
			changePage(){
				this.getKhList();
			},
			searchBtn(){
				this.nowPage = 1;
				this.getKhList(this.tablekwd)
			},
			drawerClose(){
				this.personnelList = [];
				this.khForm.name = '';
			},
			handleDelete(index, row) {
				// // console.log(index, row)
				this.$axios.post('/task/del', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						taskId: row.taskId,
					})
					.then(res => {
						// // console.log(res);
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
						return
						}
						ElMessage.success(res.data.msg);
						this.tableData.splice(index,1);
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			zhEdit(index, row){
				this.drawerTitle = '修改信息';
				this.isEdit = true;
				this.userCheack = row;
				this.nowIndex = index;
				this.drawerkh = true;
				this.deptId = row.deptId;
				this.khForm.name = row.taskName;
				var rightdefault = [];
				for (var i = 0; i < row.link_user.length; i++) {
					rightdefault.push(row.link_user[i].userId)
				}
				this.personnelList = rightdefault;
				this.getCustomer();
			},
			stateSelect(val){
				this.state = val;
				// // console.log(val);
				this.getKhList();
			},
			stopServe(index, row,state){
				this.$axios.post('/task/updateState', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						taskId: row.taskId,
						state:state
					})
					.then(res => {
						// // console.log(res);
					if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.tableData[index].state = state;
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			khBtn(){
				var link_dept = '';
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1) {
					link_dept = sessionStorage.getItem('deptId');
				} else if(sessionStorage.getItem('type') == 3) {
					link_dept = sessionStorage.getItem('link_dept');
				}
				if (this.isEdit) {
					// 修改
					this.$axios.post('/task/update', {
							userId: sessionStorage.getItem('userId'),
							type: sessionStorage.getItem('type'),
							taskId:this.userCheack.taskId,
							taskName:this.khForm.name,
							link_user:this.personnelList
						})
						.then(res => {
							// // console.log(res);
							if (res.data.code != 666) {
								ElMessage.error(res.data.msg);
								return
							}
							ElMessage.success(res.data.msg);
							// 关闭右侧弹出
							this.drawerkh = false;
							this.getKhList();
						
						})
						.catch(error => {
							console.error('error:', error)
						})
					
				}else{
					// 新增
					this.$axios.post('/task/add', {
							userId: sessionStorage.getItem('userId'),
							type: sessionStorage.getItem('type'),
							deptId:this.deptId,
							taskName:this.khForm.name,
							deptName:this.deptName,
							link_dept:link_dept,
							userName:sessionStorage.getItem('username'),
							link_user:this.personnelList
						})
						.then(res => {
							// // console.log(res);
							if (res.data.code != 666) {
								ElMessage.error(res.data.msg);
								return
							}
							ElMessage.success(res.data.msg);
							// 关闭右侧弹出
							this.drawerkh = false;
							// this.tableData.push({
							// 	account: this.khForm.user,
							// 	deptID: this.khForm.deptId,
							// 	deptName: this.khForm.deptName,
							// 	state: 1,
							// 	userId: res.data.userId,
							// 	username: this.khForm.khmc
							// })
							this.getKhList();
						
						})
						.catch(error => {
							console.error('error:', error)
						})
					
				}
				
			},
			khClick(){
				if (this.deptId == '') {
					ElMessage.error('请点击选择左侧所属单位');
					return
				}
				this.drawerTitle = '添加任务';
				this.getCustomer();
				this.isEdit = false;
				this.drawerkh = true;
			},
			nodclick(data, node, res) {
				// // // console.log(data);
				this.deptId = data.id;
				this.deptName = data.label;
				this.nowPage = 1;
				this.getKhList()
			},
			// 多选下拉
			getCustomer(){
				this.options = [];
				this.$axios.post('/task/findCustomerById', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId:this.deptId
					})
					.then(res => {
						// // console.log(res);
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
					this.options = res.data.data;
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			getKhList(search){
				this.tableLoading = true;
				// 客户列表
				var khdeptid, khlink_dept = '';
				if (sessionStorage.getItem('type') == 0 | sessionStorage.getItem('type') == 1) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				// if (!this.deptId) {
				// 	khdeptid = sessionStorage.getItem('deptId');
				// }else{
				// 	khdeptid = this.deptId;
				// }
				if(this.deptId){
					khdeptid = this.deptId;
				}
				this.$axios.post('/task/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: khdeptid,
						link_dept: khlink_dept,
						page: this.nowPage,
						limit: this.limit,
						search:this.tablekwd,
						state:this.state
					})
					.then(res => {
						// // console.log(res);
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						this.tableLoading = false;
						this.tableData = res.data.data;
						this.tableTotal = res.data.count;
					})
					.catch(error => {
						console.error('error:', error)
					})
			}
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val)
			},
			'ssdwForm.ditusou'() {
				this.autoInput();
			}
		},
		mounted() {
			// 左侧导航栏
			this.$axios.post('/dept/tree', {
					userId: sessionStorage.getItem('userId'),
					type: sessionStorage.getItem('type'),
					deptId: sessionStorage.getItem('deptId')
				})
				.then(res => {
					if (res.data.code != 666) {
						ElMessage.error(res.data.msg);
						return
					}
					this.dataSource = res.data.data;
					this.$nextTick(()=>{
							$('.nodeLeft').width('auto');
						 $('.nodeRight').width( $('.flex-div').width() - $('.nodeLeft').width() - 10);
					})
				})
				.catch(error => {
					console.error('error:', error)
				})
			// 列表
			this.getKhList();
		}
	}
</script>
 
<style scoped>
	.ptext{
		margin-right: 15px;
	}
	.el-row {
		height: 100%;
		flex-wrap: nowrap !important;
	}
	
	.left {
		width: 16.667%;
		margin-right: 10px;
	}
	
	.right {
		flex: 1;
		width: 80%;
	}
	
	.custom-tree-node {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	
	.filter-input {
		width: 100%;
		display: flex;
		height: 50px;
		align-items: center;
		margin-bottom: 15px;
	}
	
	.right-title {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	.drawer-div {
		width: 100%;
		height: calc(100% - 50px);
		box-sizing: border-box;
		padding: 10px;
	}
	.radius-w {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		line-height: 28px;
		text-align: center;
		/* display: flex;
		align-items: center;
		justify-content: center; */
		cursor: pointer;
		color: #FFFFFF;
	}
	.right-main {
		width: 100%;
		height: calc(100% - 100px);
		overflow: auto;
	}
	.gd-ditu {
		width: 100%;
		height: 400px;
		border-radius: 14px;
		position: relative;
	}
	.ditu-center {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 98;
	}
	.mapList {
		width: 100%;
		position: absolute;
		top: 40px;
		left: 0;
		z-index: 99;
		background: #fff;
		text-align: left;
		list-style: none;
	}
	
	.mapList>li {
		box-sizing: border-box;
		padding-left: 15px;
	}
	
	.mapList>li:hover {
		background: #f2f4f5;
	}
	.nodeRight,.nodeLeft{
		transition:  .3s linear;;
	}
</style>
