<template>
	<el-row :gutter="20" style="flex-wrap: none !important;" class="flex-div">
		<div class="left nodeLeft" >
			<div style="height: 100%;background-color: #fff;padding: 0 10px;border-radius: 14px;">
				<p style="line-height: 45px;">所属单位</p>
				<div class="block" style="height: calc(100% - 65px);overflow: auto;">
					<div class="filter-input">
						<el-input v-model="filterText" placeholder="输入搜索关键字" />
						<!-- <el-button type="primary" size="small" style="margin-left: 10px;" @click="drawerOpen()">
							<el-icon>
								<plus />
							</el-icon>
							新增
						</el-button> -->
					</div>
					<el-tree :data="dataSource" node-key="treedept" class="tree-w" ref="tree"
						:filter-node-method="filterNode" @node-click="nodclick"
						:expand-on-click-node="false"
						:highlight-current="true"
						@node-expand="treeExpand"
						@node-collapse="treecollapse"
						>
						<template #default="{ node, data }">
							<span class="custom-tree-node">
								<p class="ptext">{{ node.label }}</p>
								<span style="display: flex;align-items: center;">
									<el-icon @click="append(node,data)" style="color: #2f49d7;">
										<plus />
									</el-icon>
									<el-icon style="margin: 0 5px;" @click="edit(node, data)">
										<edit />
									</el-icon>
									<!-- <el-icon @click="remove(node, data)" style="color: #F56C6C;">
										<delete />
									</el-icon> -->
									<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
										icon-color="red" title="确定删除吗?" @confirm="remove(node, data)">
										<template #reference>
											<el-icon style="color: #F56C6C;">
												<delete />
											</el-icon>
										</template>
									</el-popconfirm>
								</span>
							</span>
						</template>
					</el-tree>
				</div>
			</div>
		</div>

		<div class="right nodeRight" >
			<div style="height: 100%;background-color: #fff;border-radius: 14px;box-sizing: border-box;padding: 15px;">
				<p style="line-height: 35px;">单位人员</p>
				<div class="right-title">
					<el-input style="width: 240px;" v-model="tablekwd" placeholder="请输入关键字">
						<template #suffix>
							<el-icon class="el-input__icon" @click="searchBtn">
								<search />
							</el-icon>
						</template>
					</el-input>
					<el-button color="#576CD9" style="color:#FFFFFF;" @click="khClick">
						<el-icon style="margin-right: 5px;">
							<plus />
						</el-icon>新建客户
					</el-button>
				</div>
				<div class="right-main">
					<el-table :data="tableData" style="width: 100%" v-loading="tableLoading">
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column prop="deptName" label="所属单位" width="180" />
						<el-table-column prop="username" label="用户名称" />
						<el-table-column prop="account" label="账号" width="100" />
						<el-table-column prop="upmode" label="上传模式" width="100">
							<template #default="scope">
								<span v-if="scope.row.upmode == `'camera'`">拍照上传</span>
								<span v-else>相册和拍照上传</span>
							</template>
						</el-table-column>
						<el-table-column prop="state" label="状态" width="80">
							<template #default="scope">
								<!-- <el-button size="mini" type="success" v-if="scope.row.state == 1">正常</el-button>
								<el-button size="mini" type="warning" v-if="scope.row.state == 2">停用</el-button>
								<el-button size="mini" type="danger" v-if="scope.row.state == 3">欠费</el-button> -->
								<el-popover  placement="top" :width="160"  v-if="scope.row.state == 1">
								    <p>请选择状态</p>
								    <div style="text-align: right; margin: 0">
										<el-popconfirm title="确定要停用服务吗?" @confirm="stopServe(scope.$index, scope.row,2)">
										    <template #reference>
										      <el-button size="mini" type="info">停用</el-button>
										    </template>
										</el-popconfirm>
								    </div>
								    <template #reference>
								      <el-button size="mini" type="success">正常</el-button>
								    </template>
								</el-popover>
								
								<el-popover  placement="top" :width="160"  v-if="scope.row.state == 2">
								    <p>请选择状态</p>
								    <div style="text-align: right; margin: 0">
										<el-popconfirm title="确定要恢复服务吗?" @confirm="stopServe(scope.$index, scope.row,1)">
										    <template #reference>
										      <el-button size="mini" type="success">恢复</el-button>
										    </template>
										</el-popconfirm>
								    </div>
								    <template #reference>
								      <el-button size="mini" type="warning">停用</el-button>
								    </template>
								</el-popover>
								
								<el-popover  placement="top" :width="160"  v-if="scope.row.state == 3">
								    <p>已经欠费，若想继续使用请联系管理员</p>
								    <div style="text-align: right; margin: 0">
								    </div>
								    <template #reference>
								      <el-button size="mini" type="danger">欠费</el-button>
								    </template>
								</el-popover>
								
							</template>
						</el-table-column>
						<el-table-column label="操作" >
							<template #default="scope">
								<div style="display: flex;">

									<div @click="accountEdit(scope.$index, scope.row)" title="修改密码" class="radius-w"
										style="background-color: #8CABFF;">
										<el-icon>
											<lock />
										</el-icon>
									</div>

									<div @click="handleEdit(scope.$index, scope.row)" title="修改用户名" class="radius-w"
										style="background-color: #35AC9E; margin-left: 10px;">
										<el-icon>
											<edit />
										</el-icon>
									</div>
									
									<div @click="zhEdit(scope.$index, scope.row)" title="修改账号" class="radius-w"
										style="background-color: #67c23a;margin: 0 10px;">
										<el-icon>
											<user />
										</el-icon>
									</div>
									
									<div @click="scmsEdit(scope.$index, scope.row)" title="修改上传模式" class="radius-w"
										style="background-color: #67c23a;margin: 0 10px;margin-left: 0;">
										<el-icon>
											<camera />
										</el-icon>
									</div>

									<el-popconfirm confirm-button-text="确定" cancel-button-text="取消" 
										icon-color="red" @confirm="handleDelete(scope.$index, scope.row)"
										title="确定删除吗?">
										<template #reference>
											<div title="删除" class="radius-w" style="background-color: #CF5B5B;">
												<el-icon>
													<remove />
												</el-icon>
											</div>
										</template>
									</el-popconfirm>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="block">
					<el-pagination layout="prev, pager, next,total" :total="tableTotal"
					 :page-size="limit" 
					 @current-change="changePage"
					 v-model:current-page="nowPage"></el-pagination>
				</div>
			</div>
		</div>
		
		
		<!-- 抽屉 -->
		<el-drawer v-model="drawer" size='25%' @opened="drawerOpened()" @closed="resetForm" title="添加单位">
			<div class="drawer-div">
				<el-form ref="ssdwForm" :model="ssdwForm" :rules="rules" label-width="80px">
					<el-form-item label="单位名称" prop="dwmc">
						<el-input v-model="ssdwForm.dwmc" :disabled="clickType == 'edit'"></el-input>
					</el-form-item>
					<el-form-item label="到期时间" v-if="userType ==0">
						<el-date-picker style="width: 100%;" v-model="tjdwsj" value-format="YYYY-MM-DD" type="date"
							placeholder="选择到期时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="单位地址" prop="ditusou">
						<ul class="mapList" v-show="mapInpoutOnFocus">
							<li v-for="(item,index) in mapList" @click="setCenter(item.location,item.name)">{{item.name}}
							</li>
						</ul>
						<el-input id="pickerInput" v-model="ssdwForm.ditusou" @focus="mapInpoutOnFocus = true"
							@blur="mapblur"></el-input>
		
					</el-form-item>
				</el-form>
				<!-- <label class="el-form-item__label lebel-w">单位地址</label> -->
				<div class="gd-ditu">
					<div class="ditu-center">
						<el-icon style="font-size: 52px;">
							<plus />
						</el-icon>
					</div>
					<!-- 地图 -->
					<div style="width: 100%;height: 100%;" id="container"></div>
					<!-- 搜索 -->
				</div>
			</div>
			<div style="width: 100%;text-align: left;box-sizing: border-box;padding-left: 20px;">
				<el-button type="primary" @click="ssdwBtn('ssdwForm')">提交</el-button>
			</div>
		</el-drawer>
		<!-- 添加客户 -->
		<el-drawer v-model="drawerkh" size='25%' @close="resetFormkh()" title="添加客户">
			<div class="drawer-div">
				<el-form ref="khForm" :model="khForm" :rules="khrules" label-width="80px">
					<el-form-item label="所属单位" prop="deptName">
						<el-input v-model="khForm.deptName" disabled ></el-input>
					</el-form-item>
					<el-form-item label="客户名称" prop="khmc">
						<el-input v-model="khForm.khmc"></el-input>
					</el-form-item>
					<el-form-item label="输入账号" prop="user">
						<el-input v-model="khForm.user"></el-input>
					</el-form-item>
					<el-form-item label="上传模式" prop="upmode">
						<el-radio v-model="khForm.upmode" label="'camera'" size="large" border>拍照上传</el-radio>
						<el-radio v-model="khForm.upmode" label="'album','camera'" size="large" border>拍照和相册</el-radio>
					</el-form-item>
					<el-tag type="warning">注：登陆密码同账号</el-tag>
					<!-- <el-form-item label="输入密码" prop="password">
						<el-input v-model="khForm.password"></el-input>
					</el-form-item> -->
				</el-form>
				<!-- <label class="el-form-item__label lebel-w">单位地址</label> -->
			</div>
			<div style="width: 100%;text-align: left;box-sizing: border-box;padding-left: 20px;">
				<el-button type="primary" @click="khBtn('khForm')">提交</el-button>
			</div>
		</el-drawer>
		<!-- 修改密码弹窗 -->
		<el-dialog v-model="dialogFormVisible" title="修改密码" :show-close="false">
		
			<el-form ref="PasswordForm" :model="PasswordForm" :rules="Passwordrules" label-width="120px"
				class="demo-ruleForm">
				<el-form-item label="新密码" prop="old">
					<el-input v-model="PasswordForm.old" type="password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="new">
					<el-input v-model="PasswordForm.new" type="password" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
		
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormVisible = false ; resetForm1('PasswordForm')">取消</el-button>
					<el-button type="primary" @click="passwordBtn('PasswordForm')">确定</el-button>
				</span>
			</template>
		</el-dialog>
		
		<!-- 修改用户名称 -->
		<el-dialog v-model="dialogFormuser" title="修改用户名称" :show-close="false">
		
			<el-form label-width="120px" :model="xgxxFrom"
				class="demo-ruleForm">
				<el-form-item label="用户名称" prop="old">
					<el-input v-model="xgxxFrom.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
		
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormuser = false">取消</el-button>
					<el-button type="primary" @click="xgyhmBtn">确定</el-button>
				</span>
			</template>
		</el-dialog>
		
		<!-- 修改账号 -->
		<el-dialog v-model="dialogFormzh" title="修改账号" :show-close="false">
		
			<el-form ref="xgxxFrom" :model="xgxxFrom" :rules="xgxxdrules" label-width="120px"
				class="demo-ruleForm">
				<el-form-item label="用户账号" prop="accout">
					<el-input v-model="xgxxFrom.accout"  autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
		
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormzh = false">取消</el-button>
					<el-button type="primary" @click="accountBtn('xgxxFrom')">确定</el-button>
				</span>
			</template>
		</el-dialog>
		
		<!-- 修改上传模式 -->
		<el-dialog v-model="dialogFormscms" title="修改上传模式" :show-close="false">
		
			<el-form  label-width="150px"
				class="demo-ruleForm">
				<el-form-item label="上传模式" style="text-align: left;">
					<el-radio v-model="updateUpmode" label="'camera'" size="large" border>拍照上传</el-radio>
					<el-radio v-model="updateUpmode" label="'album','camera'" size="large" border>拍照和相册</el-radio>
				</el-form-item>
			</el-form>
		
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormscms = false">取消</el-button>
					<el-button type="primary" @click="scmsBtn">确定</el-button>
				</span>
			</template>
		</el-dialog>

	</el-row>

</template>

<script>
	import md5 from 'js-md5'
	import {
		ElMessage,
		ElLoading
	} from 'element-plus'
	export default {
		data() {
			const validatePass1 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('账号不能为空'))
				} else {
					// console.log(value);
					this.$axios.post('/user/check', {
							userId: sessionStorage.getItem('userId'),
							type: sessionStorage.getItem('type'),
							account: value
						})
						.then(res => {
							// console.log(res);
							if (res.data.code != 666) {
								// console.log(111);
								callback(new Error(res.data.msg))
								callback()
							}
							callback()
						})
						.catch(error => {
							callback()
							console.log('error:', error)
						})

				}
			}
			const validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入新密码'))
				} else {
					callback()
				}
			}
			const validatePass3 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入新密码'))
				} else if (value !== this.PasswordForm.old) {
					callback(new Error("两次输入的密码不一致"))
				} else {
					callback()
				}
			}
			return {
				dialogFormscms:false,
				updateUpmode:"'camera'",
				tableLoading:true,
				nowPage:1,
				filterText: '',
				dataSource: [],
				tablekwd: '',
				drawer: false,
				drawerkh: false,
				dialogFormVisible: false,
				dialogFormuser:false,
				dialogFormzh:false,
				clickType: 'add',
				ssdwForm: {
					dwmc: '',
					ditusou: '',
					district:''
				},
				khForm: {
					khmc: '',
					user: '',
					password: '',
					ssdm: '',
					deptId: '',
					deptName: '',
					upmode:"'camera'"
				},
				tjdwsj: '',
				userType: sessionStorage.getItem('type'),
				mapList: [],
				map: '',
				mapInpoutOnFocus: false,
				centerAdderss: '',
				treenode: '',
				editGps: [],
				limit: 12,
				tableData: [],
				tableTotal: 0,
				PasswordForm: {
					old: '',
					new: ''
				},
				userCheack: '',
				isEdit:false,
				nowIndex:0,
				xgxxFrom:{
					name:'',
					accout:''
				},
				treeNum:0,
				treeLeftWidth:0,
				treeRightWidth:0,
				// 表单验证
				rules: {
				 dwmc: [{
						required: true,
						message: '请填写单位名称',
						trigger: 'blur',
					}, ],
					ditusou: [{
						required: true,
						message: '请选择单位地址',
						trigger: 'blur',
					}, ]
				},
				// 客户表单验证
				khrules: {
					deptName: [{
						required: true,
						message: '请在左侧选择所属单位后添加用户',
						trigger: 'blur',
					}, ],
					khmc: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
					password: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
					user: [{
						validator: validatePass1,
						trigger: 'blur',
						required: true
					}],
				},
				// 账号修改验证
				xgxxdrules:{
					accout: [{
						validator: validatePass1,
						trigger: 'blur',
						required: true
					}]
				},
				// 密码修改验证
				Passwordrules: {
					old: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					new: [{
						validator: validatePass3,
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			treecollapse(data,Node,seft){
				this.treeNum = this.treeNum - 1;
				var treeNum = this.treeNum;
				if (Node.level == 1) {
					$('.nodeRight').width('auto');
					$('.nodeLeft').width('16.667%');
					return
				}
				var treeLeftWidth = this.treeLeftWidth;
				var treeRightWidth = this.treeRightWidth;
				var nodePb = $('.el-tree-node.is-expanded .ptext');
				setTimeout(()=> {
					// nodeLeft
					var nodeP = $('.el-tree-node.is-expanded .ptext');
					var maxWidth = 0;
					nodeP.each(function (){
						maxWidth = maxWidth < $(this).width() ? $(this).width() : maxWidth;
					})
					$('.nodeRight').width(  $('.nodeRight').width() +  (treeNum * 20));
					$('.nodeLeft').width( $('.nodeLeft').width() -  (treeNum * 20) );
					// console.log(maxWidth);
					if (maxWidth <= 80) {
						maxWidth = 80
					}
					$('.nodeRight').width(  treeRightWidth - maxWidth)
					maxWidth = treeLeftWidth + maxWidth;
					$('.nodeLeft').width(maxWidth);
				},500)
			},
			treeExpand(data,Node,seft){
				if (this.treeNum == 0) {
					this.treeLeftWidth = $('.nodeLeft').width();
					this.treeRightWidth = $('.nodeRight').width();
				}
				this.treeNum = this.treeNum + 1;
				var treeNum = this.treeNum;
				var treeLeftWidth = this.treeLeftWidth;
				var treeRightWidth = this.treeRightWidth;
				setTimeout(()=> { 
					// nodeLeft
					var nodeP = $('.el-tree-node.is-expanded .ptext');
					var maxWidth = 0;
					nodeP.each(function (){
						maxWidth = maxWidth < $(this).width() ? $(this).width() : maxWidth;
					})
					$('.nodeRight').width(  $('.nodeRight').width() -  (treeNum * 20));
					$('.nodeLeft').width( $('.nodeLeft').width() +  (treeNum * 20) );
						$('.nodeRight').width(  treeRightWidth - maxWidth)
						maxWidth = treeLeftWidth + maxWidth;
						$('.nodeLeft').width(maxWidth);
				},500)
			},
			searchBtn(){
				this.nowPage = 1;
				this.getKhList(this.tablekwd)
			},
			stopServe(index, row,state){
				this.$axios.post('/user/updateUserState', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						khUserId: row.userId,
						state:state
					})
					.then(res => {
						// console.log(res);
					if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.tableData[index].state = state;
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			accountBtn(formName){
				
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/user/updateAccount', {
								userId: sessionStorage.getItem('userId'),
								type: sessionStorage.getItem('type'),
								khUserId: this.userCheack.userId,
								account: this.xgxxFrom.accout
							})
							.then(res => {
								// console.log(res);
							if (res.data.code != 666) {
									ElMessage.error(res.data.msg);
									return
								}
								ElMessage.success(res.data.msg);
								this.dialogFormzh = false;
								this.tableData[this.nowIndex].account = this.xgxxFrom.accout;
							})
							.catch(error => {
								console.log('error:', error)
							})
					} else {
						// console.log('error submit!!')
						return false
					}
				})
			},
			scmsBtn(){
				this.$axios.post('/user/upmode', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						khUserId: this.userCheack.userId,
						upmode: this.updateUpmode
					})
					.then(res => {
						// console.log(res);
					if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.dialogFormscms = false;
						this.getKhList();
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			xgyhmBtn(){
				this.$axios.post('/user/updateName', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						khUserId: this.userCheack.userId,
						username: this.xgxxFrom.name
					})
					.then(res => {
						// console.log(res);
					if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.dialogFormuser = false;
						this.tableData[this.nowIndex].username = this.xgxxFrom.name;
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			passwordBtn(formName) {
				// console.log(formName);
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/user/updatePaw', {
								userId: sessionStorage.getItem('userId'),
								type: sessionStorage.getItem('type'),
								khUserId: this.userCheack.userId,
								newPass: this.PasswordForm.new
							})
							.then(res => {
								// console.log(res);
					 		if (res.data.code != 666) {
									ElMessage.error(res.data.msg);
									return
								}
								ElMessage.success(res.data.msg);
								this.dialogFormVisible = false;
								this.resetForm1('PasswordForm')
							})
							.catch(error => {
								console.log('error:', error)
							})
					} else {
						// console.log('error submit!!')
						return false
					}
				})

			},
			accountEdit(index, row) {
				this.userCheack = row;
				this.dialogFormVisible = true;
			},
			handleEdit(index, row) {
				this.isEdit = true;
				// console.log(index, row)
				this.userCheack = row;
				this.nowIndex = index;
				this.dialogFormuser = true;
				this.xgxxFrom.name = row.username;
			},
			zhEdit(index, row){
				this.userCheack = row;
				this.nowIndex = index;
				this.dialogFormzh = true;
				this.xgxxFrom.accout = row.account;
			},
			scmsEdit(index, row){
				console.log(row);
				this.userCheack = row;
				this.updateUpmode = row.upmode;
				this.dialogFormscms = true;
			},
			handleDelete(index, row) {
				// console.log(index, row)
				this.$axios.post('/user/del', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						khUserId: row.userId,
					})
					.then(res => {
						// console.log(res);
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
						return
						}
						ElMessage.success(res.data.msg);
						this.tableData.splice(index,1);
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			nodclick(data, node, res) {
				this.tableLoading = true;
				this.khForm.deptId = data.id;
				this.khForm.deptName = data.label;
				this.nowPage = 1;
				// 客户列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 | sessionStorage.getItem('type') == 1) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/user/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: node.data.id,
						link_dept: khlink_dept,
						page: this.nowPage,
						limit: this.limit
					})
					.then(res => {
						this.tableLoading = false;
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						this.tableData = res.data.data;
						this.tableTotal = res.data.count;
					})
					.catch(error => {
						this.tableLoading = false;
						console.log('error:', error)
					})
				
			},
			khClick() {
				if (!this.khForm.deptName) {
					ElMessage.error('请先选择左侧单位');
					return
				}
				this.isEdit = false;
				this.drawerkh = true;
			},
			append(node, data) {
				this.clickType = 'add';
				this.treenode = node;
				this.drawerOpen();
			},
			edit(node, data) {
				this.clickType = 'edit';
				this.treenode = node;
				this.$axios.post('/dept/findById', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: this.treenode.data.id
					})
					.then(res => {
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						var data = res.data.data[0];
						this.ssdwForm.dwmc = data.deptName;
						this.ssdwForm.ditusou = data.address;
						this.tjdwsj = data.endTime;
						this.editGps = data.gps.split(",");
					})
					.catch(error => {
						console.log('error:', error)
					})

				this.drawerOpen();
			},
			remove(node, data) {
				if (!node.parent.data.id) {
					ElMessage.error('根节点不可删除！');
					return
				}
				// console.log(data);
				this.$axios.post('/dept/del', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: node.data.id
					})
					.then(res => {
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						const parent = node.parent
						const children = parent.data.children || parent.data
						const index = children.findIndex((d) => d.id === data.id)
						children.splice(index, 1)
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			filterNode(value, data) {
				if (!value) return true
				return data.label.indexOf(value) !== -1
			},
			drawerOpen() {
				this.drawer = true;
				setTimeout(res => {
					this.autoInput()

				}, 1500)
			},
			drawerOpened() {
				let that = this;
				setTimeout(function() {
					var center,zoom;
					if (that.editGps.length > 0) {
						center = that.editGps;
						zoom = 18;
					} else {
						center = [116.397428, 39.90923];
						zoom = 14;
					}
					that.map = new AMap.Map('container', {
						zoom: zoom, //级别
						center: center, //中心点坐标
						viewMode: '3D' //使用3D视图
					});
					that.map.on('dragend', that.gpsToAdderss)
				}, 1000)
			},
			autoInput() {
				let that = this;
				AMap.plugin('AMap.Autocomplete', function() {
					// 实例化Autocomplete
					var autoOptions = {
						city: '全国'
					}
			  var autoComplete = new AMap.Autocomplete(autoOptions);
					autoComplete.search(that.ssdwForm.ditusou, function(status, result) {
						// 搜索成功时，result即是对应的匹配数据
						if (result.info == 'OK') {
							that.mapList = result.tips;
						}
						// console.log(result);
					})
				})
			},
			setCenter(location, adderss) {
				if (location) {
					this.map.setZoomAndCenter(18, [location.lng, location.lat]);
					this.ssdwForm.ditusou = adderss;
				}
			},
			mapblur() {
				setTimeout(res => {
					this.mapInpoutOnFocus = false;
				}, 200)
			},
			ssdwBtn(formName) {
				let that = this;
				var urlType = '';
				if (this.clickType == 'edit') {
					urlType = '/dept/update'
				} else if (this.clickType == 'add') {
					urlType = '/dept/add'
				}
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var center = this.map.getCenter();
						var geocoder = new AMap.Geocoder({
							radius: 500 //范围，默认：500
						});
						geocoder.getAddress([center.lng, center.lat], (status, result) => {
							// that.ssdwForm.ditusou = result.regeocode.formattedAddress;
							this.$axios.post(urlType, {
									userId: sessionStorage.getItem('userId'),
									type: sessionStorage.getItem('type'),
									deptName: this.ssdwForm.dwmc,
									parentId: this.treenode.data.id,
									parentName: this.treenode.data.label,
									gps: [center.lng, center.lat].toString(),
									endTime: this.tjdwsj,
									address: this.ssdwForm.ditusou,
									district:this.ssdwForm.district,
									deptId: this.treenode.data.id
								})
								.then(res => {
									if (res.data.code != 666) {
										ElMessage.error(res.data.msg);
										return
									}

									ElMessage.success(res.data.msg);
									// 关闭右侧弹出
									this.drawer = false;
									// 清空弹窗内内容
									// this.resetForm('ssdwForm');
									// 清空坐标
									this.editGps = [];
									// console.log(this.clickType);
									if (this.clickType == 'add') {
										var sourceD = {
											id: res.data.deptId,
											label: this.ssdwForm.dwmc,
											children: []
										}
										if (!this.treenode.data.children) {
											this.treenode.data.children = []
										}
										this.treenode.data.children.push(sourceD)
									} else {
										this.treenode.data.label = this.ssdwForm.dwmc;
									}
								})
								.catch(error => {
									console.log('error:', error)
								})

						});

					} else {
						// // console.log('填写不能为空')
						return false
					}
				})

			},
			khBtn(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var link_dept;
						if (sessionStorage.getItem('type') == 3) {
							link_dept = sessionStorage.getItem('link_dept');
						} else {
							link_dept = sessionStorage.getItem('deptId');
						}
						if (this.isEdit) {
							// 修改
							this.$axios.post('/user/update', {
									userId: sessionStorage.getItem('userId'),
									type: sessionStorage.getItem('type'),
									deptId: this.khForm.deptId,
									account: this.khForm.user,
									username: this.khForm.khmc,
									deptName: this.khForm.deptName,
									upmode: this.khForm.upmode,
									khUserId:this.userCheack.userId
								})
								.then(res => {
									if (res.data.code != 666) {
										ElMessage.error(res.data.msg);
										return
									}
									ElMessage.success(res.data.msg);
									// 关闭右侧弹出
									this.drawerkh = false;
								})
								.catch(error => {
									console.log('error:', error)
								})
							
							
						}else{
							// 新增
							this.$axios.post('/user/add', {
									userId: sessionStorage.getItem('userId'),
									type: sessionStorage.getItem('type'),
									deptId: this.khForm.deptId,
									account: this.khForm.user,
									username: this.khForm.khmc,
									deptName: this.khForm.deptName,
									upmode: this.khForm.upmode,
									link_dept: link_dept
								})
								.then(res => {
									if (res.data.code != 666) {
										ElMessage.error(res.data.msg);
										return
									}
									ElMessage.success(res.data.msg);
									// 关闭右侧弹出
									this.drawerkh = false;
									this.tableData.push({
										account: this.khForm.user,
										deptID: this.khForm.deptId,
										deptName: this.khForm.deptName,
										state: 1,
										userId: res.data.userId,
										username: this.khForm.khmc
									})
								})
								.catch(error => {
									console.log('error:', error)
								})
								
						}
						

					} else {
						// console.log('error submit!!')
						return false
					}
				})
			},
			gpsToAdderss() {
				let that = this;
				var location = this.map.getCenter();
				if (!location) {
					return
				}
				var geocoder = new AMap.Geocoder({
					radius: 500 //范围，默认：500
				});
				geocoder.getAddress([location.lng, location.lat], function(status, result) {
					// console.log(status);
					// console.log(result);
					that.ssdwForm.ditusou = result.regeocode.formattedAddress;
					that.ssdwForm.district = result.regeocode.addressComponent.district;
				});
			},
			resetForm(formName) {
				this.ssdwForm.dwmc = '';
				this.ssdwForm.ditusou = '';
				this.tjdwsj = '';
				this.editGps = [];
				this.$refs.ssdwForm.clearValidate();
			},
			resetFormkh(formName) {
					this.khForm.khmc = '';
					this.khForm.user = '';
					this.khForm.password = '';
					this.khForm.ssdm = '';
					this.khForm.upmode = "'camera'";
					this.$refs.khForm.clearValidate();
			},
			resetForm1(formName) {
				this.$refs[formName].resetFields()
			},
			getKhList(search){
				this.tableLoading = true;
				// 客户列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 | sessionStorage.getItem('type') == 1) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/user/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId:this.khForm.deptId,
						link_dept: khlink_dept,
						page: this.nowPage,
						limit: this.limit,
						search:search
					})
					.then(res => {
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						this.tableLoading = false;
						this.tableData = res.data.data;
						this.tableTotal = res.data.count;
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			changePage(){
				this.getKhList();
			}
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val)
			},
			'ssdwForm.ditusou'() {
				this.autoInput();
			}
		},
		mounted() {
			// 左侧导航栏
			this.$axios.post('/dept/tree', {
					userId: sessionStorage.getItem('userId'),
					type: sessionStorage.getItem('type'),
					deptId: sessionStorage.getItem('deptId')
				})
				.then(res => {
					if (res.data.code != 666) {
						ElMessage.error(res.data.msg);
						return
					}
					this.dataSource = res.data.data;
					 this.$nextTick(()=>{ 
						 	$('.nodeLeft').width('auto');
						 $('.nodeRight').width( $('.flex-div').width() - $('.nodeLeft').width() - 10);
					 })
				})
				.catch(error => {
					console.log('error:', error)
				})

			// 客户列表
			this.getKhList();

		}
	}

	function poiPickerReady(poiPicker) {
		window.poiPicker = poiPicker;
		var marker = new AMap.Marker();
		var infoWindow = new AMap.InfoWindow({
			offset: new AMap.Pixel(0, -20)
		});
	}
</script>

<style scoped>
	.ptext{
		margin-right: 15px;
	}
	.el-row {
		height: 100%;
		flex-wrap: nowrap !important;
	}

	.left {
		width: 16.667%;
		margin-right: 10px;
	}

	.right {
		/* width: 80%; */
		flex: 1;
	}

	.custom-tree-node {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	}
	.filter-input {
		width: 100%;
		display: flex;
		height: 50px;
		align-items: center;
		margin-bottom: 15px;
	}

	.right-title {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.drawer-div {
		width: 100%;
		height: calc(100% - 50px);
		box-sizing: border-box;
		padding: 10px;
	}

	.gd-ditu {
		width: 100%;
		height: 400px;
		border-radius: 14px;
		position: relative;
	}

	.lebel-w {
		width: 100%;
		display: block;
		text-align: left;
		line-height: 45px;
		font-size: 14px;
		padding-left: 12px;
	}

	.mapList {
		width: 100%;
		position: absolute;
		top: 40px;
		left: 0;
		z-index: 99;
		background: #fff;
		text-align: left;
		list-style: none;
	}

	.mapList>li {
		box-sizing: border-box;
		padding-left: 15px;
	}

	.mapList>li:hover {
		background: #f2f4f5;
	}

	.ditu-center {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 98;
	}

	.right-main {
		width: 100%;
		height: calc(100% - 100px);
		overflow: auto;
	}

	.radius-w {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		line-height: 28px;
		text-align: center;
		/* display: flex;
		align-items: center;
		justify-content: center; */
		cursor: pointer;
		color: #FFFFFF;
	}
	.nodeRight,.nodeLeft{
		transition:  .3s linear;;
	}
</style>
