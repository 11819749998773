<template>
	<el-row :gutter="20" style="flex-wrap: none !important;align-items: normal;">
		<!-- <div class="left" style="display: none;">
			<div style="height: 100%;background-color: #fff;padding: 0 10px;border-radius: 14px;">
				<p style="line-height: 45px;">所属单位</p>
				<div class="block">
					<div class="filter-input">
						<el-input v-model="filterText" placeholder="输入搜索关键字" />
					</div>
					<el-tree :data="dataSource" node-key="treedept" class="tree-w" ref="tree"
						:filter-node-method="filterNode" @node-click="nodclick"
						:expand-on-click-node="false"
						>
						<template #default="{ node, data }">
							<span class="custom-tree-node">
								<span>{{ node.label }}</span>
								<span>
									<el-icon @click="append(node,data)" style="color: #2f49d7;">
										<plus />
									</el-icon>
									<el-icon style="margin: 0 5px;" @click="edit(node, data)">
										<edit />
									</el-icon>
									<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
										icon-color="red" title="确定删除吗?" @confirm="remove(node, data)">
										<template #reference>
											<el-icon style="color: #F56C6C;">
												<delete />
											</el-icon>
										</template>
									</el-popconfirm>
								</span>
							</span>
						</template>
					</el-tree>
				</div>
			</div>
		</div> -->

		<div class="right">
			<div style="height: 100%;background-color: #fff;border-radius: 14px;box-sizing: border-box;padding: 15px;">
				<div style="margin: 0 0 20px 0;text-align: left;">
					<el-radio-group v-model="dataType" @change="changeType">
						<el-radio-button v-if="userType != 2" label="5">未检出毒源</el-radio-button>
						<el-radio-button v-if="userType != 2" label="1">疑似毒源</el-radio-button>
						<el-radio-button label="2">待核查</el-radio-button>
						<el-radio-button label="3">已核查</el-radio-button>
						<el-radio-button label="4">全部</el-radio-button>
					</el-radio-group>
				</div>
				<div class="right-title">
					<div style="width: 40%;">
						<el-select class="rw-select" v-model="ckSelectTaskId" filterable
							style="width:49%;margin-right: 2%;height: 40px;" @change="selectCkRwPl">
							<el-option :label="item.taskName" :value="item.taskId" v-for="(item,index) in ckGlrwList">
							</el-option>
						</el-select>
						<el-input style="width: 49%;" v-model="tablekwd" placeholder="请输入关键字">
							<template #suffix>
								<el-icon class="el-input__icon" @click="searchBtn">
									<search />
								</el-icon>
							</template>
						</el-input>
					</div>
					<div style="min-width: 570px;">
						<el-button v-if="userType != 2" color="#576CD9" style="color:#FFFFFF;margin-right: 10px;"
							@click="hszClick">
							<el-icon style="margin-right: 5px;">
								<Delete />
							</el-icon>回收站
						</el-button>
						<!-- 回收站 -->
						<el-dialog v-model="drecycleBinVisible" title="回收站" width="90%" top="8vh">
							<el-row class="mb-4" style="justify-content: space-between;">
								<el-tag class="ml-2" type="danger">系统自动清理回收站中回收超过30天的毒源数据</el-tag>
								<el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon-color="red"
									@confirm="emptyReduction()" title="确定清空吗?">
									<template #reference>
										<el-button size="small" type="warning">清空回收站</el-button>
									</template>
								</el-popconfirm>
							</el-row>
							<el-table :data="drecycleBinData" highlight-current-row @selection-change="selectListRB">
								<el-table-column type="selection" width="55" v-if="userType != 2" />
								<el-table-column prop="dybh" label="毒源编号" width="180" :show-overflow-tooltip="true" />
								<el-table-column prop="address" label="毒源地址" :show-overflow-tooltip="true"
									width="200" />
								<el-table-column prop="level" label="毒源等级" />
								<el-table-column prop="taskName" label="关联任务" :show-overflow-tooltip="true" />
								<el-table-column prop="upTime" label="上传时间" :show-overflow-tooltip="true" width="110" />
								<el-table-column prop="state" label="毒源状态" width="80">
									<template #default="scope">
										<el-button size="mini" type="success" v-if="scope.row.state == 0">确认</el-button>
										<el-button size="mini" type="warning" v-if="scope.row.state == 1">疑似</el-button>
										<el-button size="mini" type="info" v-if="scope.row.state == 2">未检出</el-button>

									</template>
								</el-table-column>
								<el-table-column prop="checkUserName" label="核查人员" />
								<el-table-column prop="checkLevel" label="核查等级" />
								<el-table-column prop="checkTime" label="核查时间" :show-overflow-tooltip="true" />
								<el-table-column prop="checkState" label="核查状态" width="80">
									<template #default="scope">
										<!-- <el-button size="mini" type="success" v-if="scope.row.state == 1">正常</el-button>
						      		<el-button size="mini" type="warning" v-if="scope.row.state == 2">停用</el-button>
						      		<el-button size="mini" type="danger" v-if="scope.row.state == 3">欠费</el-button> -->
										<el-button size="mini" type="info" v-if="scope.row.checkState == 0">未检查
										</el-button>
										<el-button size="mini" type="success" v-if="scope.row.checkState == 1">已确认
										</el-button>
										<el-button size="mini" type="warning" v-if="scope.row.checkState ==2">未确认
										</el-button>

									</template>
								</el-table-column>
								<el-table-column label="操作" width="160">
									<template #default="scope">
										<div style="display: flex;">

											<div v-if="scope.row" @click="userLook(scope.$index, scope.row)" title="查看"
												class="radius-w" style="background-color: #8CABFF;margin-right: 5px;">
												<el-icon>
													<View />
												</el-icon>
											</div>

											<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
												icon-color="red" @confirm="handleReduction(scope.$index, scope.row)"
												title="确定还原吗?">
												<template #reference>
													<div title="还原" class="radius-w"
														style="background-color: #67C23A;margin-right: 5px;">
														<el-icon>
															<Refresh />
														</el-icon>
													</div>
												</template>
											</el-popconfirm>


											<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
												icon-color="red" @confirm="recycleDelete(scope.$index, scope.row)"
												title="确定删除吗?">
												<template #reference>
													<div title="删除" class="radius-w" style="background-color: #CF5B5B;">
														<el-icon>
															<remove />
														</el-icon>
													</div>
												</template>
											</el-popconfirm>
										</div>
									</template>
								</el-table-column>
							</el-table>

							<div class="block" style="display: flex;justify-content: space-between;margin-top: 10px;">
								<div style="width: 300px;display: flex;align-items: center;">
									<el-select v-if="userType != 2" v-model="listCheckSelectRB" placeholder="Select"
										size="small" :disabled="tableListCheckRB.length == 0">
										<el-option-group v-for="group in listCheckOptionsRB" :key="group.label"
											:label="group.label">
											<el-option v-for="item in group.options" :key="item.value"
												:label="item.label" :value="item.value" />
										</el-option-group>
									</el-select>

									<el-popconfirm confirm-button-text="确认" cancel-button-text="取消" icon-color="red"
										@confirm="confirmBatch(1)" title="确认批量操作吗?" v-if="userType != 2">
										<template #reference>
											<el-button style="margin-left: 15px;" size="small" type="primary"
												:disabled="tableListCheckRB.length == 0"
												:loading="plCheckBtnRB">批量操作</el-button>
										</template>
									</el-popconfirm>
								</div>
								<el-pagination layout="prev, pager, next,total" :total="BintableTotal"
									:page-size="limit" @current-change="BinchangePage"
									v-model:current-page="BintableNowPage"></el-pagination>
							</div>

						</el-dialog>

						<el-button v-if="userType != 2" color="#576CD9" style="color:#FFFFFF;margin-right: 10px;"
							@click="plscBtn(true)">
							<el-icon style="margin-right: 5px;">
								<DocumentCopy />
							</el-icon>批量上传（含识别）
						</el-button>


						<el-button v-if="userType != 2" color="#576CD9" style="color:#FFFFFF;margin-right: 10px;"
							@click="plscBtn(false)">
							<el-icon style="margin-right: 5px;">
								<DocumentCopy />
							</el-icon>批量上传
						</el-button>

						<el-dialog v-model="dialogPlsc" :close-on-click-modal="false" title="批量上传" width="40%"
							@close="Plclose">
							<el-row style="align-items: center;">
								<p style="margin-right: 5px;min-width: 70px;">关联任务</p>
								<el-select v-model="uploadFistPlData.taskId" size="small" filterable
									style="width: 30%;margin-right: 15px;" @change="selectGetRwPl">
									<el-option :label="item.taskName" :value="item.taskId"
										v-for="(item,index) in glrwList"></el-option>
								</el-select>
								<p style="margin-right: 5px;min-width: 70px;" v-if="!isTest">毒源状态</p>
								<el-select v-model="plUpType" class="m-2" placeholder="Select" size="small"
									v-if="!isTest" style="width: 30%;margin-right: 15px;">
									<el-option v-for="item in plUpTypeOptions" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
								<el-dropdown split-button type="primary" size="small" @click="uploadDwjsc"
									style="min-width: 115px;" placement="bottom-end" trigger="click">
									{{uploadTypeTaext}}
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item @click="uploadXzwj">选择文件</el-dropdown-item>
											<el-dropdown-item @click="uploadXzml">选择目录</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</el-row>
							<div class="plsc-card">
								<div class="plsc-card-text" v-if="pcUploadList.length == 0">
									请点击上方按钮选择上传的文件
								</div>
								<ul class="el-upload-list el-upload-list--text" style="padding: 0 15px;">
									<li class="el-upload-list__item is-success" v-for="(item,index) in pcUploadList"
										:key="index">
										<a class="el-upload-list__item-name" style="text-align: left;color: #bfbfbf;"
											:class="{'sbwc-text':item.fileId && item.type == 2}">
											<i class="el-icon el-icon--document">
												<svg class="icon svg-text" width="200" height="200"
													viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
													<path fill="currentColor"
														d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 01-32 32H160a32 32 0 01-32-32V96a32 32 0 0132-32zm160 448h384v64H320v-64zm0-192h160v64H320v-64zm0 384h384v64H320v-64z">
													</path>
												</svg>
											</i>
											{{item.name}}

										</a>
										<label class="el-upload-list__item-status-label"
											style="display: flex;align-items: center;">
											<span v-if="item.scNum"
												style="display: inline-block;margin-left: 10px;color: #bfbfbf;">{{item.scNum}}</span>
											<span v-if="!item.fileId && index != 0"
												style="display: inline-block;margin-left: 10px;color: #cccccc;">
												<span v-if="item.type == 0 && !item.scNum">等待上传</span>
											</span>
											<span style="color: #1ba57b;padding-right: 5px;"
												v-if="item.type == 1 ">{{isTest?'正在识别...':'上传成功'}}</span>
											<span style="color: #1ba57b;padding-right: 5px;"
												v-if="item.type == 2 && !isTest">识别完成</span>
											<i v-if="item.fileId && item.type == 2"
												class="el-icon el-icon--upload-success el-icon--circle-check">
												<svg style="color: #1ba57b;" class="icon" width="200" height="200"
													viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
													<path fill="currentColor"
														d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z">
													</path>
													<path fill="currentColor"
														d="M745.344 361.344a32 32 0 0145.312 45.312l-288 288a32 32 0 01-45.312 0l-160-160a32 32 0 1145.312-45.312L480 626.752l265.344-265.408z">
													</path>
												</svg>
											</i>
										</label>
									</li>
								</ul>
								<!--  :action="fileUrlPl" -->
								<el-upload style="text-align: left;" action="#" :limit="1" :http-request="uploadSuccess"
									:file-list="dwjfileList" :show-file-list="false" :data="uploadFistPlData"
									:on-success="PlscUpload" :on-exceed="handleExceed" :on-remove="PlRemove"
									ref="Plupload" :before-upload="dwjscBefore" class="uoloadParetn" multiple>
									<!-- <span style="margin-left: 10px;">点击上方按钮上传文件</span> -->
									<!-- <el-button type="primary" size="small"> 点击上传
										<el-icon class="el-icon--right"><Upload /></el-icon>
									</el-button> -->
								</el-upload>
							</div>
							<el-row style="align-items: center;justify-content: flex-end;">
								<el-button type="primary" @click="Plshibie" size="small" :disabled="!plscwzShow">
									{{plscwz}}</el-button>
								<!-- <div style="flex: 1;height: 15px;margin-left: 10px;" v-if="kssbProgress">
									<el-progress :percentage="PlprogressNum" />
								</div> -->
							</el-row>
						</el-dialog>

						<el-button v-if="userType != 2" color="#576CD9" style="color:#FFFFFF;" @click="khClick">
							<el-icon style="margin-right: 5px;">
								<plus />
							</el-icon>信息上报
						</el-button>
					</div>
				</div>
				<div class="right-main">
					<el-table :data="tableData" v-loading="tableLoading" ref="multipleTableRef"
						@cell-dblclick="copyDydz" @selection-change="selectList" style="width: 100%"
						highlight-current-row>
						<el-table-column type="selection" v-if="userType != 2" />
						<el-table-column prop="dybh" label="毒源编号" width="180" :show-overflow-tooltip="true" />
						<el-table-column prop="address" label="毒源地址" :show-overflow-tooltip="true" width="200" />
						<el-table-column prop="level" label="毒源等级" />
						<el-table-column prop="taskName" label="关联任务" :show-overflow-tooltip="true" />
						<el-table-column prop="upTime" label="上传时间" :show-overflow-tooltip="true" width="160" />
						<el-table-column prop="state" label="毒源状态" width="80">
							<template #default="scope">
								<!-- <el-button size="mini" type="success" v-if="scope.row.state == 1">正常</el-button>
								<el-button size="mini" type="warning" v-if="scope.row.state == 2">停用</el-button>
								<el-button size="mini" type="danger" v-if="scope.row.state == 3">欠费</el-button> -->
								<el-button size="mini" type="success" v-if="scope.row.state == 0">确认</el-button>
								<el-button size="mini" type="warning" v-if="scope.row.state == 1">疑似</el-button>
								<el-button size="mini" type="info" v-if="scope.row.state == 2">未检出</el-button>
							</template>
						</el-table-column>
						<el-table-column prop="checkUserName" label="核查人员" v-if="dhcState == 3 || dhcState == 4" />
						<el-table-column prop="checkLevel" label="核查等级" v-if="dhcState == 3 || dhcState == 4" />
						<el-table-column prop="checkTime" label="核查时间" v-if="dhcState == 3 || dhcState == 4"
							:show-overflow-tooltip="true" />
						<el-table-column prop="state" label="核查状态" width="80">
							<template #default="scope">
								<!-- <el-button size="mini" type="success" v-if="scope.row.state == 1">正常</el-button>
								<el-button size="mini" type="warning" v-if="scope.row.state == 2">停用</el-button>
								<el-button size="mini" type="danger" v-if="scope.row.state == 3">欠费</el-button> -->
								<el-button size="mini" type="info" v-if="scope.row.checkState == 0">未检查</el-button>
								<el-button size="mini" type="success" v-if="scope.row.checkState == 1">已确认</el-button>
								<el-button size="mini" type="warning" v-if="scope.row.checkState ==2">未确认</el-button>

							</template>
						</el-table-column>
						<el-table-column label="操作" width="160">
							<template #default="scope">
								<div style="display: flex;">

									<div v-if="scope.row" @click="userLook(scope.$index, scope.row)" title="查看"
										class="radius-w" style="background-color: #8CABFF;">
										<el-icon>
											<View />
										</el-icon>
									</div>

									<div @click="handleEdit(scope.$index, scope.row)" title="修改" class="radius-w"
										style="background-color: #35AC9E; margin: 0 10px;"
										v-if="scope.row.over == 0 && userType !=2">
										<el-icon>
											<edit />
										</el-icon>
									</div>


									<el-popconfirm confirm-button-text="确定" cancel-button-text="取消" icon-color="red"
										v-if="scope.row.over == 0 && userType !=2"
										@confirm="handleDelete(scope.$index, scope.row)" title="确定删除吗?">
										<template #reference>
											<div title="删除" class="radius-w" style="background-color: #CF5B5B;">
												<el-icon>
													<remove />
												</el-icon>
											</div>
										</template>
									</el-popconfirm>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="block" style="display: flex;justify-content: space-between;margin-top: 10px;">
					<div style="width: 300px;display: flex;align-items: center;">
						<el-select v-if="userType != 2" v-model="listCheckSelect" placeholder="Select" size="small"
							:disabled="tableListCheck.length == 0">
							<el-option-group v-for="group in listCheckOptions" :key="group.label" :label="group.label">
								<el-option v-for="item in group.options" :key="item.value" :label="item.label"
									:value="item.value" />
							</el-option-group>
						</el-select>

						<el-popconfirm confirm-button-text="确认" cancel-button-text="取消" icon-color="red"
							@confirm="confirmBatch(0)" title="确认批量操作吗?" v-if="userType != 2">
							<template #reference>
								<el-button style="margin-left: 15px;" size="small" type="primary"
									:disabled="tableListCheck.length == 0" :loading="plCheckBtn">批量操作</el-button>
							</template>
						</el-popconfirm>
					</div>
					<el-pagination style="width: 600px;" layout="prev, pager, next,total" :total="tableTotal"
						:page-size="limit" @current-change="changePage"
						v-model:current-page="tableNowPage"></el-pagination>
				</div>
			</div>
		</div>

	</el-row>
	<!-- 抽屉 -->
	<!-- <el-drawer v-model="drawer" size='25%' :opened="drawerOpened()" :closed="resetForm" title="添加单位">
		<div class="drawer-div">
			<el-form ref="ssdwForm" :model="ssdwForm" :rules="rules" label-width="80px">
				<el-form-item label="单位名称" prop="dwmc">
					<el-input v-model="ssdwForm.dwmc"></el-input>
				</el-form-item>
				<el-form-item label="到期时间" v-if="userType ==0">
					<el-date-picker style="width: 100%;" v-model="tjdwsj" value-format="YYYY-MM-DD" type="date"
						placeholder="选择到期时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="单位地址" prop="ditusou">
					<ul class="mapList" v-show="mapInpoutOnFocus">
						<li v-for="(item,index) in mapList" @click="setCenter(item.location,item.name)">{{item.name}}
						</li>
					</ul>
					<el-input id="pickerInput" v-model="ssdwForm.ditusou" @focus="mapInpoutOnFocus = true"
						@blur="mapblur"></el-input>

				</el-form-item>
			</el-form>
			<div class="gd-ditu">
				<div class="ditu-center">
					<el-icon style="font-size: 52px;">
						<plus />
					</el-icon>
				</div>
				<div style="width: 100%;height: 100%;" id="container"></div>
			</div>
		</div>
		<div style="width: 100%;text-align: left;box-sizing: border-box;padding-left: 20px;">
			<el-button type="primary" @click="ssdwBtn('ssdwForm')">提交</el-button>
		</div>
	</el-drawer> -->
	<!-- 添加客户 -->
	<el-drawer :lock-scroll="false" v-model="drawerkh" size='84%' @close="resetFormkh" :title="drawerTitle"
		@opened="drawerOpen">
		<div class="drawer-div">
			<div class="drawer-left" style="margin-right: 15px;">
				<!-- 天地图 -->
				<div id="mapDiv"></div>
				<!-- 十字 -->
				<div class="mapSz" style="position: absolute !important;">
					<el-icon>
						<plus />
					</el-icon>
				</div>
			</div>
			<div class="drawer-right">
				<div style="width: 100%;height: 100%;overflow-y: auto;">
					<el-divider content-position="left">位置信息</el-divider>
					<el-form ref="khForm" :rules="rules" :model="khForm" label-width="80px">
						<el-form-item label="基础位置" prop="jcwz">
							<el-input v-model="khForm.jcwz" disabled placeholder="选择第一张图片后自动获取"></el-input>
						</el-form-item>

						<el-form-item label="坐标信息" prop="zbxx">
							<el-input v-model="khForm.zbxx" :disabled="isLook || userType == 2"></el-input>
						</el-form-item>

						<el-form-item label="是否确认" prop="sfqr">
							<el-radio-group v-model="khForm.sfqr" style="width: 100%;text-align: left;"
								:disabled="isLook || userType == 2">
								<el-radio :label="0" border>确认</el-radio>
								<el-radio :label="1" border>疑似</el-radio>
								<el-radio :label="2" border>未检出</el-radio>
							</el-radio-group>
						</el-form-item>

						<el-form-item label="数量等级" prop="sldj">
							<el-select v-model="khForm.sldj" style="width: 100%;" :disabled="isLook || userType == 2">
								<el-option label="1~50" value="1~50"></el-option>
								<el-option label="50~99" value="50~99"></el-option>
								<el-option label="100+" value="100+"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="关联任务" prop="glrw">
							<el-select :disabled="isLook || userType == 2" filterable v-model="khForm.glrw"
								style="width: 100%;" @change="selectGetRw">
								<el-option :label="item.taskName" :value="item.taskId" v-for="(item,index) in glrwList">
								</el-option>
							</el-select>
						</el-form-item>

						<!-- <el-form-item label="输入密码" prop="password">
						<el-input v-model="khForm.password"></el-input>
					</el-form-item> -->
					</el-form>
					<el-image-viewer v-if="showViewer" @close="closeViewer(0)" :initial-index="viewerIndex"
						:zoom-rate="0.5" :url-list="imgList">
					</el-image-viewer>
					<el-image-viewer v-if="showViewerOld" @close="closeViewerOld()" :initial-index="viewerIndex"
						:zoom-rate="0.5" :url-list="sourceUrlList">

						<div class="image-viewer-footer">
							<div class=""
								style="color: #fff;background: rgba(0, 0, 0, 0.6);padding:10px 20px;display: flex;align-items: center;">
								文件地址:{{sourceUrlList[viewerIndex]}}
								<p style="margin-left: 10px;cursor: pointer;" @click="copyImgUrl()">复制文件地址</p>
							</div>
						</div>
					</el-image-viewer>
					<el-divider content-position="left">影像信息<span style="padding-left: 10px;color:#F56C6C;"
							v-if="getImgData">共{{getImgData.length}}个</span></el-divider>
					<div style="width: 100%;display: flex;justify-content: space-between;height: 8vw;">
						<div class="yxxx-img"
							style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
							@click="lookImg(imageData.url)">
							<div class="imgUpEidt" v-if="imageData.url">
								<el-icon @click="showViewer = true;viewerIndex=0">
									<View />
								</el-icon>
								<el-icon style="color: #ff0000;" @click="showViewerOld = true;viewerIndex=0">
									<View />
								</el-icon>
								<el-icon @click="clickEdit(0)" v-if="!isLook && userType != 2">
									<edit />
								</el-icon>
							</div>
							<img v-if="imageData.thumbFileUrl" :src="imageData.thumbFileUrl"
								style="width: 100%;height: 100%;" />

							<el-icon class="avatar-uploader-icon" v-if="!imageData.thumbFileUrl && !isEdit && !isLook">
								<plus />
							</el-icon>
							<div class="avatar-uploader-icon" v-if="(isEdit && isGetImg) || (isLook && isGetImg)">
								<el-icon class="is-loading ">
									<loading />
								</el-icon>
							</div>
						</div>

						<div class="yxxx-img"
							style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
							@click="lookImg(imageData1.url)">
							<div class="imgUpEidt" v-if="imageData1.url">
								<el-icon @click="showViewer = true;viewerIndex=1">
									<View />
								</el-icon>
								<el-icon style="color: #ff0000;" @click="showViewerOld = true;viewerIndex=1">
									<View />
								</el-icon>
								<!-- <el-icon @click="dialogVisible = true;dialogUrl = isEdit?imageData1.thumbFileUrl:imageData1.url"><View /></el-icon> -->
								<el-icon @click="clickEdit(1)" v-if="!isLook && userType != 2">
									<edit />
								</el-icon>
							</div>
							<img v-if="imageData1.thumbFileUrl" :src="imageData1.thumbFileUrl"
								style="width: 100%;height: 100%;" />

							<el-icon class="avatar-uploader-icon" v-if="!imageData1.thumbFileUrl && !isEdit && !isLook">
								<plus />
							</el-icon>
							<div class="avatar-uploader-icon" v-if="(isEdit && isGetImg) || (isLook && isGetImg)">
								<el-icon class="is-loading ">
									<loading />
								</el-icon>
							</div>
						</div>

						<div class="yxxx-img"
							style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
							@click="lookImg(imageData2.url)">
							<div class="imgUpEidt" v-if="imageData2.url">
								<el-icon @click="showViewer = true;viewerIndex=2">
									<View />
								</el-icon>
								<el-icon style="color: #ff0000;" @click="showViewerOld = true;viewerIndex=2">
									<View />
								</el-icon>
								<!-- <el-icon @click="dialogVisible = true;dialogUrl = isEdit?imageData2.thumbFileUrl:imageData2.url"><View /></el-icon> -->
								<el-icon @click="clickEdit(2)" v-if="!isLook && userType != 2">
									<edit />
								</el-icon>
							</div>
							<img v-if="imageData2.thumbFileUrl" :src="imageData2.thumbFileUrl"
								style="width: 100%;height: 100%;" />

							<el-icon class="avatar-uploader-icon" v-if="!imageData2.thumbFileUrl && !isEdit && !isLook">
								<plus />
							</el-icon>
							<div class="avatar-uploader-icon" v-if="(isEdit && isGetImg) || (isLook && isGetImg)">
								<el-icon class="is-loading ">
									<loading />
								</el-icon>
							</div>
						</div>

						<!-- 查看图片 -->
						<el-dialog v-model="dialogVisible" width="60%" top="5vh">
							<img width="100%" :src="dialogUrl" alt="" style="width: 100%;" />
						</el-dialog>
						<!-- 编辑弹窗 -->
						<el-dialog custom-class="ysyx" v-model="dialogEdit" width="80%" top="4vh" title="罂粟影像"
							@opened="dialogOpen" @close="btcColse" :close-on-click-modal="false" :show-close="false">
							<div style="width: 100%;height: auto;display: flex;">
								<div style="height: 38vw;">
									<div class="eitd-item" :class="{'isEitd-item':eitdItemText == '开始识别'}"
										@click="startIdentification();eitdItemText = '开始识别'">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/01.svg" width="35">
											<p>开始识别</p>
										</div>
									</div>

									<div class="eitd-item" @click="reloadCanvas();eitdItemText = '图片复位'"
										:class="{'isEitd-item':eitdItemText == '图片复位'}">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/02.svg" width="35">
											<p>图片复位</p>
										</div>
									</div>

									<div class="eitd-item" @click="tzImg(0);eitdItemText = '拖拽图片'"
										:class="{'isEitd-item':eitdItemText == '拖拽图片'}">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/03.svg" width="35">
											<p>拖拽图片</p>
										</div>
									</div>

									<div class="eitd-item" @click="isMove = false;tzImg(1);eitdItemText = '选中标注'"
										:class="{'isEitd-item':eitdItemText == '选中标注'}">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/04.svg" width="35">
											<p>选中标注</p>
										</div>
									</div>

									<div class="eitd-item" @click="addRect();tzImg(1);eitdItemText = '添加标注'"
										:class="{'isEitd-item':eitdItemText == '添加标注'}">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/05.svg" width="35">
											<p>添加标注</p>
										</div>
									</div>

									<div class="eitd-item" @click="addText();tzImg(1);eitdItemText = '添加文字'"
										:class="{'isEitd-item':eitdItemText == '添加文字'}">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/06.svg" width="35">
											<p>添加文字</p>
										</div>
									</div>

									<div class="eitd-item" @click="delSecect();eitdItemText = '删除选中'"
										:class="{'isEitd-item':eitdItemText == '删除选中'}">
										<div style="width: 100%;">
											<img src="../../public/img/eitd/07.svg" width="35">
											<p>删除选中</p>
										</div>
									</div>

									<!-- <div class="eitd-item" @click= "getCanvasData">
									<img src="../../public/img/eitd/08.svg" width="35">
									<p >获取数据</p>
								</div> -->

								</div>
								<div class="vanvas-d" ref="vanvas-d">
									<!-- 扫描层 -->
									<div class="shb-smc" v-show="shbSmc"></div>
									<div class="onloadImg">
										<img src="../../public/img/loading.gif" style="width: 100%;height: 100%;">
									</div>
									<canvas id="mycanvas" style="width: 100%;height: 100%;z-index: 9;"
										ref="mycanvas"></canvas>
									<!-- 右侧弹出 -->
									<div class="BasicTextResult" :class="{'close':rightIsOpen,'open':!rightIsOpen}">
										<!-- <div class="BasicTextResult-leftIcon" :class="{'close':rightIsOpen,'open':!rightIsOpen}"> -->
										<div class="BasicTextResult-leftIcon">
											<div class="leftIconDiv" @click="collectRight">
												<!-- <i class="layui-icon leftIcon" :class="{'layui-icon-right':rightIsOpen,'layui-icon-left':!rightIsOpen}"></i> -->
												<div
													style="width: 100%;height: 100%;position: absolute;line-height: 100px;color: #FFFFFF;">
													<el-icon v-show="!rightIsOpen">
														<arrow-left-bold />
													</el-icon>
													<el-icon v-show="rightIsOpen">
														<arrow-right-bold />
													</el-icon>
												</div>
											</div>
										</div>
										<!-- <div class="BasicTextResult-textResult" :class="{'close':rightIsOpen,'open':!rightIsOpen}" v-cloak> -->
										<div class="BasicTextResult-textResult" v-cloak>
											<div class="basic-text">
												识别结果
											</div>
											<!-- <div class="basic-text" v-if="sfwk" style="width: 100%;">
											该图片中未检测出罂粟目标
										</div> -->
											<!-- <div class="basic-text" v-if="errorMessage.length>0" style="width: 100%;">
											{{errorMessage}}
										</div> -->
											<!-- <div class="basic-text" v-if="ysData.length > 0" style="width: 100%;">
											经纬度:   <span v-if="ysGPS.length > 0">{{ysGPS[0]}}  ,  {{ysGPS[1]}}</span> 
											<span v-else>没有经纬度信息</span>
										</div> -->

											<div v-for="(item,i) in ysData" :key="i">
												<div>
													<div class="CentreResult-title">
														{{i>=9?i+1:'0'+(i+1)}}
													</div>
													<div class="CentreResult-Div-titileSpan">
														类目名称
													</div>
													<span class="CentreResult-Div-resultSpan">{{tsType(item[6])}}</span>
												</div>

												<div>
													<div class="CentreResult-Div-titileSpan">
														置信度
													</div>
													<span class="CentreResult-Div-resultSpan">{{zxdNum(item)}}%</span>
												</div>
											</div>


										</div>
									</div>

								</div>
								<div style="width: 20%;margin-left: 20px;position: relative;">

									<div class="yxxx-img" style="width: 100%;height: 8vw;position: relative;">
										<div v-if="imageData.url" class="imgUpEidt"
											@click="switchCanvasImg(0,imageData.url)">
											<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
												icon-color="red" placement="right-start" title="你确定删除吗?"
												@confirm="delImg(0)">
												<template #reference>
													<el-icon>
														<delete />
													</el-icon>
												</template>
											</el-popconfirm>
										</div>
										<!-- <el-dialog v-model="dialogVisible" width="60%" top="5vh">
									    <img width="100%" :src="dialogUrl" alt=""  style="width: 100%;"/>
									</el-dialog> -->
										<img v-if="imageData.thumbFileUrl" :src="imageData.thumbFileUrl"
											style="width: 100%;height: 100%;" />

										<el-upload v-if="!imageData.url" class="avatar-uploader" :action="fileUrl"
											:data="uploadFistData" :show-file-list="false" :on-success="successW"
											:limit="1" :before-upload="befpreImg" :on-progress="uploadProgress">
											<div v-if="percentage0 > 0 && percentage0 < 100"
												style="position: absolute;width: 100%;height: 100%;top: 0;left: 0;">
												<el-progress type="circle" :percentage="percentage0" color="#67c23a"
													style="width: 100% !important;height: 100% !important;" />
											</div>
											<el-icon v-if="!imageData.url && percentage0 == 0"
												class="avatar-uploader-icon">
												<plus />
											</el-icon>
										</el-upload>
									</div>

									<div class="yxxx-img"
										style="width: 100%;height: 8vw;position: relative;margin-top: 15px;">

										<div v-if="imageData1.url" class="imgUpEidt"
											@click="switchCanvasImg(1,imageData1.url)">
											<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
												icon-color="red" placement="right-start" title="你确定删除吗?"
												@confirm="delImg(1)">
												<template #reference>
													<el-icon>
														<delete />
													</el-icon>
												</template>
											</el-popconfirm>
										</div>
										<img v-if="imageData1.thumbFileUrl" :src="imageData1.thumbFileUrl"
											style="width: 100%;height: 100%;" />

										<el-upload v-if="!imageData1.url" class="avatar-uploader" :action="fileUrl"
											:data="uploadsecData" :show-file-list="false" :limit="1"
											:on-success="successW1" :before-upload="befpreImg1"
											:on-progress="uploadProgress1">
											<div v-if="percentage1 > 0 && percentage1 < 100"
												style="position: absolute;width: 100%;height: 100%;top: 0;left: 0;">
												<el-progress type="circle" :percentage="percentage1" color="#67c23a"
													style="width: 100% !important;height: 100% !important;" />
											</div>
											<el-icon v-if="!imageData1.url && percentage1 == 0"
												class="avatar-uploader-icon">
												<plus />
											</el-icon>
										</el-upload>
									</div>

									<div class="yxxx-img"
										style="width: 100%;height: 8vw;position: relative;margin-top: 15px;">

										<div v-if="imageData2.url" class="imgUpEidt"
											@click="switchCanvasImg(2,imageData2.url)">
											<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
												icon-color="red" placement="right-start" title="你确定删除吗?"
												@confirm="delImg(2)">
												<template #reference>
													<el-icon>
														<delete />
													</el-icon>
												</template>
											</el-popconfirm>
										</div>
										<img v-if="imageData2.thumbFileUrl" :src="imageData2.thumbFileUrl"
											style="width: 100%;height: 100%;" />

										<el-upload v-if="!imageData2.url" class="avatar-uploader" :action="fileUrl"
											:data="uploadsecData" :show-file-list="false" :limit="1"
											:on-progress="uploadProgress2" :on-success="successW2"
											:before-upload="befpreImg2">
											<div v-if="percentage2 > 0 && percentage2 < 100"
												style="position: absolute;width: 100%;height: 100%;top: 0;left: 0;">
												<el-progress type="circle" :percentage="percentage2" color="#67c23a"
													style="width: 100% !important;height: 100% !important;" />
											</div>
											<el-icon v-if="!imageData2.url && percentage2 == 0"
												class="avatar-uploader-icon">
												<plus />
											</el-icon>
										</el-upload>
									</div>
									<div style="position: absolute;width: 100%;left: 0;bottom: 0;">
										<el-button type="primary" style="width: 100%;" :loading="isSaveYs"
											@click="saveCanvas">
											保存
										</el-button>
									</div>

								</div>
							</div>
							<!-- <div style="margin-top: 5px;">
						<el-button @click= "startIdentification(abcdata.data[0],abcdata.height,abcdata.width)" type="primary" :loading="shbSmc">开始识别</el-button>
							<el-button @click= "reloadCanvas">图片复位</el-button>
							<el-button @click="tzImg(0)">拖拽图片</el-button>
							<el-button @click="isMove = false;tzImg(1)">选中标注</el-button>
							<el-button @click= "addRect();tzImg(1)">添加标注</el-button>
							<el-button @click= "addText();tzImg(1)">添加文字</el-button>
							<el-button @click= "delSecect">删除选中</el-button>
							<el-button>获取数据</el-button>
						</div> -->
						</el-dialog>



					</div>
					<el-button v-if="!isLook && userType !=2" type="danger" style="width: 100%;margin-top: 15px"
						@click="setCenter">初始位置</el-button>
					<el-button v-if="!isLook && !isEdit && userType !=2" type="primary"
						style="width: 100%;margin-top: 15px;margin-left: 0;" @click="khBtn('khForm')">
						上报
					</el-button>
					<el-button v-if="isEdit && !isLook && userType !=2" type="primary"
						style="width: 100%;margin-top: 15px;margin-left: 0;" @click="khBtn('khForm')">
						修改
					</el-button>

					<el-tag v-if="!isLook && userType !=2" type="warning"
						style="margin-top: 15px;width: 100%;text-align: left;">第一张图片请上传原图，以便获取经纬度
					</el-tag>
					<el-image-viewer v-if="showViewerJc" @close="closeViewer(1)" :initial-index="viewerIndexJc"
						:zoom-rate="0.5" :url-list="imgListJc">
					</el-image-viewer>
					<el-divider content-position="left" v-if="isLook || userType ==2">稽查信息</el-divider>
					<el-form :model="jcForm" label-width="80px" v-if="isLook || userType ==2">
						<el-form-item label="稽查状态">
							<el-radio-group v-model="jcForm.checkState" :disabled="isLook">
								<el-radio :label="0" border>未检查</el-radio>
								<el-radio :label="1" border>已确认</el-radio>
								<el-radio :label="2" border>未确认</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="稽查数量">
							<el-select v-model="jcForm.checkLevel" style="width: 100%;" :disabled="isLook">
								<el-option label="1~50" value="1~50"></el-option>
								<el-option label="50~99" value="50~99"></el-option>
								<el-option label="100+" value="100+"></el-option>
							</el-select>
						</el-form-item>
						<el-divider content-position="left">稽查影像<span style="padding-left: 10px;color:#F56C6C;"
								v-if="jcxxImg">共{{jcxxImg.length}}个</span></el-divider>
						<div
							style="width: 100%;display: flex;justify-content: space-between;height: 8vw;margin-bottom: 15px;">
							<div class="yxxx-img"
								style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;">
								<div class="imgUpEidt" v-show="jcxxImgnUM > 0">
									<el-icon @click="jcxxLook(0)">
										<View />
									</el-icon>
								</div>
								<img v-show="jcxxImg[0]" :src="jcxxImg[0]" style="width: 100%;height: 100%;" />
								<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
									<el-icon class="is-loading ">
										<loading />
									</el-icon>
								</div>
							</div>

							<div class="yxxx-img"
								style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;">
								<div class="imgUpEidt" v-show="jcxxImgnUM >= 2">
									<el-icon @click="jcxxLook(1)">
										<View />
									</el-icon>
								</div>
								<img v-show="jcxxImg[1]" :src="jcxxImg[1]" style="width: 100%;height: 100%;" />
								<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
									<el-icon class="is-loading ">
										<loading />
									</el-icon>
								</div>
							</div>

							<div class="yxxx-img"
								style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;">
								<div class="imgUpEidt" v-show="jcxxImgnUM >= 3">
									<el-icon @click="jcxxLook(2)">
										<View />
									</el-icon>
								</div>
								<img v-show="jcxxImg[2]" :src="jcxxImg[2]" style="width: 100%;height: 100%;" />
								<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
									<el-icon class="is-loading ">
										<loading />
									</el-icon>
								</div>
							</div>

						</div>
						<el-form-item label="稽查人员">
							<el-input v-model="jcForm.checkUserName" :disabled="isLook"></el-input>
						</el-form-item>
						<el-form-item label="备注">
							<el-input v-model="jcForm.checkNode" :disabled="isLook"></el-input>
						</el-form-item>
					</el-form>

				</div>
			</div>
			<!-- <label class="el-form-item__label lebel-w">单位地址</label> -->
		</div>
	</el-drawer>
</template>

<script>
	var canvas, startX, startY, endX, endY, movementX = 0,
		movementY = 0;
	var zoom = 1;
	import md5 from 'js-md5'
	import {
		fabric
	} from 'fabric';
	import {
		io
	} from 'socket.io-client'
	import {
		ElMessage,
		ElLoading
	} from 'element-plus'
	export default {
		data() {
			const validatePass1 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('账号不能为空'))
				} else {
					this.$axios.post('/flight/check', {
							userId: sessionStorage.getItem('userId'),
							type: sessionStorage.getItem('type'),
							account: value
						})
						.then(res => {

							if (res.data.code != 666) {
								callback(new Error(res.data.msg))
								callback()
							}
							callback()
						})
						.catch(error => {
							callback()
							console.log('error:', error)
						})

				}
			}
			const validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入新密码'))
				} else {
					callback()
				}
			}
			// const validatePass3 = (rule, value, callback) => {
			// 	if (value === '') {
			// 		callback(new Error('请再次输入新密码'))
			// 	} else if (value !== this.PasswordForm.old) {
			// 		callback(new Error("两次输入的密码不一致"))
			// 	} else {
			// 		callback()
			// 	}
			// }
			return {
				plUpTypeOptions: [{
						value: '0',
						label: '确认',
					},
					{
						value: '1',
						label: '疑似毒源',
					},
					{
						value: '2',
						label: '未检出',
					}
				],
				plUpType: '2',
				isTest: true,
				plCheckBtn: false,
				onloadCanvaImg: true,
				plCheckBtnRB: false,
				listCheckOptions: [{
					label: '操作',
					options: [{
						value: 'del',
						label: '批量删除',
					}, ]
				}],
				listCheckOptionsRB: [{
					label: '操作',
					options: [{
							value: 'delRB',
							label: '批量删除',
						},
						{
							value: 'reductionRB',
							label: '批量还原',
						},
					]
				}],
				listCheckSelect: '请选择',
				listCheckSelectRB: '请选择',
				tableListCheckRB: [],
				tableListCheck: [],
				jcxxImg: [],
				jcForm: {
					checkLevel: "",
					checkNode: "",
					checkState: 0,
					checkTime: "",
					checkUserId: "",
					checkUserName: ""
				},
				plscwz: '准备上传',
				plscwzShow: false,
				tableLoading: false,
				PlprogressNum: 0,
				PlprogressNumBf: 0,
				kssbProgress: false,
				dwjfileList: [],
				uploadTypeTaext: '选择文件',
				dialogPlsc: false,
				BintableTotal: 0,
				BintableNowPage: 1,
				drecycleBinData: [],
				drecycleBinVisible: false,
				isGetImg: false,
				eitdItemText: '',
				scale_h_percent: 1,
				scale_w_percent: 1,
				getImgData: [],
				tableTaskId: '',
				listState: 0,
				dataType: '1',
				flieidList: {
					one: '',
					two: '',
					three: ''
				},
				flieRouteList: {
					one: '',
					two: '',
					three: ''
				},
				nowPage: 0,
				canvasData: {
					one: '',
					two: '',
					three: ''
				},
				canvasBackImg: '',
				onloadImg: false,
				showViewer: false,
				showViewerOld: false,
				imgList: [],
				sourceUrlList: [],
				viewerIndex: 0,
				showViewerJc: false,
				imgListJc: [],
				viewerIndexJc: 0,
				ysData: [],
				ysGPS: [],
				sfwk: false,
				shbSmc: false,
				panning: false,
				isMove: false,
				isAdd: false,
				socket: null,
				dialogVisible: false,
				dialogEdit: false,
				uploadBeforeLength: 1,
				pcUploadList: [],
				uploadBeforeList: [],
				nowZoom: 1,
				dialogUrl: '',
				percentage0: 0,
				percentage1: 0,
				percentage2: 0,
				uploadFistPlData: {
					userId: sessionStorage.getItem('userId'),
					type: sessionStorage.getItem('type'),
					userName: sessionStorage.getItem('username'),
					taskId: '',
					taskName: ''
				},
				uploadFistData: {
					userId: sessionStorage.getItem('userId'),
					type: sessionStorage.getItem('type'),
					isCheckFile: 1,
					fileId: ''
				},
				uploadsecData: {
					userId: sessionStorage.getItem('userId'),
					type: sessionStorage.getItem('type'),
					isCheckFile: 0,
					fileId: ''
				},
				fileUrl: process.env.VUE_APP_PUBLICURL + '/file/single',
				fileUrlPl: process.env.VUE_APP_PUBLICURL + '/file/batch',
				drawerTitle: '',
				imageData: {
					url: ''
				},
				imageData1: {
					url: ''
				},
				imageData2: {
					url: ''
				},
				imageUrl1: '',
				imageUrl2: '',
				tableNowPage: 1,
				filterText: '',
				dataSource: [],
				tablekwd: '',
				drawer: false,
				drawerkh: false,
				jcxxImgnUM: 0,
				dialogFormuser: false,
				clickType: 'add',
				// ssdwForm: {
				// 	dwmc: '',
				// 	ditusou: ''
				// },
				khForm: {
					jcwz: '',
					zbxx: '',
					sfqr: 0,
					sldj: '1~50',
					glrw: '',
					glrwName: ''
				},
				tjdwsj: '',
				userType: sessionStorage.getItem('type'),
				mapList: [],
				map: '',
				mapInpoutOnFocus: false,
				isSaveYs: false,
				centerAdderss: '',
				treenode: '',
				editGps: [],
				limit: 11,
				tableData: [],
				tableTotal: 0,
				userCheack: '',
				isEdit: false,
				isLook: false,
				nowIndex: 0,
				nowCenter: '',
				ckGlrwList: [],
				ckSelectTaskId: '',
				glrwList: [],
				plscNowNum: 0,
				rightIsOpen: false,
				rowData: '',
				wuuid: '',
				plNumPercentage: 0,
				plNumProgress: 0,
				imgFile: {
					one: '',
					two: '',
					three: ''
				},
				dhcState: 1,
				abcdata: {
					data: [
						[
							2474.9546813964844,
							646.0206928253174,
							2541.5662994384766,
							711.6706707000733,
							0.9117589592933655,
							0.8276796936988831,
							0.0
						]
					],
					height: 3648,
					width: 5472,
				},
				canvasSVG: {
					one: '',
					two: '',
					three: ''
				},
				// 表单验证
				// rules: {
				//  dwmc: [{
				// 		required: true,
				// 		message: '请填写单位名称',
				// 		trigger: 'blur',
				// 	}, ],
				// 	ditusou: [{
				// 		required: true,
				// 		message: '请选择单位地址',
				// 		trigger: 'blur',
				// 	}, ]
				// },
				// 表单验证
				rules: {
					jcwz: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
					zbxx: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
					sfqr: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
					sldj: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
					glrw: [{
						required: true,
						message: '必填项不能为空',
						trigger: 'blur',
					}, ],
				},
			}
		},
		methods: {
			switchCanvasImg(num, thUrl) {
				if (this.onloadCanvaImg) {
					ElMessage.error('请等待图片加载');
					return false
				}
				this.getCanvasData();
				this.nowPage = num;
				this.initDraw(thUrl);
			},
			// 批量1删除2还原
			batchDelList(state) {
				var arrData = [];
				if (state == 2) {
					arrData = this.tableListCheck;
				} else {
					arrData = this.tableListCheckRB
				}
				this.$axios.post('/papaver/batch', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						state: state,
						arr: arrData
					})
					.then(res => {

						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.plCheckBtn = false;
						this.plCheckBtnRB = false;
						ElMessage.success('操作成功');
						if (state == 2) {
							this.getKhList();
						} else {
							this.getHszList();
						}
						// this.$refs.multipleTableRef.clearSelection();
					})
					.catch(error => {
						ElMessage.error('操作失败');
						this.plCheckBtn = false;
						this.plCheckBtnRB = false;
						console.log('error:', error)
					})

			},
			// 批量操作
			confirmBatch(type) {
				// 列表
				if (type == 0) {
					if (this.listCheckSelect == 'del') {
						this.plCheckBtn = true;
						this.batchDelList(2);
					}
				} else {
					// 回收站
					if (this.listCheckSelectRB == 'delRB') {
						this.plCheckBtnRB = true;
						this.batchDelList(3);
					} else if (this.listCheckSelectRB == 'reductionRB') {
						this.plCheckBtnRB = true;
						this.batchDelList(1);
					}
				}

			},
			// 多选
			selectList(selection) {
				this.tableListCheck = [];
				for (var i = 0; i < selection.length; i++) {
					this.tableListCheck.push(selection[i].dybh)
				}
			},
			// 回收站多选
			selectListRB(selection) {
				this.tableListCheckRB = [];
				for (var i = 0; i < selection.length; i++) {
					this.tableListCheckRB.push(selection[i].dybh)
				}
			},
			copyImgUrl() {
				console.log(this.sourceUrlList[this.viewerIndex])
				const imgUrl = this.sourceUrlList[this.viewerIndex];
				navigator.clipboard.writeText(imgUrl).then(() => {
					this.$message({
						message: '已复制到剪切板',
						type: 'success',
						offset: 200
					});
				})
			},
			copyDydz(row, column, cell, event) {
				var nowCopy = row[column.property];
				ElMessage({
					message: '已复制到剪切板',
					type: 'success',
					offset: 200
				})
				this.copyText(nowCopy);
			},
			copyText(text) {
				var input = document.createElement('input');
				input.setAttribute('id', 'input_for_copyText');
				input.value = text;
				document.getElementsByTagName('body')[0].appendChild(input);
				document.getElementById('input_for_copyText').select();
				document.execCommand('copy');
				document.getElementById('input_for_copyText').remove();
			},
			closeViewer(num) {
				if (num == 0) {
					this.showViewer = false;
				} else {
					this.showViewerJc = false;
				}

			},

			closeViewerOld() {
				this.showViewerOld = false;
			},
			jcxxLook(url) {
				this.showViewerJc = true;
				if (this.jcxxImg[url]) {
					this.viewerIndexJc = url;
					// this.dialogUrl = this.jcxxImg[url];
				}
			},
			Plclose() {
				this.$refs.Plupload.clearFiles();
				this.uploadBeforeList = [];
				this.dwjfileList = [];
				this.pcUploadList = [];
				this.plscwzShow = false;
				this.getKhList();
				this.plscwz = '准备上传';
				this.plscNowNum = 0;
			},
			PlRemove(file, fileList) {
				this.dwjfileList = fileList;
			},
			submitUpload() {
				this.$refs.Plupload.submit();
			},
			// 批量识别$axios
			PlAxios(data) {
				const formData = new FormData();
				var infoData = [{
					"fileId": data.fileId,
					"dybh": data.dybh,
					"filePath": data.filePath,
					"localFilePath": data.localFilePath,
				}]
				formData.append('info', JSON.stringify(infoData));
				this.plscwz = '识别中 ' + this.PlprogressNum + "%";
				this.$axios.post('https://ai.zcjzcd.com:3334/detect',
						formData, {
							onUploadProgress: (progressEvent) => {
								// var progressNum = (progressEvent.loaded / progressEvent.total) * 100;
								// if (progressNum > 99) {
								// 	progressNum = 99.5
								// }
								// this.PlprogressNum = parseFloat(progressNum.toFixed(2));
								// this.plscwz = '识别中 '+this.PlprogressNum+"%"
							}
						}
					)
					.then(res => {
						if (res.status != 200) {
							ElMessage.error('服务器繁忙');
							return
						}
						this.plNumProgress = this.plNumProgress + this.plNumPercentage;
						this.PlprogressNum = parseFloat(this.plNumProgress.toFixed(2));
						this.plscwz = '识别中 ' + this.PlprogressNum + "%";
						if (this.pcUploadList.length == 0) {
							// 识别进度条清空
							this.plNumProgress = 0;
							this.plNumPercentage = 0;
							this.kssbProgress = false;
							this.PlprogressNum = 0;
							ElMessage.success('识别完成');
							this.dialogPlsc = false;
						} else {
							this.PlAxios(this.pcUploadList.pop())
						}
					})
					.catch(error => {
						console.log('error:', error)
					})

			},
			// 上传完成后直接识别
			PlAxiosOne(data) {
				const formData = new FormData();
				var infoData = [{
					"fileId": data.fileId,
					"dybh": data.dybh,
					"filePath": data.filePath,
					"localFilePath": data.localFilePath,
				}]
				formData.append('info', JSON.stringify(infoData));
				this.plscwz = '识别中 ' + this.PlprogressNum + "%";
				this.$axios.post('https://ai.zcjzcd.com:3334/detect',
						formData
					)
					.then(res => {
						if (res.status != 200) {
							ElMessage.error('服务器繁忙');
							return
						};
						var isAllComplete = 0;
						for (var i = 0; i < this.pcUploadList.length; i++) {
							if (this.pcUploadList[i].fileId == res.data.result[0].fileid) {
								this.pcUploadList[i].type = 2;
							}
							if (this.pcUploadList[i].fileId == 0 || this.pcUploadList[i].fileId == 1) {
								isAllComplete++
							}
						}
						if (isAllComplete == 0) {
							this.dialogPlsc = false;
							ElMessage.success('识别完成');
						}
					})
					.catch(error => {
						ElMessage.error('当前图片识别失败');
						console.log('error:', error)
					})

			},
			Plshibie() {
				this.kssbProgress = true;
				var shibData = this.dwjfileList;
				this.plNumPercentage = 100 / this.pcUploadList.length;
				this.PlAxios(this.pcUploadList.pop());
				return
				var shibList = [];
				const formData1 = new FormData();
				var formData = [];
				var keyData;
				for (var i = 0; i < shibData.length; i++) {
					// keyData = shibData[i].response.fileId+","+shibData[i].response.dybh
					// formData.append(keyData,shibData[i].raw);
					formData.push({
						"fileId": shibData[i].response.fileId,
						"dybh": shibData[i].response.dybh,
						"filePath": shibData[i].response.filePath,
						"localFilePath": shibData[i].response.localFilePath,
					});
				};
				var progressNum = 0;
				var sbSetTime = setInterval(() => {
					progressNum = progressNum + (20 / shibData.length);
					if (progressNum > 99) {
						progressNum = 99.5;
						clearInterval(sbSetTime);
					}
					this.PlprogressNum = parseFloat(progressNum.toFixed(2));
					this.plscwz = '识别中 ' + this.PlprogressNum + "%";
				}, 1000)
				formData1.append('info', JSON.stringify(formData));
				this.$axios.post('https://ai.zcjzcd.com:3334/detect',
						formData1, {
							onUploadProgress: (progressEvent) => {
								// var progressNum = (progressEvent.loaded / progressEvent.total) * 100;
								// if (progressNum > 99) {
								// 	progressNum = 99.5
								// }
								// this.PlprogressNum = parseFloat(progressNum.toFixed(2));
								// this.plscwz = '识别中 '+this.PlprogressNum+"%"
							}
						}
					)
					.then(res => {
						this.kssbProgress = false;
						this.PlprogressNum = 0;

						if (res.status != 200) {
							ElMessage.error('服务器繁忙');
							return
						}
						ElMessage.success('识别完成');
						this.dialogPlsc = false;
					})
					.catch(error => {
						this.kssbProgress = false;
						this.PlprogressNum = 0;
						console.log('error:', error)
					})

			},
			plscBtn(type) {
				this.dialogPlsc = true;
				this.pcUploadList = [];
				this.isTest = type;
			},
			// 文件上传错误后 重新发起上传
			renovateUpload(uploadData) {
				this.pcUploadList[this.plscNowNum - 1].scNum = '等待重新上传';
				this.plscNowNum--;
				let renovateData = uploadData;
				renovateData.delete('state');
				// 从头部添加 上传失败的文件
				this.uploadBeforeList.unshift(renovateData);
				// 10s 后调用递归上传
				setTimeout(() => {
					this.splitUpload();
				}, 1000 * 10)
			},
			// 文件递归上传
			splitUpload() {
				this.plscNowNum++;
				var stateUpload = '';
				if (!this.isTest) {
					stateUpload = this.plUpType;
				}
				var uploadData = this.uploadBeforeList.shift();
				uploadData.set('state', stateUpload);
				this.plscwz = '正在上传' + (this.uploadBeforeLength - this.uploadBeforeList.length) + '/' + this
					.uploadBeforeLength;
				this.$axios.post('https://zcjzcd.com/api/public/file/batch',
						uploadData, {
							onUploadProgress: (progressEvent) => {
								var progressNum = (progressEvent.loaded / progressEvent.total) * 100;
								this.PlprogressNumBf = parseFloat(progressNum.toFixed(2));
								this.pcUploadList[this.plscNowNum - 1].scNum = this.PlprogressNumBf + " %"
							}
						}
					)
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error('上传失败,正在重新上传');
							this.renovateUpload(uploadData);
							return
						}
						// this.pcUploadList.shift();
						this.pcUploadList[this.plscNowNum - 1] = {
							name: uploadData.get('name'),
							dybh: res.data.dybh,
							fileId: res.data.fileId,
							filePath: res.data.filePath,
							type: 1,
							scNum: ''
						}
						// this.pcUploadList.push(
						// {
						// 	name:uploadData.get('name'),
						// 	dybh:res.data.dybh,
						// 	fileId:res.data.fileId,
						// 	filePath:res.data.filePath,
						// 	type:1
						// }
						// );
						// 识别文件
						if (this.isTest) {
							this.PlAxiosOne(res.data);
						}
						// this.dwjfileList.push(
						// 	{
						// 		name:uploadData.get('name'),
						// 		dybh:res.data.dybh,
						// 		fileId:res.data.fileId,
						// 		filePath:res.data.filePath
						// 	}
						// );
						if (this.uploadBeforeList.length > 0) {
							this.splitUpload();
						} else {
							// this.plscwzShow = true;
							this.plscwz = '上传成功';
							ElMessage.success('上传成功');
							if (!this.isTest) {
								this.dialogPlsc = false;
							}
						}
					})
					.catch(error => {
						ElMessage.error('文件上传失败,正在重新上传');
						this.renovateUpload(uploadData);
						console.log('error:', error)
					})

			},
			uploadSuccess(val) {
				// console.log($('.plsc-card .el-upload-list li').length);
				let formDataV = new FormData();
				formDataV.append('file', val.file);
				formDataV.append('userId', val.data.userId);
				formDataV.append('name', val.file.name);
				formDataV.append('type', val.data.type);
				formDataV.append('userName', val.data.userName);
				formDataV.append('taskId', val.data.taskId);
				formDataV.append('taskName', val.data.taskName);
				this.uploadBeforeList.push(formDataV);
				this.pcUploadList.push({
					name: val.file.name,
					type: 0,
					id: 0,
					dybh: '',
					fileId: '',
					filePath: '',
					scNum: ''
				})
				//接收完列表的文件后开始分批上传
				this.splitUpload();
			},
			PlscUpload(response, file, fileList) {
				this.dwjfileList = fileList;
			},
			handleExceed(file, uploadFiles) {
				var val = this.uploadFistPlData;
				for (var i = 0; i < file.length; i++) {
					var fileName = file[i].type.substr(0, 5);
					if (fileName == 'image') {
						let formDataV = new FormData();
						formDataV.append('file', file[i]);
						formDataV.append('name', file[i].name);
						this.pcUploadList.push({
							name: file[i].name,
							type: 0,
							id: i,
							dybh: '',
							fileId: '',
							filePath: ''
						})
						formDataV.append('userId', val.userId);
						formDataV.append('type', val.type);
						formDataV.append('userName', val.userName);
						formDataV.append('taskId', val.taskId);
						formDataV.append('taskName', val.taskName);
						this.uploadBeforeList.push(formDataV);
					}
				}
				this.uploadBeforeLength = this.uploadBeforeList.length;
				this.splitUpload();
				// this.dwjfileList = fileList;
			},
			dwjscBefore(file) {
				var fileName = file.type.substr(0, 5);
				if (fileName != 'image') {
					// ElMessage.error('暂不支持当前文件格式！');
					return false
				} else {
					return true
				}
			},
			uploadDwjsc() {
				if (!this.uploadFistPlData.taskId) {
					ElMessage.warning('请选择关联任务!')
					return
				}
				$('.uoloadParetn .el-upload__input').click();
			},
			uploadXzml() {
				this.uploadTypeTaext = '选择目录';
				$('.uoloadParetn .el-upload__input').attr('webkitdirectory', true);
				this.uploadDwjsc();
			},
			uploadXzwj() {
				this.uploadTypeTaext = '选择文件';
				$('.uoloadParetn .el-upload__input').removeAttr('webkitdirectory');
				this.uploadDwjsc();
			},
			hszClick() {
				this.getHszList();
				this.drecycleBinVisible = true;
			},
			clickEdit(num) {
				this.dialogEdit = true;
				// 保存绘制信息
				// this.getCanvasData();
				this.nowPage = num;
			},
			changeType(val) {
				this.tableNowPage = 1;
				this.dhcState = val;
				this.getKhList();
			},
			selectGetRw(vId) {
				let obj = {};
				obj = this.glrwList.find((item) => { //这里的userList就是上面遍历的数据源
					return item.taskId === vId; //筛选出匹配数据
				});
				this.khForm.glrwName = obj.taskName;
			},
			selectGetRwPl(vId) {
				let obj = {};
				obj = this.glrwList.find((item) => { //这里的userList就是上面遍历的数据源
					return item.taskId === vId; //筛选出匹配数据
				});
				this.uploadFistPlData.taskName = obj.taskName;
				this.uploadFistPlData.taskId = obj.taskId;
			},
			selectCkRwPl(val) {
				this.ckSelectTaskId = val;
				this.tableTaskId = val;
				this.getKhList();
			},
			saveCanvas() {
				// console.log(this.imageData2);
				// console.log(this.percentage2);
				this.isSaveYs = true;
				this.getCanvasData();
				var fileContent = [{
						canvasData: this.canvasData.one,
						fileid: this.flieidList.one,
						saveImg: this.canvasSVG.one
					},
					{
						canvasData: this.canvasData.two,
						fileid: this.flieidList.two,
						saveImg: this.canvasSVG.two
					},
					{
						canvasData: this.canvasData.three,
						fileid: this.flieidList.three,
						saveImg: this.canvasSVG.three
					}
				];
				this.$axios.post('/file/update', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						fileContent: fileContent
					})
					.then(res => {

						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.dialogEdit = false;
						this.isSaveYs = false;
						ElMessage.success('保存成功');
					})
					.catch(error => {
						this.isSaveYs = false;
						ElMessage.error('保存失败，请重新上传');
						console.log('error:', error)
					})
			},
			getCanvasData() {
				// 图片复位
				this.reloadCanvas();
				// console.log(canvas.toJSON());
				if (!canvas) {
					return
				}
				if (this.nowPage == 0) {
					this.canvasData.one = JSON.stringify(this.canvasToImg(canvas.toJSON()));
					this.canvasSVG.one = canvas.toDataURL({
						format: 'image/png'
					});
				} else if (this.nowPage == 1) {
					this.canvasData.two = JSON.stringify(this.canvasToImg(canvas.toJSON()));
					this.canvasSVG.two = canvas.toDataURL({
						format: 'image/png'
					});
				} else if (this.nowPage == 2) {
					this.canvasData.three = JSON.stringify(this.canvasToImg(canvas.toJSON()));
					this.canvasSVG.three = canvas.toDataURL({
						format: 'image/png'
					});
				}
			},

			tzImg(num) {
				if (num == 0) {
					this.isMove = true;
					this.isAdd = false;
					var objects = canvas.getObjects();
					for (var i = 0; i < objects.length; i++) {
						objects[i].selectable = false;
					}
				} else {
					var objects = canvas.getObjects();
					for (var i = 0; i < objects.length; i++) {
						objects[i].selectable = true;
					}
				}
			},
			zxdNum(item) {
				var zxdNum = (item[4] * item[5]) * 100;
				zxdNum = Math.round(zxdNum)
				return zxdNum
			},
			tsType(type) {
				if (type == 0) {
					return '罂粟'
				} else if (type == 1) {
					return '有花'
				} else if (type == 2) {
					return '有果'
				} else if (type == 3) {
					return '罂粟苗'
				}
			},
			// url转file对象
			urlToFile(url) {
				this.startIdentification1();
				// var image = new Image();
				// image.src = url;
				// var dataFile;
				// image.onload = ()=>{
				// 	var base64 = this.getBase64Image(image);
				// 	dataFile = this.dataURLtoFile(base64,'imgfile.jpg');
				// 	this.startIdentification1(dataFile);
				//   }
			},
			getBase64Image(img) {
				var canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;
				var ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0, img.width, img.height);
				var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
				var dataURL = canvas.toDataURL("image/" + ext);
				return dataURL;
			},
			dataURLtoFile(dataurl, filename) { //将base64转换为文件
				var arr = dataurl.split(','),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], filename, {
					type: mime
				});
			},
			// 开始识别
			startIdentification() {
				if (this.isLook) {
					return
				}
				// 清空识别结果
				this.ysData = [];
				this.shbSmc = true;
				// this.urlToFile('https://zcjzcd.com/api/public/storage/2022/01/17/202201171028223011_ab5545081b850c5027ea70d1b3473bf.jpg');
				const formData = new FormData();
				if (this.nowPage == 0) {
					if (this.isEdit) {
						setTimeout(() => {
							formData.append("file", this.urlToFile(this.imageData.url));
						}, 300)
						return
					} else {
						// formData.append("file",this.imgFile.one);
					}
					formData.append('fileId', this.flieidList.one);
					formData.append('filePath', this.flieRouteList.one);
				} else if (this.nowPage == 1) {
					if (this.isEdit) {
						formData.append("file", this.urlToFile(this.imageData1.url));
						return
					} else {
						// formData.append("file",this.imgFile.two);
					}
					formData.append('fileId', this.flieidList.two);
					formData.append('filePath', this.flieRouteList.two);
				} else if (this.nowPage == 2) {
					if (this.isEdit) {
						formData.append("file", this.urlToFile(this.imageData2.url));
						return
					} else {
						// formData.append("file",this.imgFile.three);
					}
					formData.append('fileId', this.flieidList.three);
					formData.append('filePath', this.flieRouteList.three);
				}
				this.$axios.post('https://ai.zcjzcd.com:3334/local_poppy',
						formData
					)
					.then(res => {
						this.shbSmc = false;

						if (res.status != 200) {
							// ElMessage.error('服务器繁忙');
							this.shbSmc = false;
							this.sfwk = true;
							return
						}
						// 如果关闭弹窗则不进行canvas绘制
						if (!this.dialogEdit) {
							return
						}
						if (canvas) {
							var objects = canvas.getObjects();
							for (var i = 0; i < objects.length; i++) {
								canvas.remove(objects[i])
							}
						}
						// this.transformationW(res.data.data[0],res.data.width,res.data.height);
						for (var i = 0; i < res.data.data.length; i++) {
							this.transformationW(res.data.data[i], res.data.width, res.data.height, i);
						}
						this.ysData = res.data.data;
						res = res.data;
						if (res.lon_lat) {
							this.ysGPS = [res.lon_lat.jing[0].split(',')[0] + '.' + res.lon_lat.jing[0].split(',')[1],
								res.lon_lat.wei[0].split(',')[0] + '.' + res.lon_lat.wei[0].split(',')[1]
							];
						} else {
							this.ysGPS = []
						}
						// 识别结果弹出
						this.rightIsOpen = true;
					})
					.catch(error => {
						this.shbSmc = false;
						this.sfwk = true;
						console.log('error:', error)
					})
			},
			// url图片识别
			startIdentification1(fileData) {
				if (this.isLook) {
					return
				}
				this.shbSmc = true;
				// this.urlToFile('https://zcjzcd.com/api/public/storage/2022/01/17/202201171028223011_ab5545081b850c5027ea70d1b3473bf.jpg');
				const formData = new FormData();
				if (this.nowPage == 0) {
					formData.append('fileId', this.flieidList.one);
					formData.append('filePath', this.flieRouteList.one);
				} else if (this.nowPage == 1) {
					formData.append('fileId', this.flieidList.two);
					formData.append('filePath', this.flieRouteList.two);
				} else if (this.nowPage == 2) {
					formData.append('fileId', this.flieidList.three);
					formData.append('filePath', this.flieRouteList.three);
				}
				// console.log(fileData);
				// formData.append("file",fileData);
				// console.log(formData);
				// https://ai.zcjzcd.com:3334/upload_poppy
				this.$axios.post('https://ai.zcjzcd.com:3334/local_poppy',
						formData
					)
					.then(res => {
						this.shbSmc = false;

						if (res.status != 200) {
							// ElMessage.error('服务器繁忙');
							this.shbSmc = false;
							this.sfwk = true;
							return
						}
						// 如果关闭弹窗则不进行canvas绘制
						if (!this.dialogEdit) {
							return
						}
						if (canvas) {
							var objects = canvas.getObjects();
							for (var i = 0; i < objects.length; i++) {
								canvas.remove(objects[i])
							}
						}
						// this.transformationW(res.data.data[0],res.data.width,res.data.height);
						if (res.data.data) {
							for (var i = 0; i < res.data.data.length; i++) {
								this.transformationW(res.data.data[i], res.data.width, res.data.height, i);
							}
						}
						this.ysData = res.data.data;
						res = res.data;
						if (res.lon_lat) {
							this.ysGPS = [res.lon_lat.jing[0].split(',')[0] + '.' + res.lon_lat.jing[0].split(',')[1],
								res.lon_lat.wei[0].split(',')[0] + '.' + res.lon_lat.wei[0].split(',')[1]
							];
						} else {
							this.ysGPS = []
						}
						// 识别结果弹出
						this.rightIsOpen = true;
					})
					.catch(error => {
						this.shbSmc = false;
						this.sfwk = true;
						console.log('error:', error)
					})
			},
			befpreImg(file) {
				this.imgFile.one = file;
				this.uuid();
				// 保存绘制内容
				this.getCanvasData();
			},
			befpreImg1(file) {
				if (this.onloadCanvaImg) {
					ElMessage.error('请等待图片加载');
					return false
				}
				if (this.imageData.url == '') {
					ElMessage.error('请先上传第一个图片');
					return false
				}
				this.imgFile.two = file;
				this.uuid();
				// 保存绘制内容
				this.getCanvasData();
			},
			befpreImg2(file) {
				if (this.onloadCanvaImg) {
					ElMessage.error('请等待图片加载');
					return false
				}
				if (this.imageData.url == '') {
					ElMessage.error('请先上传第一个图片');
					return false
				}
				if (this.percentage1 > 0 && this.percentage1 < 100) {
					ElMessage.error('请等待其他图片上传');
					return false
				}
				this.imgFile.three = file;
				this.uuid();
				// 保存绘制内容
				this.getCanvasData();
			},
			uuid() {
				var s = [];
				var hexDigits = "0123456789abcdef";
				for (var i = 0; i < 32; i++) {
					s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
				}
				s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
				s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
				s[8] = s[13] = s[18] = s[23] = "-";

				var uuid = s.join("");
				this.uploadFistData.fileId = uuid;
				this.uploadsecData.fileId = uuid;
				this.wuuid = uuid;

			},
			lookImg(url) {
				// 查看时不弹出  未加载完缩略图不弹出
				if (this.isLook || this.isGetImg) {
					return
				}
				if (!url) {
					this.dialogEdit = true
				}
			},
			dialogOpen() {
				// console.log(this.canvasData);
				if (!this.isEdit) {
					this.canvasData = {
						one: '',
						two: '',
						three: ''
					}
				}
				this.initDraw();
			},
			btcColse() {
				this.shbSmc = false;
				this.isMove = false;
				this.isAdd = false;
				this.eitdItemText = "";
				this.isSaveYs = false;
			},
			dialogClose() {
				// console.log(this.canvasData);
				this.canvasData = {
					one: '',
					two: '',
					three: ''
				}
				// console.log(this.canvasData);
			},
			delImg(num) {
				var fileid = '';
				if (num == 0) {
					fileid = this.flieidList.one;
				} else if (num == 1) {
					fileid = this.flieidList.two;
				} else if (num == 2) {
					fileid = this.flieidList.three;
				}
				this.$axios.post('/file/del', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						fileId: fileid
					})
					.then(res => {

						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						if (num == 0) {
							this.imageData = '';
							this.percentage0 = 0;
							this.canvasData.one = '';
							this.flieidList.one = '';
							this.canvasSVG.one = '';
							this.khForm.jcwz = '';
							this.flieRouteList.one = '';
						} else if (num == 1) {
							this.imageData1 = '';
							this.percentage1 = 0;
							this.canvasData.two = '';
							this.flieidList.two = '';
							this.canvasSVG.two = '';
							this.flieRouteList.two = '';
						} else {
							this.imageData2 = '';
							this.percentage2 = 0;
							this.canvasData.three = '';
							this.flieidList.three = '';
							this.canvasSVG.three = '';
							this.flieRouteList.three = '';
						}
						if (num == this.nowPage) {
							if (this.imageData.url) {
								this.initDraw(this.imageData.url);
								this.nowPage = 0;
							} else if (this.imageData1.url) {
								this.initDraw(this.imageData1.url);
								this.nowPage = 1;
							} else if (this.imageData2.url) {
								this.initDraw(this.imageData2.url);
								this.nowPage = 2;
							} else {
								canvas.clear(); // 清空画布
							}
						}

					})
					.catch(error => {
						console.log('error:', error)
					})

			},
			// 图片复位
			reloadCanvas(url) {
				// if (this.nowZoom != -1) {
				// 	// 缩放偏移量
				// 	movementX +=(canvas.width/2) - (canvas.width/2) / this.nowZoom;
				// 	movementY += (canvas.height/2) - (canvas.height/2) / this.nowZoom;
				// }
				// var zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y); // 中心点
				// var zoomPoint = new fabric.Point(canvas.width/2, canvas.height/2); 
				// canvas.zoomToPoint(zoomPoint, 1);
				// var canvasData = '';
				// if (canvas.toJSON().objects) {
				// 	canvasData = JSON.stringify(canvas.toJSON());
				// }
				//  if (canvasData) {
				//  	canvas.loadFromJSON(canvasData)
				//  }
				// if (this.nowPage == 0) {
				// 	this.canvasData.one = JSON.stringify(this.canvasToImg(canvas.toJSON()));
				// 	console.log(this.canvasData.one);
				// 	// this.canvasSVG.one = canvas.toDataURL({format: 'image/png'});
				// 	this.initDraw(this.imageData.url);
				// }else if (this.nowPage == 1) {
				// 	this.canvasData.two = JSON.stringify(this.canvasToImg(canvas.toJSON()));
				// 	// this.canvasSVG.two = canvas.toDataURL({format: 'image/png'});
				// 	this.initDraw(this.imageData1.url);
				// }else if (this.nowPage == 2) {
				// 	this.canvasData.three = JSON.stringify(this.canvasToImg(canvas.toJSON()));
				// 	// this.canvasSVG.three = canvas.toDataURL({format: 'image/png'});
				// 	this.initDraw(this.imageData2.url);
				// }
				var canvasBackImg = this.canvasBackImg;
				var canvasData = this.canvasToImg(canvas.toJSON());
				if (canvas) {
					// 清空画布
					$('.vanvas-d .canvas-container').remove();
					$('.vanvas-d').prepend(
						'<canvas id="mycanvas" style="border:1px solid #ccc;width: 100%;height: 100%;" ref="mycanvas"></canvas>'
					)
					// 重置偏移量
					movementX = 0;
					movementY = 0;
				}
				// 初始化画布
				canvas = new fabric.Canvas('mycanvas');
				// 设置宽高
				canvas.setHeight($('.vanvas-d').height());
				canvas.setWidth($('.vanvas-d').width());
				// 设置背景
				canvas.setBackgroundImage(canvasBackImg, canvas.renderAll.bind(canvas));
				canvas.renderAll();

				// 绘制对象
				if (canvasData) {
					var canvasHis = canvasData;
					var textD = canvasHis.text;
					canvasHis = canvasHis.rect;
					this.ysData = canvasHis;
					if (canvasHis) {
						for (var i = 0; i < canvasHis.length; i++) {
							this.transformationW(canvasHis[i], canvasBackImg.width, canvasBackImg.height, i)
						}
					}
					if (textD) {
						for (var i = 0; i < textD.length; i++) {
							this.transformaText(textD[i], canvasBackImg.width, canvasBackImg.height)
						}
					}
				};
				// 拖拽
				canvas.on({
					// 鼠标滚动缩放
					"mouse:wheel": (e) => {
						var oldZoom = zoom;
						zoom = (event.deltaY > 0 ? -0.2 : 0.2) + canvas.getZoom();
						zoom = Math.max(1, zoom); //最小为原来的1
						zoom = Math.min(10, zoom); //最大是原来的10倍
						this.nowZoom = zoom;
						// 缩放偏移量
						movementX += (canvas.width / 2) / zoom - (canvas.width / 2) / oldZoom;
						movementY += (canvas.height / 2) / zoom - (canvas.height / 2) / oldZoom;
						// var zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y); // 中心点
						var zoomPoint = new fabric.Point(canvas.width / 2, canvas.height / 2);
						canvas.zoomToPoint(zoomPoint, zoom);
					},
				})

				canvas.on('mouse:up', (e) => {
					this.panning = false;
					endX = e.pointer.x;
					endY = e.pointer.y;
					var widthX = endX - startX > 0 ? endX - startX : startX - endX;
					var heightY = endY - startY > 0 ? endY - startY : startY - endY;
					// 减去背景平移位置
					startX = startX / zoom - movementX;
					startY = startY / zoom - movementY;
					if (this.isAdd) {
						this.addfabric(startX, startY, widthX / zoom, heightY / zoom);
						this.isAdd = false;
					}
				});
				// 鼠标按下
				canvas.on('mouse:down', (e) => {
					this.panning = true;
					startX = e.pointer.x;
					startY = e.pointer.y;
				});
				canvas.on('mouse:move', (e) => {
					if (this.isMove && this.panning && e && e.e) {
						// // console.log(e);
						var units = 10;
						movementX += (e.e.movementX) / zoom;
						movementY += (e.e.movementY) / zoom;
						var delta = new fabric.Point(e.e.movementX, e.e.movementY);
						canvas.relativePan(delta);
					}
				})
				// 取消选中状态
				if (canvas) {
					canvas.discardActiveObject().renderAll();
				}

				movementX = 0;
				movementY = 0;
				this.nowZoom = -1;
				zoom = 1;
			},
			delSecect() {
				canvas.remove(canvas.getActiveObject())
			},
			// 添加文字
			addText() {
				if (this.isLook) {
					return
				}
				this.isMove = false;
				var text = new fabric.Textbox('请在此处输入文字标注', { //绘制文本
					width: 200,
					fontSize: 20,
					left: canvas.width / 2,
					top: canvas.height / 2,
					fill: "blue",
					stroke: 'blue',
					strokeWidth: 0.1,
				});
				canvas.add(text);
				canvas.setActiveObject(text);
			},
			// 添加矩形
			addRect() {
				if (this.isLook) {
					return
				}
				this.isMove = false;
				this.isAdd = true;
			},
			// 保存，对象位置转坐标
			canvasToImg(data) {
				if (!data.objects || data.objects.length == 0) {
					return
				}
				var left0, top0, left1, top1, cdata, zxd0, zxd1, zxd2;
				var canvasData = [];
				var canvasText = [];
				var canvasWidth = $('#mycanvas').width();
				var canvasHeight = $('#mycanvas').height();
				let scale_h_percent = canvasHeight / data.backgroundImage.height;
				let scale_w_percent = canvasWidth / data.backgroundImage.width;
				data = data.objects;
				for (var i = 0; i < data.length; i++) {
					if (data[i].type == 'group') {
						cdata = [];
						left0 = data[i].left + 60;
						top0 = data[i].top + 40;
						left1 = left0 + data[i].objects[0].width;
						top1 = top0 + data[i].objects[0].height;
						if (data[i].backgroundColor) {
							zxd0 = data[i].backgroundColor.split(",")[0];
							zxd1 = data[i].backgroundColor.split(",")[1];
							zxd2 = data[i].backgroundColor.split(",")[2];
						} else {
							zxd0 = 1;
							zxd1 = 1;
							zxd2 = 1;
						}
						cdata.push(left0 / scale_w_percent);
						cdata.push(top0 / scale_h_percent);
						cdata.push(left1 / scale_w_percent);
						cdata.push(top1 / scale_h_percent);
						cdata.push(parseFloat(zxd0));
						cdata.push(parseFloat(zxd1));
						cdata.push(parseFloat(zxd2));
						canvasData.push(cdata)
					} else if (data[i].type == 'textbox') {
						canvasText.push([data[i].left / scale_w_percent, data[i].top / scale_h_percent, data[i].width,
							data[i].text
						]);
					}
				}
				var retData = {
					rect: canvasData,
					text: canvasText
				}
				// console.log(retData);
				return retData
			},
			// 位置转换
			transformationW(data, imgWidth, imgHeight, num) {
				var left0 = parseFloat(data[0].toFixed(2));
				var top0 = parseFloat(data[1].toFixed(2));
				var canvasWidth = $('#mycanvas').width();
				var canvasHeight = $('#mycanvas').height();
				let scale_h_percent = canvasHeight / imgHeight;
				let scale_w_percent = canvasWidth / imgWidth;
				var left = left0 * scale_w_percent;
				var top = top0 * scale_h_percent;
				var width = parseFloat(data[2].toFixed(2)) - parseFloat(data[0].toFixed(2));
				width = width * scale_w_percent;
				var height = parseFloat(data[3].toFixed(2)) - parseFloat(data[1].toFixed(2));
				height = height * scale_h_percent;
				var zxd = data[4] + "," + data[5] + "," + data[6];
				// console.log(left,top,width,height,num,zxd);
				this.addfabric(left, top, width, height, num, zxd);
			},
			transformaText(data, imgWidth, imgHeight) {
				var left0 = parseFloat(data[0].toFixed(2));
				var top0 = parseFloat(data[1].toFixed(2));
				var canvasWidth = $('#mycanvas').width();
				var canvasHeight = $('#mycanvas').height();
				let scale_h_percent = canvasHeight / imgHeight;
				let scale_w_percent = canvasWidth / imgWidth;
				var left = left0 * scale_w_percent;
				var top = top0 * scale_h_percent;
				var width = data[2];
				var text = new fabric.Textbox(data[3], { //绘制文本
					width: width,
					fontSize: 20,
					left: left,
					top: top,
					fill: "blue",
					stroke: 'blue',
					strokeWidth: 0.1,
				});
				canvas.add(text);
				canvas.setActiveObject(text);
			},
			addfabric(left, top, width, height, num, zxd) {
				var deleteIcon =
					"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
				// // console.log(fabric.Object.prototype);
				fabric.Object.prototype.transparentCorners = false;
				fabric.Object.prototype.borderColor = '#409eff';
				fabric.Object.prototype.cornerColor = 'blue';
				fabric.Object.prototype.cornerStyle = 'circle';
				var img = document.createElement('img');
				img.setAttribute('crossOrigin', 'anonymous');
				img.src = deleteIcon;
				var rect = new fabric.Rect({
					wleft: left,
					wtop: top,
					fill: 'rgba(255, 255, 255, 0)',
					width: width,
					height: height,
					objectCaching: false,
					stroke: '#F56C6C',
					strokeWidth: 1,
				});

				var imgElement = document.createElement('img'); //声明我们的图片
				imgElement.setAttribute('crossOrigin', 'anonymous');
				// imgElement.src = '/img/jiantou.png';
				imgElement.src = process.env.VUE_APP_IMGSRC_BASE;
				// // console.log(imgElement);
				var imgInstance = new fabric.Image(imgElement, { //设置图片位置和样子
					left: -50,
					top: -40,
					width: 500,
					scaleX: 0.1,
					scaleY: 0.1,
					height: 200,
					angle: 30 //设置图形顺时针旋转角度
				});
				if (!num && num != 0) {
					num = ''
				} else {
					num = num + 1;
					num = num > 9 ? "" + num : "0" + num;
				}

				var text = new fabric.Text(num, { //绘制文本
					left: 5,
					top: -5,
					fontSize: 14,
					originX: 'center',
					originY: 'center',
					fill: 'red'
				})

				//进行组合
				var group = new fabric.Group([rect, imgInstance, text], {
					left: left - 60,
					top: top - 40,
					backgroundColor: zxd
				})
				canvas.add(group);
				canvas.setActiveObject(group);

				fabric.Object.prototype.controls.deleteControl = new fabric.Control({
					x: 0.5,
					y: -0.5,
					offsetY: 16,
					cursorStyle: 'pointer',
					mouseUpHandler: deleteObject,
					render: renderIcon,
					cornerSize: 24
				});

				function deleteObject(eventData, transform) {
					var target = transform.target;
					var canvas = target.canvas;
					canvas.remove(target);
					canvas.remove(imgInstance);
					canvas.requestRenderAll();
				}

				function renderIcon(ctx, left, top, styleOverride, fabricObject) {
					var size = this.cornerSize;
					ctx.save();
					ctx.translate(left, top);
					ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
					ctx.drawImage(img, -size / 2, -size / 2, size, size);
					ctx.restore();
				}

			},
			setUrlCanvas() {
				this.$nextTick(function() {
					// this.initDraw();
				})
			},
			// canvas画布
			initDraw(url) {
				var that = this;
				if (canvas) {
					// 清空画布
					$('.vanvas-d .canvas-container').remove();
					$('.vanvas-d').prepend(
						'<canvas id="mycanvas" style="border:1px solid #ccc;width: 100%;height: 100%;" ref="mycanvas"></canvas>'
					)
					// 重置偏移量
					movementX = 0;
					movementY = 0;
				}
				// 初始化画布
				canvas = new fabric.Canvas('mycanvas');
				// 设置宽高
				canvas.setHeight($('.vanvas-d').height());
				canvas.setWidth($('.vanvas-d').width());
				if (!url) {
					url = this.imageData.url;
				}
				$('.onloadImg').show();
				var nowImgWidth = 0;
				var nowImgHeight = 0;
				this.onloadCanvaImg = true;
				fabric.Image.fromURL(url, (img) => {
					this.onloadCanvaImg = false;
					img.set({
						// 通过scale来设置图片大小，这里设置和画布一样大
						scaleX: canvas.width / img.width,
						scaleY: canvas.height / img.height,
					});
					this.canvasBackImg = img;
					// 设置背景
					canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
					canvas.renderAll();
					$('.onloadImg').hide();
					nowImgWidth = img.width;
					nowImgHeight = img.height;
					// 绘制历史记录
					// if (this.isEdit && this.getImgData[0].sbxx && this.nowPage == 0) {
					//  var canvasHis = JSON.parse(this.getImgData[0].sbxx);
					// for (var i = 0; i < canvasHis.length; i++) {
					// 	this.transformationW(canvasHis[i],nowImgWidth,nowImgHeight,i)
					// }
					// }
					// 切换图片时绘制
					// console.log(this.nowPage);
					if (this.nowPage == 0 && this.canvasData.one) {
						var canvasHis = JSON.parse(this.canvasData.one);
						var textD = canvasHis.text;
						canvasHis = canvasHis.rect;
						this.ysData = canvasHis;
						if (canvasHis) {
							for (var i = 0; i < canvasHis.length; i++) {
								this.transformationW(canvasHis[i], nowImgWidth, nowImgHeight, i)
							}
						}
						if (textD) {
							for (var i = 0; i < textD.length; i++) {
								this.transformaText(textD[i], nowImgWidth, nowImgHeight)
							}
						}
					} else if (this.nowPage == 1 && this.canvasData.two) {
						var canvasHis = JSON.parse(this.canvasData.two);
						var textD = canvasHis.text;
						canvasHis = canvasHis.rect;
						this.ysData = canvasHis;
						if (canvasHis) {
							for (var i = 0; i < canvasHis.length; i++) {
								this.transformationW(canvasHis[i], nowImgWidth, nowImgHeight, i)
							}
						}
						if (textD) {
							for (var i = 0; i < textD.length; i++) {
								this.transformaText(textD[i], nowImgWidth, nowImgHeight)
							}
						}
					} else if (this.nowPage == 2 && this.canvasData.three) {
						var canvasHis = JSON.parse(this.canvasData.three);
						var textD = canvasHis.text;
						canvasHis = canvasHis.rect;
						this.ysData = canvasHis;
						if (canvasHis) {
							for (var i = 0; i < canvasHis.length; i++) {
								this.transformationW(canvasHis[i], nowImgWidth, nowImgHeight, i)
							}
						}
						if (textD) {
							for (var i = 0; i < textD.length; i++) {
								this.transformaText(textD[i], nowImgWidth, nowImgHeight)
							}
						}
					}
				});
				canvas.on({
					// 鼠标滚动缩放
					"mouse:wheel": (e) => {
						var oldZoom = zoom;
						zoom = (event.deltaY > 0 ? -0.2 : 0.2) + canvas.getZoom();
						zoom = Math.max(1, zoom); //最小为原来的1
						zoom = Math.min(10, zoom); //最大是原来的10倍
						that.nowZoom = zoom;
						// 缩放偏移量
						movementX += (canvas.width / 2) / zoom - (canvas.width / 2) / oldZoom;
						movementY += (canvas.height / 2) / zoom - (canvas.height / 2) / oldZoom;
						// var zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y); // 中心点
						var zoomPoint = new fabric.Point(canvas.width / 2, canvas.height / 2);
						canvas.zoomToPoint(zoomPoint, zoom);
					},
				})

				canvas.on('mouse:up', (e) => {
					this.panning = false;
					endX = e.pointer.x;
					endY = e.pointer.y;
					var widthX = endX - startX > 0 ? endX - startX : startX - endX;
					var heightY = endY - startY > 0 ? endY - startY : startY - endY;
					// 减去背景平移位置
					startX = startX / zoom - movementX;
					startY = startY / zoom - movementY;
					if (this.isAdd) {
						this.addfabric(startX, startY, widthX / zoom, heightY / zoom);
						this.isAdd = false;
					}
				});
				// 鼠标按下
				canvas.on('mouse:down', (e) => {
					this.panning = true;
					startX = e.pointer.x;
					startY = e.pointer.y;
				});
				canvas.on('mouse:move', (e) => {
					if (this.isMove && this.panning && e && e.e) {
						// // console.log(e);
						var units = 10;
						movementX += (e.e.movementX) / zoom;
						movementY += (e.e.movementY) / zoom;
						var delta = new fabric.Point(e.e.movementX, e.e.movementY);
						canvas.relativePan(delta);
					}
				})




			},
			collectRight() {
				this.rightIsOpen = !this.rightIsOpen
			},
			getGlrw() {
				var link_dept = '';
				// if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
				// 		'type') == 2) {
				// 	link_dept = sessionStorage.getItem('deptId');
				// } else if (sessionStorage.getItem('type') == 3) {
				// 	link_dept = sessionStorage.getItem('link_dept');
				// }
				link_dept = sessionStorage.getItem('deptId');
				this.$axios.post('/papaver/linkTask', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						link_dept: link_dept
					})
					.then(res => {

						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						this.glrwList = res.data.data;
						this.ckGlrwList = res.data.data;
						this.ckGlrwList = [{
							taskName: '全部',
							taskId: ''
						}];
						for (var i = 0; i < res.data.data.length; i++) {
							this.ckGlrwList.push(res.data.data[i])
						}
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			aavvc() {},
			successW1(response, file, fileList) {
				this.flieidList.two = this.wuuid;
				this.flieRouteList.two = response.filePath;
				this.imageData1 = response;
				this.imageData1.url = response.fileUrl;
				$('.onloadImg').show();
				this.initDraw(response.fileUrl);
				this.nowPage = 1;
			},
			successW2(response, file, fileList) {
				this.flieidList.three = this.wuuid;
				this.flieRouteList.three = response.filePath;
				this.imageData2 = response;
				this.imageData2.url = response.fileUrl;
				$('.onloadImg').show();
				this.initDraw(response.fileUrl);
				this.nowPage = 2;
			},
			successW(response, file, fileList) {
				this.flieidList.one = this.wuuid;
				this.flieRouteList.one = response.filePath;
				$('.onloadImg').show();
				this.imageData = response;
				this.imageData.url = response.fileUrl;
				this.khForm.jcwz = response.filePosition.address ? response.filePosition.address : '';
				if (response.filePosition.longitude) {
					this.nowCenter = [response.filePosition.longitude, response.filePosition.latitude];
					this.setCenter();
				} else {
					ElMessage.error('未获取到经纬度信息');
				}
				// 初始化canvas
				this.initDraw();
				this.nowPage = 0;
			},
			uploadProgress(event, file, fileList) {
				this.percentage0 = parseFloat(event.percent.toFixed(2));
			},
			uploadProgress1(event, file, fileList) {
				this.percentage1 = parseFloat(event.percent.toFixed(2));
			},
			uploadProgress2(event, file, fileList) {
				this.percentage2 = parseFloat(event.percent.toFixed(2));
			},
			searchBtn() {
				this.tableNowPage = 1;
				this.getKhList(this.tablekwd)
			},
			stopServe(index, row, state) {
				this.$axios.post('/flight/updateUserState', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						fxUserId: row.userId,
						state: state
					})
					.then(res => {

						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.tableData[index].state = state;
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			// 获取图片信息
			getImgBase(dybh, isLook) {
				this.isGetImg = true;
				this.$axios.post('/papaver/file', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						dybh: dybh,
					})
					.then(res => {
						this.isGetImg = false;
						var data = res.data.data;
						console.log(data)
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.jcxxImgnUM = 0;
						// 稽查信息
						var jcdd = res.data.jcData;
						for (var i = 0; i < jcdd.length; i++) {
							this.imgListJc.push(jcdd[i].url);
							this.jcxxImg.push(jcdd[i].thumbUrl);
							this.jcxxImgnUM = this.jcxxImgnUM + 1;
						}
						// 查看图片
						for (var i = 0; i < data.length; i++) {
							this.imgList.push(data[i].sbUrl)
						}
						// 原图
						for (var i = 0; i < data.length; i++) {
							this.sourceUrlList.push(data[i].sourceUrl)
						}
						console.log(this.sourceUrlList)
						this.getImgData = data;
						if (data[0]) {
							this.flieidList.one = data[0].fileId;
							this.canvasSVG.one = data[0].sbUrl;
							this.flieRouteList.one = data[0].filePath;

							if (data[0].sbUrl) {
								this.imageData.thumbFileUrl = data[0].sbUrl;
							} else {
								this.imageData.thumbFileUrl = data[0].thumbUrl;
							}
							if (isLook) {
								this.imageData.url = data[0].sbUrl;
							} else {
								this.imageData.url = data[0].url;
								if (data[0].sbxx) {
									this.canvasData.one = data[0].sbxx;
								}
							}
						}
						if (data[1]) {
							this.flieidList.two = data[1].fileId;
							this.canvasSVG.two = data[1].sbUrl;
							this.flieRouteList.two = data[0].filePath;

							if (data[1].sbUrl) {
								this.imageData1.thumbFileUrl = data[1].sbUrl;
							} else {
								this.imageData1.thumbFileUrl = data[1].thumbUrl;
							}

							if (isLook) {
								this.imageData1.url = data[1].sbUrl;
							} else {
								this.imageData1.url = data[1].url;
								if (data[1].sbxx) {
									this.canvasData.two = data[1].sbxx;
								}
							}
						}
						if (data[2]) {
							this.flieidList.three = data[2].fileId;
							this.canvasSVG.three = data[2].sbUrl;
							this.flieRouteList.three = data[0].filePath;

							if (data[2].sbUrl) {
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							} else {
								this.imageData2.thumbFileUrl = data[2].thumbUrl;
							}

							if (isLook) {
								this.imageData2.url = data[2].sbUrl;
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							} else {
								this.imageData2.url = data[2].url;
								this.imageData2.thumbFileUrl = data[2].sbUrl;
								if (data[2].sbxx) {
									this.canvasData.three = data[2].sbxx;
								}
							}
						}
						// console.log(this.canvasData);
					})
					.catch(error => {
						this.isGetImg = false;
						console.log('error:', error)
					})
			},
			userLook(index, row) {
				// console.log(this.isLook);
				// 清空稽查信息
				this.jcxxImg = [];
				this.jcForm = {
					checkLevel: "",
					checkNode: "",
					checkState: 0,
					checkTime: '',
					checkUserId: "",
					checkUserName: ""
				};
				this.rowData = row;
				this.khForm.jcwz = row.address;
				this.khForm.zbxx = row.gps;
				this.khForm.sfqr = row.state;
				this.khForm.sldj = row.level;
				this.khForm.glrw = row.taskId;
				this.isEdit = false;
				this.nowCenter = row.gps.split(",");
				this.drawerTitle = "查看任务--" + row.dybh;
				var isNullhlrw = true;
				for (var i = 0; i < this.glrwList.length; i++) {
					if (this.glrwList[i].taskId == row.taskId) {
						isNullhlrw = false;
					}
				};
				if (isNullhlrw) {
					this.glrwList.push({
						taskId: row.taskId,
						taskName: row.taskName
					})
				}
				// 获取图片
				this.getImgBase(row.dybh, true);
				this.$nextTick(() => {
					this.isLook = true;
				})
				this.drawerkh = true;
				// 稽查信息
				this.jcForm = row;
				// console.log(row);
			},
			// 还原
			handleReduction(index, row) {
				this.$axios.post('/papaver/move', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						dybh: row.dybh,
					})
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.drecycleBinData.splice(index, 1);
						this.getKhList();
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			// 清空回收站
			emptyReduction(index, row) {
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
						'type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/removeAll', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						link_dept: khlink_dept,
					})
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.getHszList();
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			// 回收站删除
			recycleDelete(index, row) {
				this.$axios.post('/papaver/remove', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						dybh: row.dybh,
					})
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.drecycleBinData.splice(index, 1);
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			handleEdit(index, row) {
				this.rowData = row;
				this.isEdit = true;
				this.khForm.jcwz = row.address;
				this.khForm.zbxx = row.gps;
				this.khForm.sfqr = row.state;
				this.khForm.sldj = row.level;
				this.khForm.glrw = row.taskId;
				this.khForm.glrwName = row.taskName;
				this.isLook = false;
				this.nowCenter = row.gps.split(",");
				this.drawerTitle = "修改任务--" + row.dybh;
				var isNullhlrw = true;
				for (var i = 0; i < this.glrwList.length; i++) {
					if (this.glrwList[i].taskId == row.taskId) {
						isNullhlrw = false;
					}
				};
				if (isNullhlrw) {
					this.glrwList.push({
						taskId: row.taskId,
						taskName: row.taskName
					})
				}
				// 获取图片
				this.getImgBase(row.dybh, false);
				this.drawerkh = true;
			},
			handleDelete(index, row) {
				this.$axios.post('/papaver/del', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						dybh: row.dybh,
					})
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						ElMessage.success(res.data.msg);
						this.tableData.splice(index, 1);
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			nodclick(data, node, res) {
				this.khForm.deptId = data.id;
				this.khForm.deptName = data.label;
				this.tableNowPage = 1;
				// 客户列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
						'type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/flight/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						deptId: node.data.id,
						link_dept: khlink_dept,
						page: this.tableNowPage,
						limit: this.limit
					})
					.then(res => {
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						this.tableData = res.data.data;
						this.tableTotal = res.data.count;
					})
					.catch(error => {
						console.log('error:', error)
					})

			},
			khClick() {
				this.drawerTitle = '信息上报';
				this.isEdit = false;
				this.isLook = false;
				this.drawerkh = true;
			},
			drawerOpen() {
				if (this.map != '') {
					if (this.khForm.zbxx) {
						var centerSps = this.khForm.zbxx.split(",");
						this.map.centerAndZoom(new T.LngLat(centerSps[0], centerSps[1]), 18);
					} else {
						this.map.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
					}
					return
				}
				var imageURL = "http://t0.tianditu.gov.cn/img_w/wmts?" +
					"SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
					"&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1c0b123b51edfa0425d320598f59c192";
				var textURl =
					"https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles&tk=1c0b123b51edfa0425d320598f59c192";
				//创建自定义图层对象
				var lay = new T.TileLayer(imageURL, {
					minZoom: 1,
					maxZoom: 18
				});
				var textLay = new T.TileLayer(textURl, {
					minZoom: 1,
					maxZoom: 18
				});
				var config = {
					layers: [lay, textLay]
				};
				//初始化地图对象
				this.map = new T.Map('mapDiv', config);
				// 
				if (this.khForm.zbxx) {
					var centerSps = this.khForm.zbxx.split(",");
					this.map.centerAndZoom(new T.LngLat(centerSps[0], centerSps[1]), 18);
				} else {
					this.map.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
				}
				//允许鼠标滚轮缩放地图
				this.map.enableScrollWheelZoom();
				// 拖拽监听
				this.map.addEventListener("moveend", (e) => {
					this.khForm.zbxx = e.target.getCenter().getLng() + "," + e.target.getCenter().getLat();
				});
			},
			// drawerOpened() {
			// 	let that = this;
			// 	setTimeout(function() {

			// 	}, 1000)
			// },
			autoInput() {

			},
			setCenter(location, adderss) {
				if (this.nowCenter) {
					this.map.centerAndZoom(new T.LngLat(this.nowCenter[0], this.nowCenter[1]), 18);
				}
			},
			mapblur() {
				setTimeout(res => {
					this.mapInpoutOnFocus = false;
				}, 200)
			},
			// ssdwBtn(formName) {
			// 	let that = this;
			// 	var urlType = '';
			// 	if (this.clickType == 'edit') {
			// 		urlType = '/dept/update'
			// 	} else if (this.clickType == 'add') {
			// 		urlType = '/dept/add'
			// 	}
			// 	this.$refs[formName].validate((valid) => {
			// 		if (valid) {

			// 		} else {
			// 			// console.log('填写不能为空')
			// 			return false
			// 		}
			// 	})

			// },
			khBtn(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var link_dept;
						if (sessionStorage.getItem('type') == 3) {
							link_dept = sessionStorage.getItem('link_dept');
						} else {
							link_dept = sessionStorage.getItem('deptId');
						}
						var fileIdList = [];
						if (this.flieidList.one) {
							fileIdList.push(this.flieidList.one);
						}
						if (this.flieidList.two) {
							fileIdList.push(this.flieidList.two);
						}
						if (this.flieidList.three) {
							fileIdList.push(this.flieidList.three);
						}
						if (this.isEdit) {
							// 修改rowData
							this.$axios.post('/papaver/update', {
									userId: sessionStorage.getItem('userId'),
									type: sessionStorage.getItem('type'),
									dybh: this.rowData.dybh,
									address: this.khForm.jcwz,
									gps: this.khForm.zbxx,
									state: this.khForm.sfqr,
									level: this.khForm.sldj,
									taskId: this.khForm.glrw,
									taskName: this.khForm.glrwName,
									fileIds: fileIdList
								})
								.then(res => {
									if (res.data.code != 200) {
										ElMessage.error(res.data.msg);
										return
									}
									ElMessage.success(res.data.msg);
									this.getKhList();
									// 关闭右侧弹出
									this.drawerkh = false;
								})
								.catch(error => {
									console.log('error:', error)
								})


						} else {
							// 新增
							var fileIdList = [];
							if (this.flieidList.one) {
								fileIdList.push(this.flieidList.one);
							}
							if (this.flieidList.two) {
								fileIdList.push(this.flieidList.two);
							}
							if (this.flieidList.three) {
								fileIdList.push(this.flieidList.three);
							}
							this.$axios.post('/papaver/add', {
									userId: sessionStorage.getItem('userId'),
									userName: sessionStorage.getItem('username'),
									type: sessionStorage.getItem('type'),
									address: this.khForm.jcwz,
									gps: this.khForm.zbxx,
									state: this.khForm.sfqr,
									level: this.khForm.sldj,
									taskId: this.khForm.glrw,
									taskName: this.khForm.glrwName,
									fileIds: fileIdList
								})
								.then(res => {
									if (res.data.code != 200) {
										ElMessage.error(res.data.msg);
										return
									}
									ElMessage.success(res.data.msg);
									// 关闭右侧弹出
									this.drawerkh = false;
									// this.tableData.push({
									// 	account: this.khForm.user,
									// 	deptID: sessionStorage.getItem('deptId'),
									// 	deptName: sessionStorage.getItem('deptName'),
									// 	state: 1,
									// 	userId: res.data.userId,
									// 	username: this.khForm.khmc
									// })
								})
								.catch(error => {
									console.log('error:', error)
								})

						}


					} else {
						return false
					}
				})
			},
			gpsToAdderss() {
				let that = this;
				var location = this.map.getCenter();
				if (!location) {
					return
				}
				var geocoder = new AMap.Geocoder({
					radius: 500 //范围，默认：500
				});
				geocoder.getAddress([location.lng, location.lat], function(status, result) {
					that.ssdwForm.ditusou = result.regeocode.formattedAddress;
				});
			},
			// resetForm(formName) {
			// 	this.ssdwForm.dwmc = '';
			// 	this.ssdwForm.ditusou = '';
			// 	this.tjdwsj = '';
			// 	this.editGps = [];
			// },
			resetFormkh(formName) {
				this.khForm = {
					jcwz: '',
					zbxx: '',
					sfqr: 0,
					sldj: '1~50',
					glrw: '',
				};
				this.imageData = {
					url: ''
				};
				this.imageData1 = {
					url: ''
				};
				this.imageData2 = {
					url: ''
				};
				this.canvasData = {
					one: '',
					two: '',
					three: ''
				};
				this.flieidList = {
					one: '',
					two: '',
					three: ''
				};
				this.percentage0 = 0,
					this.percentage1 = 0,
					this.percentage2 = 0,
					// 识别结果重置
					this.eitdItemText = '';
				this.ysData = [];
				this.$refs.khForm.clearValidate();

				this.showViewer = false;
				this.imgList = [];
				this.sourceUrlList = [];
				this.viewerIndex = 0;

				this.showViewerJc = false;
				this.imgListJc = [];
				this.viewerIndexJc = 0;
				this.getImgData = [];
			},
			resetForm1(formName) {
				this.$refs[formName].resetFields()
			},
			getKhList(state) {
				this.tableLoading = true;
				if (!state) {
					state = 1
				}
				this.listState = state;
				// 任务列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
						'type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						taskId: this.tableTaskId,
						link_dept: khlink_dept,
						page: this.tableNowPage,
						limit: this.limit,
						search: this.tablekwd,
						dyzt: this.dhcState
					})
					.then(res => {
						this.tableLoading = false;
						//  
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.tableData = res.data.data;
						this.tableTotal = res.data.count;
					})
					.catch(error => {
						this.tableLoading = false;
						console.log('error:', error)
					})
			},
			// 回收站
			getHszList(state) {
				if (!state) {
					state = 1
				}
				this.listState = state;
				// 任务列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
						'type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						taskId: '',
						link_dept: khlink_dept,
						page: this.BintableNowPage,
						limit: this.limit,
						search: '',
						dyzt: '',
						isDel: 1
					})
					.then(res => {

						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.drecycleBinData = res.data.data;
						this.BintableTotal = res.data.count;
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			changePage() {
				this.getKhList();
			},
			BinchangePage() {
				this.getHszList();
			},
			setSocket() {
				var heartCheck, reconnectionWs;
				if (window.WebSocket) {
					this.socket = new WebSocket("wss://ai.zcjzcd.com:21000/ws") // 与后端配置ip端口一致（连接websocket）
					this.socket.onopen = () => {
						// console.log("服务器连接成功");
						var sendData = {
							'uid': sessionStorage.getItem('userId')
						}
						this.socket.send(JSON.stringify(sendData));

						heartCheck = setTimeout(() => {
							// console.log('发送心跳包');
							this.socket.send(JSON.stringify({
								'beat': 1
							}));
							reconnectionWs = setTimeout(() => {
								// console.log('重新连接');
								this.setSocket();
							}, 1000 * 10)
						}, 1000 * 60);
					}
					this.socket.onclose = function() {
						// console.log("服务器连接关闭")
					}
					this.socket.onerror = (e) => {
						// console.log("服务器连接出错", e)
						// console.log('重新连接');
						setTimeout(() => {
							this.setSocket();
						}, 3000)
					}
					this.socket.onmessage = (e) => { // 接收服务器返回的数据
						// console.log(e.data)
						clearTimeout(heartCheck);
						clearTimeout(reconnectionWs);
						heartCheck = setTimeout(() => {
							// console.log('发送心跳包');
							this.socket.send(JSON.stringify({
								'beat': 1
							}));
							reconnectionWs = setTimeout(() => {
								// console.log('重新连接');
								this.setSocket();
							}, 1000 * 10)
						}, 1000 * 60);
					}
				} else {
					// console.log('抱歉，您的浏览器不支持Websocket协议！')
				}
			},
			//心跳检测
			heartCheck() {
				var timeout = 540000; //9分钟发一次心跳
				var timeoutObj = null;
				var serverTimeoutOb = null;
			}
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val)
			},
			'ssdwForm.ditusou'() {
				this.autoInput();
			}
		},
		mounted() {
			if (sessionStorage.getItem('type') == 2) {
				this.dataType = '2';
				this.dhcState = 2;
			}
			// 客户列表
			this.getKhList();
			// 关联任务
			this.getGlrw();
			// 建立连接
			// this.setSocket();
		},
		beforeUnmount() {
			// 关闭连接
			// this.socket.close()
			// 销毁 websocket 实例对象
			// this.socket = null
		}
	}

	function poiPickerReady(poiPicker) {
		window.poiPicker = poiPicker;
		var marker = new AMap.Marker();
		var infoWindow = new AMap.InfoWindow({
			offset: new AMap.Pixel(0, -20)
		});
	}
</script>

<style scoped>
	.el-row {
		height: 100%;
		flex-wrap: nowrap !important;
	}

	.left {
		min-width: 16.667%;
		margin-right: 10px;
	}

	.right {
		width: 100%;
		flex: 1;
	}

	.custom-tree-node {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.filter-input {
		width: 100%;
		display: flex;
		height: 50px;
		align-items: center;
		margin-bottom: 15px;
	}

	.right-title {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.drawer-div {
		width: 100%;
		height: calc(100% - 50px);
		/* height: 850px; */
		box-sizing: border-box;
		padding: 10px;
		display: flex;
	}

	.gd-ditu {
		width: 100%;
		height: 400px;
		border-radius: 14px;
		position: relative;
	}

	.lebel-w {
		width: 100%;
		display: block;
		text-align: left;
		line-height: 45px;
		font-size: 14px;
		padding-left: 12px;
	}

	.mapList {
		width: 100%;
		position: absolute;
		top: 40px;
		left: 0;
		z-index: 99;
		background: #fff;
		text-align: left;
		list-style: none;
	}

	.mapList>li {
		box-sizing: border-box;
		padding-left: 15px;
	}

	.mapList>li:hover {
		background: #f2f4f5;
	}

	.ditu-center {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 98;
	}

	.right-main {
		width: 100%;
		height: calc(100% - 140px);
		overflow: auto;
	}

	.radius-w {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		line-height: 28px;
		text-align: center;
		/* display: flex;
		align-items: center;
		justify-content: center; */
		cursor: pointer;
		color: #FFFFFF;
	}

	.drawer-left {
		flex: 1;
		position: relative;
	}

	.mapSz {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		font-size: 60px;
		color: #67C23A;
	}

	.drawer-right {
		width: 40%;
		overflow-y: auto;
	}

	#mapDiv {
		width: 100%;
		height: 100%;
	}

	.vanvas-d {
		border: 1px solid #ccc;
		width: calc(80% - 6vw);
		height: 36vw;
		position: relative;
		overflow: hidden;
	}

	.BasicTextResult {
		top: 0;
		right: 0;
		z-index: 12;
		width: 300px;
		height: 100%;
		display: flex;
		overflow: hidden;
		position: absolute;
		color: #fff;
	}

	.BasicTextResult-leftIcon {
		width: 15px;
		height: 100%;
		position: relative;
		transition: all .5s linear 0ms;
		cursor: pointer;
	}

	.leftIconDiv {
		height: 100px;
		border-right: 15px solid rgba(0, 0, 0, .4);
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		position: absolute;
		top: 216px;
		left: 0;
	}

	.leftIconDiv .leftIcon {
		font-size: 6px;
		position: absolute;
		top: 40px;
		left: 4px;
		color: #fff;
	}

	.BasicTextResult-textResult {
		width: 285px;
		height: 100%;
		padding: 16px;
		overflow-y: auto;
		transition: all .5s linear 0ms;
		background: rgba(0, 0, 0, .4);
		box-sizing: border-box;
		text-align: left;
	}

	.CentreResult-title {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #fff;
		letter-spacing: 0;
		line-height: 31.22px;
		clear: both;
		text-align: left;
		text-indent: 1em;
	}

	.CentreResult-Div-titileSpan {
		width: 80px;
		float: left;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #fff;
		letter-spacing: 0;
		line-height: 31.22px;
	}

	.CentreResult-Div-resultSpan {
		margin-left: 10px;
		width: 130px;
		float: left;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #fff;
		letter-spacing: 0;
		line-height: 31.22px;
	}

	.BasicTextResult.open {
		transform: translateX(285px);
		transition: all .5s linear 0ms;
	}

	.BasicTextResult.close {
		transform: translateX(0);
		transition: all .5s linear 0ms;
	}

	.basic-text {
		height: 22px;
		width: 85px;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #fff;
		margin-bottom: 10px;
		letter-spacing: 0;
	}

	.shb-smc {
		/* display: none; */
		width: 100%;
		height: 0;
		position: absolute;
		top: 0;
		left: 0;
		background-image: linear-gradient(to top, #3a98df, #42b0ff, #ffffff, #ffffff);
		z-index: 99;
		opacity: 0.5;
		animation: shbSmc 2s linear 0s infinite;
	}

	.onloadImg {
		width: 30%;
		height: 30%;
		/* background: url(../../public/img/loadBg.png) no-repeat;
		background-position: center top;
		background-size: auto 100%; */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: none;
	}

	.eitd-item {
		width: 6vw;
		height: 4vw;
		text-align: center;
		cursor: pointer;
		margin-bottom: .5vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.eitd-item img {
		width: 2vw;
	}

	.isEitd-item {
		background-color: aliceblue;
	}

	.plsc-card {
		width: 100%;
		height: 500px;
		overflow-y: auto;
		margin: 15px 0;
		border: 2px dashed #CCCCCC;
		border-radius: 14px;
		position: relative;
	}

	.plsc-card-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 2px;
		color: #ccc;
	}

	.sbwc-text {
		color: #1ba57b !important;
	}

	.sbwc-text .svg-text {
		color: #1ba57b !important;
	}

	@keyframes shbSmc {
		from {
			height: 0;
		}

		to {
			height: 36vw;
		}
	}

	.image-viewer-footer {
		width: 100%;
		position: absolute;
		top: 10px;
		left: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		/* 根据背景色调整文字颜色 */
		/* 其他样式... */
	}
</style>