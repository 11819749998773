<template>
	<el-row :gutter="30" >
		<el-col :span="17" style="padding-left: 0;padding-right: 0;">
			<div style="width: 100%;display: flex;justify-content: space-around;">
				<div class="item-card">
					<img src="/img/jzcd/ysb.svg">
					<div style="margin-left: 1vw;text-align: left;">
						<p class="item-text">今年已上报</p>
						<p class="itme-num">{{ysData.jnysb}}</p>
					</div>
				</div>
				<div class="item-card">
					<img src="/img/jzcd/yjc.svg">
					<div style="margin-left: 1vw;text-align: left;">
						<p class="item-text">今年已稽查</p>
						<p class="itme-num">{{ysData.jnyjc}}</p>
					</div>
				</div>
				<div class="item-card">
					<img src="/img/jzcd/wjc.svg">
					<div style="margin-left: 1vw;text-align: left;">
						<p class="item-text">今年未稽查</p>
						<p class="itme-num">{{ysData.jnwjc}}</p>
					</div>
				</div>
			</div>

			<div style="width: 100%;display: flex;justify-content: space-around;margin-top: 30px;">
				<div class="item-card" style="height: 9vw;">
					<img src="/img/jzcd/Aysb.svg">
					<div style="margin-left: 1vw;text-align: left;">
						<p class="item-text">总上报</p>
						<p class="itme-num">{{ysData.zsb}}</p>
					</div>
				</div>
				<div class="item-card" style="height: 9vw;">
					<img src="/img/jzcd/Ayjc.svg">
					<div style="margin-left: 1vw;text-align: left;">
						<p class="item-text">总稽查</p>
						<p class="itme-num">{{ysData.zjc}}</p>
					</div>
				</div>
				<div class="item-card endTime" style="height: 9vw;justify-content: space-between;">
					<div style="margin-left: 1vw;text-align: left;">
						<p class="item-text" style="color: #FFFFFF;">有效期至</p>
						<p class="itme-num" style="color: #FFFFFF;">{{endTime.endTime}}</p>
					</div>
					<el-progress type="circle" :percentage="100 " :width="80" color="#ffffff">
						<template #default="{ percentage }">
							<p style="color: #FFFFFF;">剩余天数</p>
							<p style="color: #FFFFFF;margin-top: 5px;">{{endTime.surplus}}</p>
						</template>
					</el-progress>
				</div>
			</div>
		</el-col>
		<el-col :span="7">
			<div class="item-card" style="height: 100%;width: 100%;">
				<div style="width: 100%;">
					<el-avatar :size="90" :src="userUrl"></el-avatar>
					<p class="user-text" style="margin-top: 20px;">{{username}}</p>
					<p class="user-zc" style="margin-top: 5px;">{{deptName}}</p>
					<div style="width: 100%;display: flex;margin-top: 30px;justify-content: space-evenly;">
						<div style="display: flex;cursor: pointer;" @click="changePassword">
							<img src="/img/jzcd/Revise.svg" width="25">
							<span style="margin-left: 15px;">修改密码</span>
						</div>
						<el-divider direction="vertical" border-style="dashed"></el-divider>
						<div style="display: flex;cursor: pointer;" @click="tologin">
							<img src="/img/jzcd/quit.svg" width="25">
							<span style="margin-left: 15px;">退出登录</span>
						</div>
					</div>
				</div>
			</div>
		</el-col>
	</el-row>
	<div style="width: 100%;margin-top: 30px;display: flex;justify-content: space-between;">
		<div style="width: 47%;display: flex;" v-if="userType == 1 || userType == 0">
			<div class="item-card-ry">
				<div style="width: 100%;">
					<img src="/img/jzcd/icon.svg">
					<p class="item-text" style="margin-top: 30px;">飞行员人数</p>
					<p class="itme-num">{{userData.fxry}}</p>
				</div>
			</div>
			<div class="item-card-ry" style="margin-left: 1.5vw;">
				<div style="width: 100%;">
					<img src="/img/jzcd/jcry.svg">
					<p class="item-text" style="margin-top: 30px;">客户数量</p>
					<p class="itme-num">{{userData.khsl}}</p>
				</div>
			</div>
		</div>
		<div style="width: 52%;display: flex;height: 14.5vw;" :class="{'x-div':userType ==2  || userType == 3}">
			<div class="item-chart">
				<div id="chartWeek" style="width: 100%;height: 100%;">

				</div>
			</div>
		</div>
	</div>

	<!-- 修改密码弹窗 -->
	<el-dialog v-model="dialogFormVisible" title="修改密码" :show-close="false">

		<el-form ref="PasswordForm" :model="PasswordForm" :rules="Passwordrules" label-width="120px"
			class="demo-ruleForm">
			<el-form-item label="新密码" prop="old">
				<el-input v-model="PasswordForm.old" type="password" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="new">
				<el-input v-model="PasswordForm.new" type="password" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取消</el-button>
				<el-button type="primary" @click="passwordBtn('PasswordForm')">确定</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		ElMessage,
		ElLoading
	} from 'element-plus'
	import md5 from 'js-md5'
	export default {
		data() {
			const validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入新密码'))
				} else {
					callback()
				}
			}
			const validatePass3 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入新密码'))
				} else if (value !== this.PasswordForm.old) {
					callback(new Error("两次输入的密码不一致"))
				} else {
					callback()
				}
			}
			this.myChart = null
			return {
				endTime: {
					endTime: "",
					surplus: 0
				},
				PasswordForm: {
					old: '',
					new: ''
				},
			  username: sessionStorage.getItem('username'),
			  deptName: sessionStorage.getItem('deptName'),
				userType: sessionStorage.getItem('type'),
				publicPath: process.env.BASE_URL,
				ysData: {
					jnwjc: 0,
					jnyjc: 0,
					jnysb: 0,
				 zjc: 0,
					zsb: 0
				},
				userData: {
					fxry: 0,
					khsl: 0
				},
				userUrl:'/img/userICon.jpg',
				dialogFormVisible: false,
				// 密码修改验证
				Passwordrules: {
					old: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					new: [{
						validator: validatePass3,
						trigger: 'blur'
					}],
				},
				// myChart:''
			}
		},
		methods: {
			getWeekChart(){
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
						'type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/home/7day', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						link_dept: khlink_dept,
					})
					.then(res => {
						// console.log(res);
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.setChart(res.data.data);
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			getHomeCount() {
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem(
						'type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/home/homeCount', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						link_dept: khlink_dept,
					})
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.ysData = res.data.ysData[0];
						this.userData = res.data.ryData;
						this.endTime = res.data.endTime;
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			tologin() {
				sessionStorage.clear();
				this.$router.push('/');
			},
			changePassword() {
				this.dialogFormVisible = true;
			},
			xgmm(){
				console.log(111);
				this.$axios.post('/system/updatePassword', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						newPassword: md5(this.PasswordForm.old),
						confirmPassword: md5(this.PasswordForm.new)
					})
					.then(res => {
						// console.log(res);
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
				
						// ElMessage.success(res.data.msg);
						this.$message.success(res.data.msg);
						this.dialogFormVisible = false;
					})
					.catch(error => {
						console.error('error:', error)
					})
			},
			passwordBtn(formName) {
				// this.xgmm();
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/system/updatePassword', {
								userId: sessionStorage.getItem('userId'),
								type: sessionStorage.getItem('type'),
								newPassword: md5(this.PasswordForm.old),
								confirmPassword: md5(this.PasswordForm.new)
							})
							.then(res => {
								// console.log(res);
								if (res.data.code != 666) {
									ElMessage.error(res.data.msg);
									return
								}

								// ElMessage.success(res.data.msg);
								this.$message.success(res.data.msg);
								this.dialogFormVisible = false;
							})
							.catch(error => {
								console.error('error:', error)
							})
					} else {
						console.error('error submit!!')
						return false
					}
				})

			},
			setChart(data) {
				let bgColor = "#fff";
				let color = [
					"#0090FF",
					"#36CE9E",
					"#FFC005",
					"#FF515A",
					"#8B5CFF",
					"#00CA69"
				];
				// let xAxisData = ["1", "2", "3", "4", "5", "6", "7", "8"];
				// let yAxisData1 = [100, 138, 350, 173, 180, 150, 180, 230];
				// let yAxisData2 = [233, 233, 200, 180, 199, 233, 210, 180];
				let xAxisData = [];
				let yAxisData1 = [];
				let yAxisData2 = [];
				for (var i = 0; i < data.length; i++) {
					xAxisData.push(data[i].time);
					yAxisData1.push(data[i].yjc);
					yAxisData2.push(data[i].ysb);
				}
				const hexToRgba = (hex, opacity) => {
					let rgbaColor = "";
					let reg = /^#[\da-f]{6}$/i;
					if (reg.test(hex)) {
						rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
				      "0x" + hex.slice(3, 5)
				    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
					}
					return rgbaColor;
				}
				// 基于准备好的dom，初始化echarts实例
				this.myChart = echarts.init(document.getElementById('chartWeek'));

				// 指定图表的配置项和数据
				var option = {
					// backgroundColor: bgColor,
					color: color,
					legend: {
						right: 10,
						top: 10
					},
					tooltip: {
						trigger: "axis",
						formatter: function(params) {
							let html = '';
							params.forEach(v => {
								// console.log(v);
								html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                ${v.seriesName}.${v.name}
                <span style="color:${color[v.componentIndex]};font-size: 18px">${v.value}</span>
                个`;
							})
							return html
						},
						extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
						axisPointer: {
							type: 'shadow',
							shadowStyle: {
								color: '#ffffff',
								shadowColor: 'rgba(225,225,225,1)',
								shadowBlur: 5
							}
						}
					},
					grid: {
						top: '25%',
						left: '5%',
						right: '5%',
						bottom: '8%',
						containLabel: true
					},
					xAxis: [{
						type: "category",
						boundaryGap: false,
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: "#333"
							}
						},
						axisLine: {
							lineStyle: {
								color: "#D9D9D9"
							}
						},
						data: xAxisData
					}],
					yAxis: [{
						type: "value",
						name: '近七天数据',
						axisLabel: {
							textStyle: {
								color: "#666"
							}
						},
						nameTextStyle: {
							color: "#7D86B3",
							fontSize: 12,
							lineHeight: 40
						},
						splitLine: {
							lineStyle: {
								type: "dashed",
								color: "#E9E9E9"
							}
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						}
					}],
					series: [{
						name: "已稽查",
						type: "line",
						smooth: true,
						// showSymbol: false,/
						symbolSize: 8,
						zlevel: 3,
						lineStyle: {
								color: color[0],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[0], 0.5),
								shadowOffsetY: 8
						},
						areaStyle: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[0], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[0], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[0], 0.1),
								shadowBlur: 10
						},
						data: yAxisData1
					}, {
						name: "已上报",
						type: "line",
						smooth: true,
						// showSymbol: false,
						symbolSize: 8,
						zlevel: 3,
						lineStyle: {
								color: color[1],
								shadowBlur: 3,
								shadowColor: hexToRgba(color[1], 0.5),
								shadowOffsetY: 8
						},
						areaStyle: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: hexToRgba(color[1], 0.3)
										},
										{
											offset: 1,
											color: hexToRgba(color[1], 0.1)
										}
									],
									false
								),
								shadowColor: hexToRgba(color[1], 0.1),
								shadowBlur: 10
						},
						data: yAxisData2
					}]
				};

				// 使用刚指定的配置项和数据显示图表。
				this.myChart.setOption(option);
			},
		},
		mounted() {
			// 数量统计
			this.getHomeCount();
			this.getWeekChart();
		},
		beforeUnmount(){
			if (this.myChart) {
				this.myChart.dispose();
			}
		}
	}
</script>

<style scoped>
	@import url("../font/font.css");

	.item-card {
		width: 31%;
		height: 6.5vw;
		background: #FFFFFF;
		border-radius: 14px;
		box-sizing: border-box;
		padding: 30px;
		display: flex;
		align-items: center;
	}

	.item-card-ry {
		width: 47.5%;
		height: 14.5vw;
		background: url(/img/jzcd/bg1.png) #FFFFFF;
		border-radius: 14px;
		display: flex;
		align-items: center;
	}

	.item-card-ry>div>img {
		width: 92px;
		height: 92px;
	}

	.item-chart {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		border-radius: 14px;
	}

	.item-text {
		color: #7D86B3;
		font-size: 14px;
	}

	.itme-num {
		font-family: "zitifs";
		font-weight: bold;
		color: #000000;
		font-size: 22px;
		margin-top: 10px;
	}

	.item-card>img {
		max-width: 64px;
		max-height: 64px;
	}

	.user-zc {
		color: #CCCCCC;
		font-size: 14px;
	}

	.x-div {
		width: 100% !important;
	}

	.endTime {
		background: url(/img/jzcd/bgEnd.png) #576CD9 no-repeat;
		background-size: auto 100%;
	}
</style>
