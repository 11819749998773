<template>
	数据管理
</template>
 
<script>
	export default {
		data(){
			return {
				
			}
		},
		methods:{
			
		}
	}
</script>
 
<style scoped>
</style>
