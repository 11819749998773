<template>
	<div class="login-container">
		<div class="login-left">
			<div class="left-title">
				<!-- <img src="../../dist/favicon.ico" > -->
				<div style="width: 42px;height: 42px;background: rgba(212, 217, 237, 0.4);border-radius: 50%;position: relative;margin-right: 10px;">
					<div style="width: 26px;height: 26px;background: #D4D9ED;border-radius: 50%;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);"></div>
				</div>
				<p class="title-p">禁种铲毒辅助上报平台</p>
				
			</div>
			<img :src="`${publicPath}img/login-dp.png`" style="width: 780px;margin-top: 20px;">
		</div>
		<div class="login-right">
			<el-form ref="form" :model="form">
				<el-form-item>
					<div slot="label" class="input-leb">
						<el-icon :size="30" color="#576CD9" class="sizz">
						    <user></user>
						</el-icon>
						<p>用户名</p>
					</div>
				  <el-input v-model="form.username" placeholder="请输入用户名" class="input-a" clearable ></el-input>
				</el-form-item>
				
				<el-form-item style="margin-top: 42px;">
					<div slot="label" class="input-leb">
						<el-icon :size="30" color="#576CD9" class="sizz">
						    <Lock></Lock>
						</el-icon>
						<p>密码</p>
					</div>
				  <el-input v-model="form.password" placeholder="请输入密码" class="input-a" show-password clearable ></el-input>
				</el-form-item>
				<div class="login-btn" @click="login()">
					登录
				</div>
				<p style="text-align: center;font-size: 12px;color: #F4F6F5;">山东智诚大数据服务有限公司,山东智诚,智诚,智慧城市,智慧城管,大数据,禁毒,罂粟,政府,无人机,识别,城管</p>
			</el-form>
		</div>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	import { ElMessage , ElLoading} from 'element-plus'
	export default {
		data(){
			return {
				publicPath: process.env.BASE_URL,
				form:{
					username:'',
					password:''
				}
			}
		},
		methods:{
			login(){
				if (!this.form.username) {
					ElMessage.error('请填写账号密码');
					return
				}
				const loading = ElLoading.service({
				        lock: true,
				        text: '登录中...',
				        background: 'rgba(0, 0, 0, 0.7)',
				      })
				this.$axios.post('/user/login',{
					username:this.form.username,
					password:md5(this.form.password)
				})
				        .then(res => {
							loading.close();
							if (res.data.code != 666) {
								 ElMessage.error(res.data.msg);
								 return
							}
							sessionStorage.setItem("type",res.data.data[0].type);
							sessionStorage.setItem("userId",res.data.data[0].userId);
							sessionStorage.setItem("deptId",res.data.data[0].deptId);
							sessionStorage.setItem("deptName",res.data.data[0].deptName);
							sessionStorage.setItem("username",res.data.data[0].username);
							sessionStorage.setItem("usergps",res.data.data[0].gps);
							if (res.data.data[0].link_dept) {
								sessionStorage.setItem("link_dept",res.data.data[0].link_dept);
							}
							// 清空登录页按键绑定
							window.removeEventListener('keydown',this.keyDown);
							this.$router.push('./home');
				        })
				        .catch(error => {
							loading.close();
							ElMessage.error('服务器繁忙');
							console.error('error:', error)
				        })
				
			},
			 keyDown(e){
			        //如果是回车则执行登录方法
			      if(e.keyCode == 13){
			        this.login();
			      }
			    }

		},
		mounted() {
			//绑定事件
			 window.addEventListener('keydown',this.keyDown);
		}
	}
</script>

<style>
	@import url("../font/font.css");
	.login-container{
		width: 100%;
		height: 100%;
		background-color: #42B983;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		background: url(../../public/img/login-bg.jpg);
	}
	.login-right{
		width: 520px;
		background: #F4F6F5;
		box-shadow: -2px 5px 16px 0px #6F9BE4;
		border-radius: 14px;
		padding: 60px;
		box-sizing: border-box;
	}
	.login-left{
		width: 820px;
		height: 600px;
	}
	.left-title{
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.title-p{
		font-family: "zitifs";
		font-size: 30px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 15px;
		letter-spacing: 10px;
	}
	.sizz{
		font-size: 35px !important;
	}
	.input-leb{
		width: 100%;
		display: flex;
		height: 50px;
	}
	.input-leb>p{
		font-size: 20px;
		font-weight: 400;
		color: #333232;
		margin-left: 24px;
	}
	.input-a > input{
		background: #DFE8F2;
		border-radius: 14px;
		height: 45px;
	}
	.login-btn{
		width: 100%;
		height: 45px;
		background: #576CD9;
		border-radius: 14px;
		line-height: 45px;
		margin-top: 50px;
		color: #FFFFFF;
		letter-spacing: 8px;
		font-size: 18px;
		cursor: pointer;
	}
</style>
