<template>
 <div class="main-sj">
 	<div id="mapDiv1"></div>
 </div>	
 <!-- 查看客户 -->
 <el-drawer :lock-scroll="false" v-model="drawerkh" size='84%' @close="resetFormkh" :title="drawerTitle" @opened="drawerOpen">
 	<div class="drawer-div">
 		<div class="drawer-left" style="margin-right: 15px;">
 			<!-- 天地图 -->
 			<div id="mapDiv"></div>
 			<!-- 十字 -->
 			<div class="mapSz" style="position: absolute !important;">
 				<el-icon>
 					<plus />
 				</el-icon>
 			</div>
 		</div>
 		<div class="drawer-right">
 		 <div style="width: 100%;height: 100%;overflow-y: auto;">
 			<el-divider content-position="left">位置信息</el-divider>
 			<el-form ref="khForm" :model="khForm" label-width="80px">
 				<el-form-item label="基础位置">
 					<el-input v-model="khForm.jcwz" disabled  placeholder="选择第一张图片后自动获取"></el-input>
 				</el-form-item>
 
 				<el-form-item label="坐标信息" >
 					<el-input v-model="khForm.zbxx" :disabled="isLook"></el-input>
 				</el-form-item>
 
 				<el-form-item label="是否确认">
 					<el-radio-group v-model="khForm.sfqr" style="width: 100%;text-align: left;" :disabled="isLook">
 						<el-radio :label="0" border>确认</el-radio>
 						<el-radio :label="1" border>疑似</el-radio>
 						<el-radio :label="2" border>未检出</el-radio>
 					</el-radio-group>
 				</el-form-item>
 
 				<el-form-item label="数量等级">
 					<el-select v-model="khForm.sldj" style="width: 100%;" :disabled="isLook">
 						<el-option label="1~50" value="1~50"></el-option>
 						<el-option label="50~99" value="50~99"></el-option>
 						<el-option label="100+" value="100+"></el-option>
 					</el-select>
 				</el-form-item>
 
 				<el-form-item label="关联任务" >
 					<el-select :disabled="isLook" filterable  v-model="khForm.glrw" style="width: 100%;">
 						<el-option :label="item.taskName"  :value="item.taskId" v-for="(item,index) in glrwList"></el-option>
 					</el-select>
 				</el-form-item>
 
 				<!-- <el-form-item label="输入密码" prop="password">
 					<el-input v-model="khForm.password"></el-input>
 				</el-form-item> -->
 			</el-form>
			<el-image-viewer
					 v-if="showViewer"
					 @close="closeViewer(0)"
					 :initial-index="viewerIndex"
					 :url-list="imgList">
			</el-image-viewer>
 			<el-divider content-position="left">影像信息</el-divider>
 			<div style="width: 100%;display: flex;justify-content: space-between;height: 8vw;">
 				<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
 				>
 					<div class="imgUpEidt" v-if="imageData.url"
 						>
 						 <el-icon @click="showViewer = true;viewerIndex=0"><View /></el-icon>
 					</div>
 					<img v-if="imageData.thumbFileUrl" :src="imageData.thumbFileUrl" style="width: 100%;height: 100%;" />
 					
 						<div class="avatar-uploader-icon" v-if="(isLook && isGetImg)">
 							<el-icon class="is-loading ">
 							    <loading />
 							</el-icon>
 						</div>
 				</div>
 				
 				<div class="yxxx-img" style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
 				>
 					<div class="imgUpEidt" v-if="imageData1.url"
 						>
 						 <el-icon @click="showViewer = true;viewerIndex=1"><View /></el-icon>
 					</div>
 					<img v-if="imageData1.thumbFileUrl" :src="imageData1.thumbFileUrl" style="width: 100%;height: 100%;" />
 					
 						<div class="avatar-uploader-icon" v-if="(isLook && isGetImg)">
 							<el-icon class="is-loading ">
 							    <loading />
 							</el-icon>
 						</div>
 				</div>
 				
 				<div class="yxxx-img" style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
 				>
 					<div class="imgUpEidt" v-if="imageData2.url"
 						>
 						 <el-icon @click="showViewer = true;viewerIndex=2"><View /></el-icon>
 					</div>
 					<img v-if="imageData2.thumbFileUrl" :src="imageData2.thumbFileUrl" style="width: 100%;height: 100%;" />
 					
 						<div class="avatar-uploader-icon" v-if="(isEdit && isGetImg) || (isLook && isGetImg)">
 							<el-icon class="is-loading ">
 							    <loading />
 							</el-icon>
 						</div>
 				</div>
 				
 				<!-- 查看图片 -->
 				<el-dialog v-model="dialogVisible" width="60%" top="5vh">
 				    <img width="100%" :src="dialogUrl" alt=""  style="width: 100%;"/>
 				</el-dialog>
 				<!-- 编辑弹窗 -->
 			</div>
 			<el-divider content-position="left" v-if="isLook ">稽查信息</el-divider>
 			<el-form ref="jcForm" :model="jcForm" label-width="80px" v-if="isLook">
 				<el-form-item label="稽查状态">
 				      <el-radio-group v-model="jcForm.checkState" disabled>
 						  <el-radio :label="0" border>未检查</el-radio>
 				        <el-radio :label="1" border>已确认</el-radio>
 				        <el-radio :label="2" border>未确认</el-radio>
 				      </el-radio-group>
 				</el-form-item>
 				<el-form-item label="稽查数量">
 				      <el-input v-model="jcForm.checkLevel" disabled></el-input>
 				</el-form-item>
				<el-image-viewer
					 v-if="showViewerJc"
					 @close="closeViewer(1)"
					 :initial-index="viewerIndexJc"
					 :url-list="imgListJc">
				</el-image-viewer>
 				<el-divider content-position="left">稽查影像</el-divider>
 				<div style="width: 100%;display: flex;justify-content: space-between;height: 8vw;margin-bottom: 15px;">
 					<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
 					>
 						<div class="imgUpEidt" v-show="jcxxImg[0]">
 							 <el-icon @click="showViewerJc = true;viewerIndexJc=0"><View /></el-icon>
 						</div>
 						<img v-show="jcxxImg[0]" :src="jcxxImg[0]" style="width: 100%;height: 100%;" />
 							<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
 								<el-icon class="is-loading ">
 								    <loading />
 								</el-icon>
 							</div>
 					</div>
 					
 					<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
 					>
 						<div class="imgUpEidt" v-show="jcxxImg[1]">
 							 <el-icon @click="showViewerJc = true;viewerIndexJc=1"><View /></el-icon>
 						</div>
 						<img v-show="jcxxImg[1]" :src="jcxxImg[1]" style="width: 100%;height: 100%;" />
 							<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
 								<el-icon class="is-loading ">
 								    <loading />
 								</el-icon>
 							</div>
 					</div>
 					
 					<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
 					>
 						<div class="imgUpEidt" v-show="jcxxImg[2]">
 							 <el-icon @click="showViewerJc = true;viewerIndexJc=2"><View /></el-icon>
 						</div>
 						<img v-show="jcxxImg[2]" :src="jcxxImg[2]" style="width: 100%;height: 100%;" />
 							<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
 								<el-icon class="is-loading ">
 								    <loading />
 								</el-icon>
 							</div>
 					</div>
 					
 				</div>
 				<el-form-item label="稽查人员">
 				      <el-input v-model="jcForm.checkUserName" disabled></el-input>
 				</el-form-item>
 				<el-form-item label="备注">
 				      <el-input v-model="jcForm.checkNode" disabled></el-input>
 				</el-form-item>
 			</el-form>
 			
 		</div>
 	 </div>
 		<!-- <label class="el-form-item__label lebel-w">单位地址</label> -->
 	</div>
 </el-drawer>
</template>
 
<script>
	export default {
		data(){
			return {
				showViewer:false,
				viewerIndex:0,
				dialogVisible:false,
				imgList:[],
				showViewerJc:false,
				imgListJc:[],
				viewerIndexJc:0,
				dialogUrl:'',
				isEdit:false,
				map1:'',
				map:'',
				glrwList:[],
				jcxxImg:[],
				jcForm:{
					checkLevel: "",
					checkNode: "",
					checkState: 0,
					checkTime:"",
					checkUserId: "",
					checkUserName: ""
				},
				khForm: {
					jcwz: '',
					zbxx: '',
					sfqr: 0,
					sldj: '1~50',
					glrw: '',
					glrwName:''
				},
				isLook:false,
				drawerTitle:'查看任务',
				drawerkh:false,
				imageData: {
					url: ''
				},
				imageData1: {
					url: ''
				},
				imageData2: {
					url: ''
				},
				isGetImg:false
			}
		},
		methods:{
			closeViewer(num){
				if (num == 0) {
					this.showViewer = false;
				}else{
					this.showViewerJc = false;
				}
				
			},
			resetFormkh(formName) {
				this.khForm = {
					jcwz: '',
					zbxx: '',
					sfqr: 0,
					sldj: '1~50',
					glrw: '',
				};
				this.imageData = {
					url: ''
				};
				this.imageData1= {
					url: ''
				};
				this.imageData2 = {
					url: ''
				};
				this.showViewer = false;
				this.imgList = [];
				this.viewerIndex = 0;
				
				this.showViewerJc = false;
				this.imgListJc = [];
				this.viewerIndexJc = 0;
			},
			initMap() {
				var imageURL = "http://t0.tianditu.gov.cn/img_w/wmts?" +
					"SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
					"&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1c0b123b51edfa0425d320598f59c192";
				var textURl =
					"https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles&tk=1c0b123b51edfa0425d320598f59c192";
				//创建自定义图层对象
				var lay = new T.TileLayer(imageURL, {
					minZoom: 1,
					maxZoom: 18
				});
				var textLay = new T.TileLayer(textURl, {
					minZoom: 1,
					maxZoom: 18
				});
				var config = {
					layers: [lay, textLay]
				};
				//初始化地图对象
				this.map1 = new T.Map('mapDiv1', config);
				if (sessionStorage.getItem('usergps')) {
					var usergps = sessionStorage.getItem('usergps').split(',');
					this.map1.centerAndZoom(new T.LngLat(usergps[0], usergps[1]), 15);
				}else{
					this.map1.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
				}
				//允许鼠标滚轮缩放地图
				this.map1.enableScrollWheelZoom();
				this.getKhList();
			},
			getKhList(state) {
				// 任务列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem('type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						link_dept: khlink_dept,
						dyzt:6
					})
					.then(res => {
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						var data = res.data.data;
						for (var i = 0; i < data.length; i++) {
							this.addMaker(data[i]);
						}
					})
					.catch(error => {
						this.tableLoading = false;
						console.error('error:', error)
					})
			},
			addMaker(data){
				var nGps = '';
				if (data.gps) {
					nGps = data.gps.split(',');
				}else{
					return
				}
				//创建图片对象
				var icon ;
				if (data.over == 0) {
					icon = new T.Icon({
						iconUrl: "/img/map/markerA.png",
						iconSize: new T.Point(40, 46),
						iconAnchor: new T.Point(10, 25)
					});
				}else{
					icon = new T.Icon({
						iconUrl: "/img/map/markerB.png",
						iconSize: new T.Point(40, 46),
						iconAnchor: new T.Point(10, 25)
					});
				}
				//向地图上添加自定义标注
				var marker = new T.Marker(new T.LngLat(nGps[0], nGps[1]), {icon: icon});
				this.map1.addOverLay(marker);
				marker.addEventListener("click",(e)=> {
					this.userLook(data)
				})
			},
			userLook(row) {
				// 清空稽查信息
				this.jcxxImg = [];
				this.jcForm = {
					checkLevel: "",
					checkNode: "",
					checkState: 0,
					checkTime: '',
					checkUserId: "",
					checkUserName: ""
				};
				this.khForm.jcwz = row.address;
				this.khForm.zbxx = row.gps;
				this.khForm.sfqr = row.state;
				this.khForm.sldj = row.level;
				this.khForm.glrw = row.taskId;
				this.drawerTitle = "查看任务--"+row.dybh;
				this.glrwList = [
					{
						taskId: row.taskId,
						taskName: row.taskName
					}
				]
				// 获取图片
				this.getImgBase(row.dybh,true);
				this.$nextTick(() => {
					this.isLook = true;
				})
				this.drawerkh = true;
				// 稽查信息
				this.jcForm = row;
			},
			getImgBase(dybh,isLook){
				this.isGetImg  = true;
				this.$axios.post('/papaver/file', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						dybh: dybh,
					})
					.then(res => {
						this.isGetImg  = false;
						var data = res.data.data;
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						// 稽查信息
						var jcdd = res.data.jcData;
						for (var i = 0; i < jcdd.length; i++) {
							this.imgListJc.push(jcdd[i].url);
							this.jcxxImg.push(jcdd[i].thumbUrl);
						}
						// 查看图片
						for (var i = 0; i < data.length; i++) {
							this.imgList.push(data[i].sbUrl)
						}
						this.getImgData = data;
						if (data[0]) {
							if (data[0].sbUrl) {
								this.imageData.thumbFileUrl = data[0].sbUrl;
							}else{
								this.imageData.thumbFileUrl = data[0].thumbUrl;
							}
							if (isLook) {
								this.imageData.url = data[0].sbUrl;
							}else{
								this.imageData.url = data[0].url;
							}
						}
						if (data[1]) {
							if (data[1].sbUrl) {
								this.imageData1.thumbFileUrl = data[1].sbUrl;
							}else{
								this.imageData1.thumbFileUrl = data[1].thumbUrl;
							}
							
							if (isLook) {
								this.imageData1.url = data[1].sbUrl;
							}else{
								this.imageData1.url = data[1].url;
							}
						}
						if (data[2]) {
							if (data[2].sbUrl) {
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							}else{
								this.imageData2.thumbFileUrl = data[2].thumbUrl;
							}
							
							if (isLook) {
								this.imageData2.url = data[2].sbUrl;
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							}else{
								this.imageData2.url = data[2].url;
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							}
						}
					})
					.catch(error => {
						this.isGetImg  = false;
						console.error('error:', error)
					})
			},
			drawerOpen() {
				if (this.map != '') {
					if (this.khForm.zbxx) {
						var centerSps = this.khForm.zbxx.split(",");
						this.map.centerAndZoom(new T.LngLat(centerSps[0], centerSps[1]), 18);
					}else{
						this.map.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
					}
					return
				}
				var imageURL = "http://t0.tianditu.gov.cn/img_w/wmts?" +
					"SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
					"&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1c0b123b51edfa0425d320598f59c192";
				var textURl =
					"https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles&tk=1c0b123b51edfa0425d320598f59c192";
				//创建自定义图层对象
				var lay = new T.TileLayer(imageURL, {
					minZoom: 1,
					maxZoom: 18
				});
				var textLay = new T.TileLayer(textURl, {
					minZoom: 1,
					maxZoom: 18
				});
				var config = {
					layers: [lay, textLay]
				};
				//初始化地图对象
				this.map = new T.Map('mapDiv', config);
				// 
				if (this.khForm.zbxx) {
					var centerSps = this.khForm.zbxx.split(",");
					this.map.centerAndZoom(new T.LngLat(centerSps[0], centerSps[1]), 18);
				}else{
					this.map.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
				}
				//允许鼠标滚轮缩放地图
				this.map.enableScrollWheelZoom();
				// 拖拽监听
				this.map.addEventListener("moveend", (e) => {
					this.khForm.zbxx = e.target.getCenter().getLng() + "," + e.target.getCenter().getLat();
				});
			}
		},
		mounted() {
			this.initMap();
		}
	}
</script>
 
<style scoped>
	.main-sj{
		width: 100%;
		height: 100%;
		background-color: rgb(255, 255, 255);
		border-radius: 14px;
		box-sizing: border-box;
		padding: 15px;
	}
	#mapDiv1{
		width: 100%;
		height: 100%;
	}
	.drawer-div {
		width: 100%;
		height: calc(100% - 50px);
		/* height: 850px; */
		box-sizing: border-box;
		padding: 10px;
		display: flex;
	}
	.drawer-left {
		flex: 1;
		position: relative;
	}
	
	.mapSz {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		font-size: 60px;
		color: #67C23A;
	}
	
	.drawer-right {
		width: 40%;
		overflow-y: auto;
	}
	
	#mapDiv {
		width: 100%;
		height: 100%;
	}
</style>
