<template>
	<div class="home">
		<el-container style="height: 100%;">
			<el-aside width="200px" style="background: #2F49D7;">

				<div class="logo-d"></div>

				<el-menu
				        active-text-color="#fff"
				        background-color="#2F49D1"
				        class="el-menu-vertical-demo"
				        default-active="jzcd"
				        text-color="#fff"
						:router="true"
				      >
					  <!-- <el-menu-item index="13">
					    <img src="../../public/img/jzcd-s.svg" width="32" onload="SVGInject(this)">
					    <span>数据管理</span>
					  </el-menu-item> -->
				        <el-menu-item :index="item.href" v-for="(item,index) in meanData" :key="index" @click="menuCliek(item)">
				          <img :src="item.icon" width="32" onload="SVGInject(this)">
				          <span>{{item.title}}</span>
				        </el-menu-item>
				        <!-- <el-menu-item index="2">
				          <img src="../../public/img/sjsc.png" width="32">
				          <span>数据上传</span>
				        </el-menu-item>
						<el-menu-item index="3">
						  <img src="../../public/img/sjgl.png" width="32">
						  <span>数据管理</span>
						</el-menu-item>
						<el-menu-item index="4">
						  <img src="../../public/img/khgl.png" width="32">
						  <span>客户管理</span>
						</el-menu-item>
						<el-menu-item index="5">
						  <img src="../../public/img/rwgl.png" width="32">
						  <span>任务管理</span>
						</el-menu-item>
						<el-menu-item index="6">
						  <img src="../../public/img/sjtj.png" width="32">
						  <span>数据统计</span>
						</el-menu-item>
						<el-menu-item index="7">
						  <img src="../../public/img/sjksh.png" width="32">
						  <span>数据可视化</span>
						</el-menu-item> -->
						
				      </el-menu>


			</el-aside>
			<el-container class="w-container">
				<el-header height="100px">
					<div class="header-title">
						<div>
							<p class="header-text">{{titleText}}</p>
							<p class="header-time">{{dateTime}}</p>
						</div>
						<div style="display: flex;width: 350px;justify-content: space-evenly;flex-wrap: wrap;"
						v-if="titleText == '欢迎使用禁种铲毒辅助上报平台'"
						>
							<span>{{weathData.city}}</span>
							<span><img :src="weathData.img1" width="25"></span>
							<span>{{weathData.type}}</span>
							<span v-if="weathData.low">
								{{weathData.low}} ~ {{weathData.high}}
							</span>
							<p style="width: 100%;font-size: 10px;">{{weathData.ganmao}}</p>
						</div>
						<el-popover
						v-if="titleText != '欢迎使用禁种铲毒辅助上报平台'"
						    placement="bottom"
						    :width="210"
						    trigger="hover"
							:offset="-1"
							:show-arrow="false"
						  >
						    <template #reference>
						      <div class="user-icon">
						      	<el-avatar :size="45" :src="userUrl"></el-avatar>
						      	<p>{{username}}</p>
						      	<el-icon><caret-left /></el-icon>
						      </div>
						    </template>
							<ul style="width: 100%;list-style: none;">
								<li>
									<div class="userEdi">
										<el-icon :size="20">
										    <edit />
										</el-icon>
										<el-button type="text" @click="changePassword" style="color: #000000;">修改密码</el-button>
									</div>
									
									<div class="userEdi" >
										<el-icon :size="20"> <switch-button /></el-icon>
										<el-button type="text" @click="tologin" style="color: #000000;">退出登录</el-button>
									</div>
								</li>
							</ul>
						  </el-popover>
						  
						
					</div>
				</el-header>
				<!-- 二维码 -->
				<div style="position: fixed;right: 16px;bottom: 16px;z-index: 99;" v-if="titleText == '欢迎使用禁种铲毒辅助上报平台'">
					
					<el-popover
					    placement="left-start"
					    :width="210"
					    trigger="hover"
						:offset="-1"
						:show-arrow="false"
					  >
					    <template #reference>
					      <div class="user-icon" style="width: 40px;;border: 1px solid #cccccc;height: 40px;">
					      	<img src="/img/jzcd/sysm.svg" height="25">
					      </div>
					    </template>
						<ul style="width: 100%;list-style: none;">
							<li style="text-align: center;" v-if="usertype == 2">
								<a href="/page/customer.html" target="_blank" style="text-decoration: none;">使用文档点这里</a>
							</li>
							<li style="text-align: center;" v-else-if="usertype == 3">
								<a href="/page/flight.html" target="_blank" style="text-decoration: none;">使用文档点这里</a>
							</li>
							<li style="text-align: center;" v-else>
								<a href="/page/admin.html" target="_blank" style="text-decoration: none;">使用文档点这里</a>
							</li>
						</ul>
					  </el-popover>
					  
					<el-popover
					    placement="left-start"
					    :width="210"
					    trigger="hover"
						:offset="-1"
						:show-arrow="false"
					  >
					    <template #reference>
					      <div class="user-icon" style="width: 40px;;border: 1px solid #cccccc;height: 40px;margin-top: 10px;">
					      	<img src="/img/jzcd/telephone.svg" height="25">
					      </div>
					    </template>
						<ul style="width: 100%;list-style: none;">
							<li style="text-align: center;">
								<span>技术支持：胡工 176-8640-3445 </span>
							</li>
							<li style="text-align: center;">
								<span>技术支持：牛工 131-8173-9260 </span>
							</li>
						</ul>
					  </el-popover>
					  
					  <el-popover
					      placement="left-start"
					      :width="210"
					      trigger="hover"
					  	:offset="-1"
					  	:show-arrow="false"
					    >
					      <template #reference>
					        <div class="user-icon" style="width: 40px;;border: 1px solid #cccccc;height: 40px;margin-top: 10px;">
					        	<img src="/img/jzcd/ewm-icon.svg" height="25">
					        </div>
					      </template>
					  	<ul style="width: 100%;list-style: none;">
					  		<li style="text-align: center;">
					  			<img src="/img/jzcd/wxew.svg" width="150">
					  		</li>
					  		<li style="text-align: center;">
					  			稽查人员请使用<br>微信扫一扫进入小程序
					  		</li>
					  	</ul>
					    </el-popover>
						
				</div>
				<el-main>
					<router-view/>
				</el-main>
			</el-container>
		</el-container>
	</div>
	<!-- 修改密码弹窗 -->
	<el-dialog v-model="dialogFormVisible" title="修改密码" :show-close="false">
	
		<el-form ref="PasswordForm" :model="PasswordForm" :rules="Passwordrules" label-width="120px"
			class="demo-ruleForm">
			<el-form-item label="新密码" prop="old">
				<el-input v-model="PasswordForm.old" type="password" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="new">
				<el-input v-model="PasswordForm.new" type="password" autocomplete="off"></el-input>
			</el-form-item>
		</el-form>
	
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取消</el-button>
				<el-button type="primary" @click="passwordBtn('PasswordForm')">确定</el-button>
			</span>
		</template>
	</el-dialog>
</template>
<script>
	// @ is an alias to /src
import SVGInjec from '@iconfu/svg-inject'
import md5 from 'js-md5'
import moment from 'moment'; //导入模块
import {
		ElMessage,
		ElLoading
	} from 'element-plus'
	export default {
		data() {
			const validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入新密码'))
				} else {
					callback()
				}
			}
			const validatePass3 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入新密码'))
				} else if (value !== this.PasswordForm.old) {
					callback(new Error("两次输入的密码不一致"))
				} else {
					callback()
				}
			}
			return {
				publicPath: process.env.BASE_URL,
				userUrl:'/img/userICon.jpg',
				meanData:[],
				titleText:'欢迎使用禁种铲毒辅助上报平台',
				dateTime:'',
				dialogFormVisible:false,
				PasswordForm:{
					old:'',
					new:''
				},
				username:sessionStorage.getItem('username'),
				usertype:sessionStorage.getItem('type'),
				// 密码修改验证
				Passwordrules: {
					old: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					new: [{
						validator: validatePass3,
						trigger: 'blur'
					}],
				},
				weathData:{
					city: "",
					cityid:"",
					img1: "",
					img2: "",
					ptime: "",
					temp1: "",
					temp2: "",
					weather: ""
				}
			}
		},
		methods: {
			changePassword(){
				this.dialogFormVisible = true;
			},
			passwordBtn(formName) {
				console.log(formName);
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/system/updatePassword', {
								userId: sessionStorage.getItem('userId'),
								type: sessionStorage.getItem('type'),
								newPassword:md5(this.PasswordForm.old),
								confirmPassword:md5(this.PasswordForm.new)
							})
							.then(res => {
								// console.log(res);
					 		if (res.data.code != 666) {
									ElMessage.error(res.data.msg);
									return
								}
								
								// ElMessage.success(res.data.msg);
								this.$message.success(res.data.msg);
								this.dialogFormVisible = false;
							})
							.catch(error => {
								 console.error('error:', error)
							})
					} else {
						console.error('error submit!!')
						return false
					}
				})
			
			},
			menuCliek(item){
				if (item.title == '禁种铲毒') {
					this.titleText = '欢迎使用禁种铲毒辅助上报平台';
				}else{
					this.titleText = item.title
				}
			},
			vsss(){
				this.$router.push('/home/jzcd');
			},
			getNowDate(){
				this.dateTime = moment().format('YYYY-MM-DD');
			},
			backLogin(){
				if (sessionStorage.getItem('userId')&& sessionStorage.getItem('deptId')) {
					// 刷新默认第一个
					this.$router.push('/home/jzcd');
				}else{
					this.$router.push('/');
				}
			},
			tologin(){
				sessionStorage.clear();
				this.$router.push('/');
			},
			getWeath(){
				this.$axios.get('/system/wather')
					.then(res => {
						if (res.data.code != 200) {
							 ElMessage.error(res.data.msg);
							 return
						}
						var data = res.data.data;
						this.weathData = data;
					})
					.catch(error => {
						 // console.error('error:', error)
					})
			}
		},
		mounted() {
			// 判断是否登陆
			this.backLogin();
			// 获取时间
			this.getNowDate();
			// 获取天气
			this.getWeath();
			this.$axios.post('/home/menu',{
				userId:sessionStorage.getItem('userId'),
				type:sessionStorage.getItem('type')
			})
				.then(res => {
					if (res.data.code != 666) {
						 ElMessage.error(res.data.msg);
						 return
					}
					var data = res.data.data;
					this.meanData = data;
				})
				.catch(error => {
					 console.error('error:', error)
				})
				
		}
	}
	
	// 地图创建
	var map = new AMap.Map('container', {
		zoom: 11, //级别
		center: [116.397428, 39.90923], //中心点坐标
		viewMode: '3D' //使用3D视图
	});
</script>
<style>
	@import url("../font/font.css");
	.home {
		height: 100%;
		width: 100%;
	}

	.logo-d {
		width: 64px;
		height: 64px;
		margin: 20px auto;
		background: url(/img/logo/LOGO.svg);
		border-radius: 12px;
	}
	.el-menu-item.is-active{
		background: #5269E5;
	}
	.el-menu-vertical-demo{
		border: 0 !important;
	}
	.el-menu-vertical-demo .el-menu-item img{
		margin-right: 10px;
	}
	.header-title{
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-between;
	}
	.user-icon{
		width: 240px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.header-text{
		font-family: "zitifs";
		color: #333232;
		font-size: 18px;
	}
	.header-time{
		color: #807E7E;
		font-size: 14px;
		margin-top: 10px;
	}
	.w-container{
		background: url(../../public/img/index-bg.png) no-repeat;
		background-size: cover;
	}
	.is-active path{
		fill: #fff;
	}
	.el-main{
		padding-top: 0 !important;
		padding-bottom: 10px !important;
	}
	.el-drawer__body{
		padding: 0 !important;
		overflow-y: auto;
	}
	.el-drawer__header{
		text-align: left;
		border-bottom: 1px solid #f2f4f5;
		padding-bottom: 20px !important;
		margin-bottom: 20px !important;
	}
	.userEdi{
		width: 100%;
		height: 100%;
		display: flex;
		box-sizing: border-box;
		padding-right: 20px;
		justify-content: space-evenly;
		align-items: center;
	}
	.el-drawer__body .el-transfer__button:nth-child(2){
		margin: 0 !important;
	}
	.el-drawer__body .el-transfer__buttons{
		padding: 0 !important;
	}
	.avatar-uploader .el-upload{
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	.avatar-uploader-icon{
	    position: absolute !important;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	.avatar-uploader{
		width: 100%;
		height: 100%;
	}
	/* 隐藏天地图logo */
	.tdt-control-container{    
	    display: none !important;
	}
	.avatar-uploader .el-upload-list--picture-card .el-upload-list__item{
		width: 100%;
		height: 100%;
	}
	.avatar-uploader .el-progress-circle{
		width: 50% !important;
		height: 100% !important;
		margin: 0 auto;
	}
	/* 上传文件 查看删除 */
	.imgUpEidt{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background: rgba(0, 0, 0, 0.3);
		font-size: 24px;
		color: #fff;
		align-items: center;
		justify-content: space-evenly;
		display: none;
	}
	.yxxx-img:hover .imgUpEidt{
		display: flex;
	}
	.yxxx-img{
		cursor: pointer;
	}
	.imgUpEidt i{
		cursor: pointer;
	}
	.ysyx .el-dialog__body{
		padding-left: 0 !important;
	}
	.uoloadParetn .el-upload--text{
		display: none;
	}
	.el-radio{
		margin-right: 1vw !important;
	}
	/* 修改默认 disabled样式*/
	.el-input.is-disabled .el-input__inner{
		color: #303133 !important;
	}
	.el-radio.is-disabled.is-bordered.is-checked{
		border-color:var(--el-color-primary) !important;
	}
	.el-radio.is-disabled.is-bordered.is-checked .el-radio__label{
		color: var(--el-color-primary) !important ;
	}
	.el-radio__input.is-disabled.is-checked .el-radio__inner{
		border-color: var(--el-color-primary) !important;
	}
	/* .el-tree-node__content{
		height: auto !important;
	} */
/* 	.el-tree-node__content{
		display: block !important; 
	}
	.el-tree-node__children{
		overflow: visible !important;
	} */
</style>

