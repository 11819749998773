import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import login from '../views/login.vue'

import jzcd from '../views/jzcd.vue'
import sjsc from '../views/sjsc.vue'
import sjgl from '../views/sjgl.vue'
import khgl from '../views/khgl.vue'
import fxry from '../views/fxry.vue'
import rwgl from '../views/rwgl.vue'
import sjtj from '../views/sjtj.vue'
import sjksh from '../views/sjksh.vue'



const routes = [
  
  {
    path: '/',
    name: 'login',
    component: login
  },{
    path: '/home',
    name: 'Home',
    component: Home,
	redirect:'/home/jzcd',
	children:[
		{
		  path: 'jzcd',
		  name: 'jzcd',
		  component: jzcd
		},
		{
		  path: 'sjsc',
		  name: 'sjsc',
		  component: sjsc
		},
		{
		  path: 'sjgl',
		  name: 'sjgl',
		  component: sjgl
		},
		{
		  path: 'khgl',
		  name: 'khgl',
		  component: khgl
		},
		{
		  path: 'fxry',
		  name: 'fxry',
		  component: fxry
		},
		{
		  path: 'rwgl',
		  name: 'rwgl',
		  component: rwgl
		},
		{
		  path: 'sjtj',
		  name: 'sjtj',
		  component: sjtj
		},
		{
		  path: 'sjksh',
		  name: 'sjksh',
		  component: sjksh
		}
	]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
