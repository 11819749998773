<template>
	<div class="main-sj">
		<div class="screen-title">
			<div class="form-item" style="margin-left: 0;">
				<p>毒源编号：</p>
				<el-input v-model="searchData.dybh" placeholder="请输入毒源编号" />
			</div>
			
			<div class="form-item">
				<p>毒源地址：</p>
				<el-input v-model="searchData.address" placeholder="请输入毒源地址" />
			</div>
			
			<div class="form-item">
				<p>核查人员：</p>
				<el-input v-model="searchData.checkUserName" placeholder="请输入核查人员" />
			</div>
			
			<div class="form-item" v-if="searchMore">
				<p>核查状态：</p>
				<el-select v-model="searchData.checkState">
					<el-option label="未检查" value="0"></el-option>
					<el-option label="已确认" value="1"></el-option>
					<el-option label="未确认" value="2"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" style="margin-left: 0;" v-if="searchMore">
				<p>毒源等级：</p>
				<el-select v-model="searchData.level">
					<el-option label="1~50" value="1~50"></el-option>
					<el-option label="50~99" value="50~99"></el-option>
					<el-option label="100+" value="100+"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" v-if="searchMore">
				<p>核查等级：</p>
				<el-select v-model="searchData.checkLevel">
					<el-option label="1~50" value="1~50"></el-option>
					<el-option label="50~99" value="50~99"></el-option>
					<el-option label="100+" value="100+"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" v-if="searchMore">
				<p>关联任务：</p>
				<el-select v-model="searchData.taskId" @change="selectGetRwPl">
					<el-option :label="item.taskName"  :value="item.taskId" v-for="(item,index) in glrwList"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" v-if="searchMore">
				<p>毒源状态：</p>
				<el-select v-model="searchData.state">
					<el-option label="确认" value="0"></el-option>
					<el-option label="疑似" value="1"></el-option>
					<el-option label="未检出" value="2"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" style="margin-left: 0;" v-if="searchMore">
				<p>任务状态：</p>
				<el-select v-model="searchData.over">
					<el-option label="进行中" value="0"></el-option>
					<el-option label="已结束" value="1"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" v-if="searchMore">
				<p>上传时间：</p>
				<el-date-picker
					v-model="searchData.upTime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="YYYY-MM-DD"
					style="flex: 1;"
				  >
				  </el-date-picker>
			</div>
			
			<div class="form-item" v-if="searchMore">
				<p>核查时间：</p>
				<el-date-picker
					v-model="searchData.checkTime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="YYYY-MM-DD"
					style="flex: 1;"
				  >
				  </el-date-picker>
			</div>
			<div class="form-item" >
				<el-button type="primary" @click="btnSub" size="small">搜索</el-button>
				<el-button type="warning" @click="resetFrom" size="small">重置</el-button>
				<el-button @click="getExcelData" size="small">导出</el-button>
				<el-button type="info" plain @click="searchMoreBtn" size="small">{{searchMoreText}}</el-button>
			</div>
			
			
		</div>
		<!-- <div class="screen-title">
			<div class="form-item" style="margin-left: 0;">
				<p>毒源等级：</p>
				<el-select v-model="searchData.level">
					<el-option label="1~50" value="1~50"></el-option>
					<el-option label="50~99" value="50~99"></el-option>
					<el-option label="100+" value="100+"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" >
				<p>核查等级：</p>
				<el-select v-model="searchData.checkLevel">
					<el-option label="1~50" value="1~50"></el-option>
					<el-option label="50~99" value="50~99"></el-option>
					<el-option label="100+" value="100+"></el-option>
				</el-select>
			</div>
			
			<div class="form-item">
				<p>关联任务：</p>
				<el-select v-model="searchData.taskId" @change="selectGetRwPl">
					<el-option :label="item.taskName"  :value="item.taskId" v-for="(item,index) in glrwList"></el-option>
				</el-select>
			</div>
			
			<div class="form-item">
				<p>毒源状态：</p>
				<el-select v-model="searchData.state">
					<el-option label="确认" value="0"></el-option>
					<el-option label="疑似" value="1"></el-option>
					<el-option label="未检出" value="2"></el-option>
				</el-select>
			</div>
			
		</div> -->
		<!-- <div class="screen-title">
			
			<div class="form-item" style="margin-left: 0;">
				<p>任务状态：</p>
				<el-select v-model="searchData.over">
					<el-option label="进行中" value="0"></el-option>
					<el-option label="已结束" value="1"></el-option>
				</el-select>
			</div>
			
			<div class="form-item" >
				<p>上传时间：</p>
				<el-date-picker
					v-model="searchData.upTime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="YYYY-MM-DD"
					style="flex: 1;"
				  >
				  </el-date-picker>
			</div>
			
			<div class="form-item">
				<p>核查时间：</p>
				<el-date-picker
					v-model="searchData.checkTime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="YYYY-MM-DD"
					style="flex: 1;"
				  >
				  </el-date-picker>
			</div>
			<div class="btn-d" style="padding-left: 10px;">
				<el-button type="primary" @click="btnSub">搜索</el-button>
				<el-button type="warning" @click="resetFrom">重置</el-button>
				<el-button @click="getExcelData">导出</el-button>
			</div>
			
		</div> -->
		
		<div class="table-d">
			<el-table :data="tableData" v-loading="tableLoading" 
			@cell-dblclick="copyDydz"
			style="width: 100%" highlight-current-row>
				<el-table-column prop="dybh" label="毒源编号" width="180" :show-overflow-tooltip="true"/>
				<el-table-column prop="address" label="毒源地址" :show-overflow-tooltip="true" width="200"/>
				<el-table-column prop="level" label="毒源等级" />
				<el-table-column prop="taskName" label="关联任务" :show-overflow-tooltip="true" />
				<el-table-column prop="upTime" label="上传时间"  :show-overflow-tooltip="true" width="110"/>
				<el-table-column prop="state" label="毒源状态" width="80">
					<template #default="scope">
						<!-- <el-button size="mini" type="success" v-if="scope.row.state == 1">正常</el-button>
						<el-button size="mini" type="warning" v-if="scope.row.state == 2">停用</el-button>
						<el-button size="mini" type="danger" v-if="scope.row.state == 3">欠费</el-button> -->
						<el-button size="mini" type="success" v-if="scope.row.state == 0">确认</el-button>
						<el-button size="mini" type="warning" v-if="scope.row.state == 1">疑似</el-button>
						<el-button size="mini" type="info" v-if="scope.row.state == 2">未检出</el-button>
			
					</template>
				</el-table-column>
				<el-table-column prop="checkUserName" label="核查人员" />
				<el-table-column prop="checkLevel" label="核查等级"/>
				<el-table-column prop="checkTime" label="核查时间" :show-overflow-tooltip="true"/>
				<el-table-column prop="state" label="核查状态" width="80">
					<template #default="scope">
						<!-- <el-button size="mini" type="success" v-if="scope.row.state == 1">正常</el-button>
						<el-button size="mini" type="warning" v-if="scope.row.state == 2">停用</el-button>
						<el-button size="mini" type="danger" v-if="scope.row.state == 3">欠费</el-button> -->
						<el-button size="mini" type="info" v-if="scope.row.checkState == 0">未检查</el-button>
						<el-button size="mini" type="success" v-if="scope.row.checkState == 1">已确认</el-button>
						<el-button size="mini" type="warning" v-if="scope.row.checkState ==2">未确认</el-button>
				
					</template>
				</el-table-column>
				<el-table-column label="操作" width="80">
					<template #default="scope">
						<div style="display: flex;">
			
							<div v-if="scope.row" @click="userLook(scope.$index, scope.row)" title="查看" class="radius-w"
								style="background-color: #8CABFF;">
								<el-icon>
									<View />
								</el-icon>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
			
			<div class="block">
				<el-pagination layout="prev, pager, next,total" :total="tableTotal" :page-size="limit"
					@current-change="changePage" v-model:current-page="tableNowPage"></el-pagination>
			</div>
			
		</div>
		
		
	</div>
	<!-- 查看 -->
	<el-drawer :lock-scroll="false" v-model="drawerkh" size='84%' @close="resetFormkh" :title="drawerTitle" @opened="drawerOpen">
		<div class="drawer-div">
			<div class="drawer-left" style="margin-right: 15px;">
				<!-- 天地图 -->
				<div id="mapDiv"></div>
				<!-- 十字 -->
				<div class="mapSz" style="position: absolute !important;">
					<el-icon>
						<plus />
					</el-icon>
				</div>
			</div>
			<div class="drawer-right">
			 <div style="width: 100%;height: 100%;overflow-y: auto;">
				<el-divider content-position="left">位置信息</el-divider>
				<el-form ref="khForm" :model="khForm" label-width="80px">
					<el-form-item label="基础位置">
						<el-input v-model="khForm.jcwz" disabled  placeholder="选择第一张图片后自动获取"></el-input>
					</el-form-item>
	
					<el-form-item label="坐标信息" >
						<el-input v-model="khForm.zbxx" :disabled="isLook"></el-input>
					</el-form-item>
	
					<el-form-item label="是否确认">
						<el-radio-group v-model="khForm.sfqr" style="width: 100%;text-align: left;" :disabled="isLook">
							<el-radio :label="0" border>确认</el-radio>
							<el-radio :label="1" border>疑似</el-radio>
							<el-radio :label="2" border>未检出</el-radio>
						</el-radio-group>
					</el-form-item>
	
					<el-form-item label="数量等级">
						<el-select v-model="khForm.sldj" style="width: 100%;" :disabled="isLook">
							<el-option label="1~50" value="1~50"></el-option>
							<el-option label="50~99" value="50~99"></el-option>
							<el-option label="100+" value="100+"></el-option>
						</el-select>
					</el-form-item>
	
					<el-form-item label="关联任务" >
						<el-select :disabled="isLook" filterable  v-model="khForm.glrw" style="width: 100%;" @change="selectGetRwPl">
							<el-option :label="item.taskName"  :value="item.taskId" v-for="(item,index) in glrwList"></el-option>
						</el-select>
					</el-form-item>
	
					<!-- <el-form-item label="输入密码" prop="password">
						<el-input v-model="khForm.password"></el-input>
					</el-form-item> -->
				</el-form>
				<el-image-viewer
						 v-if="showViewer"
						 @close="closeViewer(0)"
						 :initial-index="viewerIndex"
						 :url-list="imgList">
				</el-image-viewer>
				<el-divider content-position="left">影像信息</el-divider>
				<div style="width: 100%;display: flex;justify-content: space-between;height: 8vw;">
					<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
					>
						<div class="imgUpEidt" v-if="imageData.url"
							>
							 <el-icon @click="showViewer = true;viewerIndex=0"><View /></el-icon>
						</div>
						<img v-if="imageData.thumbFileUrl" :src="imageData.thumbFileUrl" style="width: 100%;height: 100%;" />
						
							<div class="avatar-uploader-icon" v-if="(isLook && isGetImg)">
								<el-icon class="is-loading ">
								    <loading />
								</el-icon>
							</div>
					</div>
					
					<div class="yxxx-img" style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
					>
						<div class="imgUpEidt" v-if="imageData1.url"
							>
							 <el-icon @click="showViewer = true;viewerIndex=1"><View /></el-icon>
						</div>
						<img v-if="imageData1.thumbFileUrl" :src="imageData1.thumbFileUrl" style="width: 100%;height: 100%;" />
						
							<div class="avatar-uploader-icon" v-if="(isLook && isGetImg)">
								<el-icon class="is-loading ">
								    <loading />
								</el-icon>
							</div>
					</div>
					
					<div class="yxxx-img" style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
					>
						<div class="imgUpEidt" v-if="imageData2.url"
							>
							 <el-icon @click="showViewer = true;viewerIndex=2"><View /></el-icon>
						</div>
						<img v-if="imageData2.thumbFileUrl" :src="imageData2.thumbFileUrl" style="width: 100%;height: 100%;" />
						
							<div class="avatar-uploader-icon" v-if="(isEdit && isGetImg) || (isLook && isGetImg)">
								<el-icon class="is-loading ">
								    <loading />
								</el-icon>
							</div>
					</div>
					
					<!-- 查看图片 -->
					<el-dialog v-model="dialogVisible" width="60%" top="5vh">
					    <img width="100%" :src="dialogUrl" alt=""  style="width: 100%;"/>
					</el-dialog>
					<!-- 编辑弹窗 -->
				</div>
				<el-image-viewer
						 v-if="showViewerJc"
						 @close="closeViewer(1)"
						 :initial-index="viewerIndexJc"
						 :url-list="imgListJc">
				</el-image-viewer>
				<el-divider content-position="left" v-if="isLook ">稽查信息</el-divider>
				<el-form ref="jcForm" :model="jcForm" label-width="80px" v-if="isLook">
					<el-form-item label="稽查状态">
					      <el-radio-group v-model="jcForm.checkState" disabled>
							  <el-radio :label="0" border>未检查</el-radio>
					        <el-radio :label="1" border>已确认</el-radio>
					        <el-radio :label="2" border>未确认</el-radio>
					      </el-radio-group>
					</el-form-item>
					<el-form-item label="稽查数量">
					      <el-input v-model="jcForm.checkLevel" disabled></el-input>
					</el-form-item>
					<el-divider content-position="left">稽查影像</el-divider>
					<div style="width: 100%;display: flex;justify-content: space-between;height: 8vw;margin-bottom: 15px;">
						<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
						>
							<div class="imgUpEidt" v-show="jcxxImg[0]">
								<el-icon @click="showViewerJc = true;viewerIndexJc=0"><View /></el-icon>
							</div>
							<img v-show="jcxxImg[0]" :src="jcxxImg[0]" style="width: 100%;height: 100%;" />
								<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
									<el-icon class="is-loading ">
									    <loading />
									</el-icon>
								</div>
						</div>
						
						<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
						>
							<div class="imgUpEidt" v-show="jcxxImg[1]">
								 <el-icon @click="showViewerJc = true;viewerIndexJc=1"><View /></el-icon>
							</div>
							<img v-show="jcxxImg[1]" :src="jcxxImg[1]" style="width: 100%;height: 100%;" />
								<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
									<el-icon class="is-loading ">
									    <loading />
									</el-icon>
								</div>
						</div>
						
						<div class="yxxx-img"  style="width: 31%;height: 100%;position: relative;border: 1px dashed #d9d9d9;border-radius: 6px;"
						>
							<div class="imgUpEidt" v-show="jcxxImg[2]">
								 <el-icon @click="showViewerJc = true;viewerIndexJc=2"><View /></el-icon>
							</div>
							<img v-show="jcxxImg[2]" :src="jcxxImg[2]" style="width: 100%;height: 100%;" />
								<div class="avatar-uploader-icon" v-show="(isEdit && isGetImg) || (isLook && isGetImg)">
									<el-icon class="is-loading ">
									    <loading />
									</el-icon>
								</div>
						</div>
						
					</div>
					<el-form-item label="稽查人员">
					      <el-input v-model="jcForm.checkUserName" disabled></el-input>
					</el-form-item>
					<el-form-item label="备注">
					      <el-input v-model="jcForm.checkNode" disabled></el-input>
					</el-form-item>
				</el-form>
				
			</div>
		 </div>
			<!-- <label class="el-form-item__label lebel-w">单位地址</label> -->
		</div>
	</el-drawer>
</template>
 
<script>
	import XLSX from 'xlsx';
	import {
		ElMessage,
		ElLoading
	} from 'element-plus'
	export default {
		data(){
			return {
				searchMore:false,
				searchMoreText:'更多筛选',
				map:'',
				tableData:[],
				dialogVisible:false,
				isEdit:false,
				isGetImg:false,
				showViewer:false,
				imgList:[],
				viewerIndex:0,
				showViewerJc:false,
				imgListJc:[],
				viewerIndexJc:0,
				tableTotal:0,
				limit:12,
				tableNowPage:1,
				tableLoading:false,
				searchData:{
					dybh:'',
					address:'',
					checkUserName:'',
					level:'',
					checkLevel:'',
					taskId:'',
					taskName:'',
					state:'',
					checkState:'',
					upTime:'',
					checkTime:'',
					over:''
				},
				glrwList:[],
				jcxxImg:[],
				jcForm:{
					checkLevel: "",
					checkNode: "",
					checkState: 0,
					checkTime:"",
					checkUserId: "",
					checkUserName: ""
				},
				khForm: {
					jcwz: '',
					zbxx: '',
					sfqr: 0,
					sldj: '1~50',
					glrw: '',
					glrwName:''
				},
				isLook:false,
				drawerTitle:'查看任务',
				drawerkh:false,
				imageData: {
					url: ''
				},
				imageData1: {
					url: ''
				},
				imageData2: {
					url: ''
				},
			}
		},
		methods:{
			copyDydz(row, column, cell, event){
				var nowCopy = row[column.property];
				ElMessage({
				    message: '已复制到剪切板',
				    type: 'success',
					offset:200
				  })
				this.copyText(nowCopy);
			},
			copyText(text){
				var input = document.createElement('input');
				    input.setAttribute('id', 'input_for_copyText');
				    input.value = text;
				    document.getElementsByTagName('body')[0].appendChild(input);
				    document.getElementById('input_for_copyText').select();
				    document.execCommand('copy');
				    document.getElementById('input_for_copyText').remove();
			},
			closeViewer(num){
				if (num == 0) {
					this.showViewer = false;
				}else{
					this.showViewerJc = false;
				}
				
			},
			searchMoreBtn(){
				this.searchMore = !this.searchMore;
				if (this.searchMore) {
					this.searchMoreText = '收起';
					$('.main-sj').height($('.main-sj').height() + 90)
				}else{
					this.searchMoreText = '更多筛选';
					$('.main-sj').height($('.main-sj').height() - 90)
				}
				
			},
			exportExcel(res){
				var data = [['毒源编号','毒源地址','毒源等级','关联任务','上传时间','毒源状态','提交人员','核查人员','核查时间','核查状态','备注']];  //数据，一定注意需要时二维数组
				res = res.data;
				// console.log(res);
				var dyzt,hczt;
				for (var i = 0; i < res.length; i++) {
					dyzt = res[i].state == 0?'确认':'疑似';
					hczt = res[i].checkState == 0 ?'未检查':res[i].checkState == 1 ?'已确认':'未确认';
					var xData = [
						res[i].dybh,
						res[i].address,
						res[i].level,
						res[i].taskName,
						res[i].upTime,
						dyzt,
						res[i].upUserName,
						res[i].checkUserName,
						res[i].checkTime,
						hczt,
						res[i].checkNode
						];
					data.push(xData);
				}
				// console.log(data);
				var filename = "数据统计.xlsx"; //文件名称
				var ws_name = "Sheet1"; //Excel第一个sheet的名称
				var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
				XLSX.utils.book_append_sheet(wb, ws, ws_name);  //将数据添加到工作薄
				XLSX.writeFile(wb, filename); //导出Excel
			},
			getExcelData(){
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem('type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						taskId: this.searchData.taskId,
						taskName: this.searchData.taskName,
						link_dept: khlink_dept,
						dyzt:4,
						address:this.searchData.address,
						dybh:this.searchData.dybh,
						level:this.searchData.level,
						checkLevel:this.searchData.checkLevel,
						state:this.searchData.state,
						checkState:this.searchData.checkState,
						over:this.searchData.over,
						checkUserName:this.searchData.checkUserName,
						upTime:this.searchData.upTime,
						checkTime:this.searchData.checkTime,
					})
					.then(res => {
						// console.log(res);
						//  
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						this.exportExcel(res.data)
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			drawerOpen() {
				if (this.map != '') {
					if (this.khForm.zbxx) {
						var centerSps = this.khForm.zbxx.split(",");
						this.map.centerAndZoom(new T.LngLat(centerSps[0], centerSps[1]), 18);
					}else{
						this.map.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
					}
					return
				}
				var imageURL = "http://t0.tianditu.gov.cn/img_w/wmts?" +
					"SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
					"&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1c0b123b51edfa0425d320598f59c192";
				var textURl =
					"https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles&tk=1c0b123b51edfa0425d320598f59c192";
				//创建自定义图层对象
				var lay = new T.TileLayer(imageURL, {
					minZoom: 1,
					maxZoom: 18
				});
				var textLay = new T.TileLayer(textURl, {
					minZoom: 1,
					maxZoom: 18
				});
				var config = {
					layers: [lay, textLay]
				};
				//初始化地图对象
				this.map = new T.Map('mapDiv', config);
				// 
				if (this.khForm.zbxx) {
					var centerSps = this.khForm.zbxx.split(",");
					this.map.centerAndZoom(new T.LngLat(centerSps[0], centerSps[1]), 18);
				}else{
					this.map.centerAndZoom(new T.LngLat(117.120128, 36.652069), 15);
				}
				//允许鼠标滚轮缩放地图
				this.map.enableScrollWheelZoom();
				// 拖拽监听
				this.map.addEventListener("moveend", (e) => {
					this.khForm.zbxx = e.target.getCenter().getLng() + "," + e.target.getCenter().getLat();
				});
				// // console.log("执行完成");
			},
			resetFormkh(formName) {
				this.khForm = {
					jcwz: '',
					zbxx: '',
					sfqr: 0,
					sldj: '1~50',
					glrw: '',
				};
				this.imageData = {
					url: ''
				};
				this.imageData1= {
					url: ''
				};
				this.imageData2 = {
					url: ''
				};
				this.showViewer = false;
				this.imgList = [];
				this.viewerIndex = 0;
				
				this.showViewerJc = false;
				this.imgListJc = [];
				this.viewerIndexJc = 0;
			},
			changePage() {
				this.getKhList();
			},
			userLook(index,row) {
				// // console.log(index+","+row.address);
				// 清空稽查信息
				this.jcxxImg = [];
				this.jcForm = {
					checkLevel: "",
					checkNode: "",
					checkState: 0,
					checkTime: '',
					checkUserId: "",
					checkUserName: ""
				};
				this.khForm.jcwz = row.address;
				this.khForm.zbxx = row.gps;
				this.khForm.sfqr = row.state;
				this.khForm.sldj = row.level;
				this.khForm.glrw = row.taskId;
				this.isLook = true;
				this.drawerTitle = "查看任务--"+row.dybh;
				// 获取图片
				this.getImgBase(row.dybh);
				this.drawerkh = true;
				// 稽查信息
				this.jcForm = row;
			},
			getImgBase(dybh){
				this.isGetImg  = true;
				this.$axios.post('/papaver/file', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						dybh: dybh,
					})
					.then(res => {
						this.isGetImg  = false;
						var data = res.data.data;
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						// 稽查信息
						var jcdd = res.data.jcData;
						for (var i = 0; i < jcdd.length; i++) {
							this.imgListJc.push(jcdd[i].url);
							this.jcxxImg.push(jcdd[i].thumbUrl);
						}
						// 查看图片
						for (var i = 0; i < data.length; i++) {
							this.imgList.push(data[i].sbUrl)
						}
						this.getImgData = data;
						if (data[0]) {
							if (data[0].sbUrl) {
								this.imageData.thumbFileUrl = data[0].sbUrl;
							}else{
								this.imageData.thumbFileUrl = data[0].thumbUrl;
							}
							if (this.isLook) {
								this.imageData.url = data[0].sbUrl;
							}else{
								this.imageData.url = data[0].url;
							}
						}
						if (data[1]) {
							if (data[1].sbUrl) {
								this.imageData1.thumbFileUrl = data[1].sbUrl;
							}else{
								this.imageData1.thumbFileUrl = data[1].thumbUrl;
							}
							
							if (this.isLook) {
								this.imageData1.url = data[1].sbUrl;
							}else{
								this.imageData1.url = data[1].url;
							}
						}
						if (data[2]) {
							if (data[2].sbUrl) {
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							}else{
								this.imageData2.thumbFileUrl = data[2].thumbUrl;
							}
							
							if (this.isLook) {
								this.imageData2.url = data[2].sbUrl;
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							}else{
								this.imageData2.url = data[2].url;
								this.imageData2.thumbFileUrl = data[2].sbUrl;
							}
						}
					})
					.catch(error => {
						this.isGetImg  = false;
						console.error('error:', error)
					})
			},
			btnSub(){
				this.getKhList();
				// // console.log(this.searchData);
			},
			resetFrom(){
				this.searchData = {
					dybh:'',
					address:'',
					checkUserName:'',
					level:'',
					checkLevel:'',
					taskId:'',
					taskName:'',
					state:'',
					checkState:'',
					upTime:'',
					checkTime:'',
					over:''
				};
				this.getKhList();
			},
			selectGetRwPl(vId){
				let obj = {};
				  obj = this.glrwList.find((item)=>{//这里的userList就是上面遍历的数据源
					  return item.taskId === vId;//筛选出匹配数据
				  });
				  this.searchData.taskName = obj.taskName;
				  this.searchData.taskId = obj.taskId;
			},
			getGlrw(){
				var link_dept = '';
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem('type') == 2) {
					link_dept = sessionStorage.getItem('deptId');
				} else if(sessionStorage.getItem('type') == 3) {
					link_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/linkTask', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						link_dept:link_dept
					})
					.then(res => {
						 
						if (res.data.code != 666) {
							ElMessage.error(res.data.msg);
							return
						}
						this.glrwList = res.data.data;
					})
					.catch(error => {
						console.log('error:', error)
					})
			},
			getKhList(state) {
				this.tableLoading = true;
				// if (!state) {
				// 	state = 1
				// }
				// this.listState = state;
				// 任务列表
				var khdeptid, khlink_dept;
				if (sessionStorage.getItem('type') == 0 || sessionStorage.getItem('type') == 1 || sessionStorage.getItem('type') == 2) {
					khlink_dept = sessionStorage.getItem('deptId')
					khdeptid = sessionStorage.getItem('deptId');
				} else if (sessionStorage.getItem('type') == 3) {
					khdeptid = '';
					khlink_dept = sessionStorage.getItem('link_dept');
				}
				this.$axios.post('/papaver/list', {
						userId: sessionStorage.getItem('userId'),
						type: sessionStorage.getItem('type'),
						taskId: this.searchData.taskId,
						taskName: this.searchData.taskName,
						link_dept: khlink_dept,
						page: this.tableNowPage,
						limit: this.limit,
						dyzt:4,
						address:this.searchData.address,
						dybh:this.searchData.dybh,
						level:this.searchData.level,
						checkLevel:this.searchData.checkLevel,
						state:this.searchData.state,
						checkState:this.searchData.checkState,
						over:this.searchData.over,
						checkUserName:this.searchData.checkUserName,
						upTime:this.searchData.upTime,
						checkTime:this.searchData.checkTime,
					})
					.then(res => {
						this.tableLoading = false;
						//  
						if (res.data.code != 200) {
							ElMessage.error(res.data.msg);
							return
						}
						// console.log(res.data.data);
						this.tableData = res.data.data;
						this.tableTotal = res.data.count;
					})
					.catch(error => {
						this.tableLoading = false;
						console.log('error:', error)
					})
			}
		},
		mounted() {
			this.getGlrw();
			this.getKhList();
		}
	}
</script>
 
<style scoped>
	.form-item{
		margin-left: 2%;
		width: 23%;
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}
	.form-item .el-input{
		width: auto !important; 
		flex: 1;
	}
	.form-item .el-select{
		width: auto !important; 
		flex: 1;
	}
	.form-item p{
		width: 80px;
	}
	.main-sj{
		width: 100%;
		height: 100%;
		background-color: rgb(255, 255, 255);
		border-radius: 14px;
		box-sizing: border-box;
		padding: 15px;
	}
	.screen-title{
		width: 100%;
		display: flex;
		margin-bottom: 15px;
		flex-wrap: wrap;
	}
	.table-d{
		width: 100%;
	}
	.radius-w {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		line-height: 28px;
		text-align: center;
		/* display: flex;
		align-items: center;
		justify-content: center; */
		cursor: pointer;
		color: #FFFFFF;
	}
	.drawer-div {
		width: 100%;
		height: calc(100% - 50px);
		/* height: 850px; */
		box-sizing: border-box;
		padding: 10px;
		display: flex;
	}
	.drawer-left {
		flex: 1;
		position: relative;
	}
	
	.mapSz {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		font-size: 60px;
		color: #67C23A;
	}
	.drawer-right {
		width: 40%;
		overflow-y: auto;
	}
	
	#mapDiv {
		width: 100%;
		height: 100%;
	}
</style>
