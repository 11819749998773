import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { ElMessage , ElLoading} from 'element-plus'
import $ from 'jquery'
const app = createApp(App)
// 统一注册Icon图标
for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName]
    app.component(iconName, item)
  }
}
app.config.globalProperties.$axios=axios
// 请求头信息是为post请求设置
// axios.defaults.headers.post['token'] = sessionStorage.getItem('token');
// axios.defaults.baseURL='/dptapi';
// axios.defaults.baseURL='/api/public';
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL='/dptapi';
}else{
  console.log = () => {}
  axios.defaults.baseURL='/api/public';
}
app.use(jquery).use(ElMessage).use(ElLoading).use(store).use(router).use(ElementPlus, {
  locale: zhCn,
}).mount('#app')
